import React, { useEffect, useState } from "react";
import Chart from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Modal,
  Collapse,
  InputGroupAddon,
  InputGroupText,
  InputGroup
} from "reactstrap";
import ReactDatetime from "react-datetime";
import formatDate from '../../../util/formatDate';
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import swal from "sweetalert";
import api from '../../../util/api';
import ModalCompany from '../../../components/ModalCompany';
import changeTypes from '../../../util/changeTypes';
import sum from '../../../util/sum';
import usePagination from '../../../hooks/usePagination';
import useList from '../../../hooks/useList';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import addMonths from "util/addMonths";


function Elements() {

  const [contractSent, setContractSent] = useState([]);
  const [closedWon, setClosedWon] = useState([]);

  console.log(contractSent);
  console.log(closedWon);

  useEffect(() => {
    api.get('/contracts', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res => {
      if(res.data.success){
        setContractSent(res.data.contractSent);
        setClosedWon(res.data.closedWon);
      }
    })
  }, [])

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  } 

  return (
    <>
      <SimpleHeader name="Forecast" parentName="" />
      <Container className="mt--6" fluid>

        <Row>
          <Col>

          <Card className="bg-default">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-light text-uppercase ls-1 mb-1">
                      Overview
                    </h6>
                    <h5 className="h3 text-white mb-0">Forecast</h5>
                  </div>
                  <div className="col">
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Line
                    data={{
                      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                      datasets: [
                        {
                          label: "Closed Won",
                          data: closedWon,
                          fill: false,
                          borderColor: "rgba(75,192,192,1)",
                          backgroundColor: "rgba(75,192,192,1)",
                          pointColor: "#da3e2f",
                          strokeColor: "#da3e2f",
                          datalabels: {
                            anchor: 'end',
                            align: 'end',
                            offset: 5
                          },
                        },
                        {
                          label: "Contract Sent",
                          data: contractSent,
                          fill: false,
                          borderColor: "#ad2d10",
                          backgroundColor: "#ad2d10",
                          pointColor: "#da3e2f",
                          strokeColor: "#da3e2f",
                          datalabels: {
                            anchor: 'end',
                            align: 'end',
                            offset: 5
                          },
                        },

                      ],
                    }}
                    plugins={[ChartDataLabels]}
                    id="chart-sales-dark"
                    className="chart-canvas"
                  />
                </div>
              </CardBody>
            </Card>
          
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Elements;
