import React, { useEffect, useState } from "react";


import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardImg,
    CardTitle,
    FormGroup,
    Form,
    Input,
    ListGroupItem,
    ListGroup,
    Progress,
    Container,
    Row,
    Col,
    Label,
    FormText,
    Collapse,
    Modal
} from "reactstrap";
// core components
import ProfileHeader from "components/Headers/ProfileHeader.js";
import api from '../../../util/api';
import swal from "sweetalert";

function Profile() {
    const [refresh, setRefresh] = useState(false);
    const [user, setUser] = useState({});
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [zipCode, setZipCode] = useState('');

    const [selectedFile, setSelectedFile] = useState('');


    const [loading, setLoading] = useState(false);









    const updateUser = (e) => {
        e.preventDefault();
        api.put(`/user/${user.id}`, {
            name, email, country, state, zip_code: zipCode, address, city
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(async res => {
            if (res.data.success) {
                await swal('Success', 'Informations updated succesfully!', 'success');
                setRefresh(!refresh);
                setName('');
                setEmail('');
                setAddress('');
                setCity('');
                setCountry('');
                setZipCode('');
            } else {
                await swal('Error!', res.data.message, 'error');
            }
        })
    }

    const uploadImage = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('image', selectedFile);
        api.post('/user/upload-image', formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(async res => {
            if (res.data.success) {
                await swal('Success!', 'Your image was uploaded succesfully!', 'success');
                setRefresh(!refresh);
                setSelectedFile('');
            } else {
                await swal('Error!', res.data.message, 'error');
            }
        })
    }

    const token = localStorage.getItem('token');
    useEffect(() => {
        api.get('/user', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(async res => {
            if (res.data.success) {
                setUser(res.data.user);
            } else {
                await swal('Error', 'An error ocurred. Try login again!', 'error');
                localStorage.removeItem('token');
                window.location.href = '/';
            }
        });

    }, [token, refresh]);
    return (
        <>

            <Container className="mt--6" fluid>
                <Row>
                    <Col className="order-xl-2" xl="4">
                        <Card className="card-profile">
                            <CardImg
                                alt="..."
                                src={require("assets/img/theme/img-1-1000x600.jpg").default}
                                top
                            />
                            <Row className="justify-content-center">
                                <Col className="order-lg-2" lg="3">
                                    <div className="card-profile-image">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                            <img
                                                alt="..."
                                                className="rounded-circle"
                                                src={!user.avatarUrl || user.avatarUrl === 'none' ? '/img/none.png' : user.avatarUrl}
                                            />
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                            <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                                <div className="d-flex justify-content-between">
                                    <Button
                                        className="mr-4"
                                        color="info"
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                        size="sm"
                                    >
                                        Connect
                                    </Button>
                                    <Button
                                        className="float-right"
                                        color="default"
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                        size="sm"
                                    >
                                        Message
                                    </Button>
                                </div>
                            </CardHeader>
                            <CardBody className="pt-0">
                                <Row>
                                    <div className="col">
                                        <div className="card-profile-stats d-flex justify-content-center">
                                            <div>
                                                <span className="heading">22</span>
                                                <span className="description">Friends</span>
                                            </div>
                                            <div>
                                                <span className="heading">10</span>
                                                <span className="description">Photos</span>
                                            </div>
                                            <div>
                                                <span className="heading">89</span>
                                                <span className="description">Comments</span>
                                            </div>
                                        </div>
                                    </div>
                                </Row>
                                <div className="text-center">
                                    <h5 className="h3">
                                        {user.name}
                                        <span className="font-weight-light">, {user.yearsOld}</span>
                                    </h5>
                                    <div className="h5 font-weight-300">
                                        <i className="ni location_pin mr-2" />
                                        {user.city}, {user.country === 'BR' ? 'Brazil' : 'United States'}
                                    </div>
                                    <div className="h5 mt-4">
                                        <i className="ni business_briefcase-24 mr-2" />
                                        {user.jobPosition} {user.company ? `- ${user.company}` : ''}
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
{/* 
                        <Card>
                            <CardHeader>
                                <h5 className="h3 mb-0">Progress track</h5>
                            </CardHeader>

                            <CardBody>
                                <ListGroup className="list my--3" flush>
                                    <ListGroupItem className="px-0">
                                        <Row className="align-items-center">
                                            <Col className="col-auto">
                                                <a
                                                    className="avatar rounded-circle"
                                                    href="#pablo"
                                                    onClick={(e) => e.preventDefault()}
                                                >
                                                    <img
                                                        alt="..."
                                                        src={
                                                            require("assets/img/theme/bootstrap.jpg").default
                                                        }
                                                    />
                                                </a>
                                            </Col>
                                            <div className="col">
                                                <h5>Argon Design System</h5>
                                                <Progress
                                                    className="progress-xs mb-0"
                                                    max="100"
                                                    value="60"
                                                    color="warning"
                                                />
                                            </div>
                                        </Row>
                                    </ListGroupItem>
                                    <ListGroupItem className="px-0">
                                        <Row className="align-items-center">
                                            <Col className="col-auto">
                                                <a
                                                    className="avatar rounded-circle"
                                                    href="#pablo"
                                                    onClick={(e) => e.preventDefault()}
                                                >
                                                    <img
                                                        alt="..."
                                                        src={
                                                            require("assets/img/theme/angular.jpg").default
                                                        }
                                                    />
                                                </a>
                                            </Col>
                                            <div className="col">
                                                <h5>Angular Now UI Kit PRO</h5>
                                                <Progress
                                                    className="progress-xs mb-0"
                                                    max="100"
                                                    value="100"
                                                    color="success"
                                                />
                                            </div>
                                        </Row>
                                    </ListGroupItem>
                                    <ListGroupItem className="px-0">
                                        <Row className="align-items-center">
                                            <Col className="col-auto">
                                                <a
                                                    className="avatar rounded-circle"
                                                    href="#pablo"
                                                    onClick={(e) => e.preventDefault()}
                                                >
                                                    <img
                                                        alt="..."
                                                        src={require("assets/img/theme/sketch.jpg").default}
                                                    />
                                                </a>
                                            </Col>
                                            <div className="col">
                                                <h5>Black Dashboard</h5>
                                                <Progress
                                                    className="progress-xs mb-0"
                                                    max="100"
                                                    value="72"
                                                    color="danger"
                                                />
                                            </div>
                                        </Row>
                                    </ListGroupItem>
                                    <ListGroupItem className="px-0">
                                        <Row className="align-items-center">
                                            <Col className="col-auto">
                                                <a
                                                    className="avatar rounded-circle"
                                                    href="#pablo"
                                                    onClick={(e) => e.preventDefault()}
                                                >
                                                    <img
                                                        alt="..."
                                                        src={require("assets/img/theme/react.jpg").default}
                                                    />
                                                </a>
                                            </Col>
                                            <div className="col">
                                                <h5>React Material Dashboard</h5>
                                                <Progress
                                                    className="progress-xs mb-0"
                                                    max="100"
                                                    value="90"
                                                    color="info"
                                                />
                                            </div>
                                        </Row>
                                    </ListGroupItem>
                                    <ListGroupItem className="px-0">
                                        <Row className="align-items-center">
                                            <Col className="col-auto">
                                                <a
                                                    className="avatar rounded-circle"
                                                    href="#pablo"
                                                    onClick={(e) => e.preventDefault()}
                                                >
                                                    <img
                                                        alt="..."
                                                        src={require("assets/img/theme/vue.jpg").default}
                                                    />
                                                </a>
                                            </Col>
                                            <div className="col">
                                                <h5>Vue Paper UI Kit PRO</h5>
                                                <Progress
                                                    className="progress-xs mb-0"
                                                    max="100"
                                                    value="100"
                                                    color="success"
                                                />
                                            </div>
                                        </Row>
                                    </ListGroupItem>
                                </ListGroup>
                            </CardBody>
                        </Card>
                     */}
                    </Col>
                    <Col className="order-xl-1" xl="8">

                        <Card>
                            <CardHeader>
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">Edit profile</h3>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>

                                <Form onSubmit={updateUser}>
                                    <h6 className="heading-small text-muted mb-4">
                                        User information
                                    </h6>
                                    <div className="pl-lg-4">

                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                    >
                                                        Name
                                                    </label>
                                                    <Input
                                                        id="input-username"
                                                        placeholder={user.name}
                                                        type="text"
                                                        value={name}
                                                        onChange={e => setName(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-email"
                                                    >
                                                        Email address
                                                    </label>
                                                    <Input
                                                        id="input-email"
                                                        placeholder={user.email}
                                                        type="email"
                                                        value={email}
                                                        onChange={e => setEmail(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                    </div>
                                    <hr className="my-4" />

                                    <h6 className="heading-small text-muted mb-4">
                                        Contact information
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col md="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-address"
                                                    >
                                                        Address
                                                    </label>
                                                    <Input
                                                        id="input-address"
                                                        placeholder={user.address}
                                                        type="text"
                                                        value={address}
                                                        onChange={e => setAddress(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-city"
                                                    >
                                                        City
                                                    </label>
                                                    <Input
                                                        id="input-city"
                                                        placeholder={user.city}
                                                        type="text"
                                                        onChange={e => setCity(e.target.value)}
                                                        value={city}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-country"
                                                    >
                                                        Country
                                                    </label>
                                                    <Input
                                                        id="input-country"
                                                        placeholder={user.country}
                                                        type="text"
                                                        onChange={e => setCountry(e.target.value)}
                                                        value={country}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-country"
                                                    >
                                                        Postal code
                                                    </label>
                                                    <Input
                                                        id="input-postal-code"
                                                        placeholder={user.zipCode}
                                                        type="number"
                                                        onChange={e => setZipCode(e.target.value)}
                                                        value={zipCode}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button type="submit">Update profile</Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                                <hr className="my-4" />

                                <h6 className="heading-small text-muted mb-4">Image profile</h6>
                                <form onSubmit={uploadImage}>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label for="exampleFile">Profile</Label>
                                                <Input onChange={e => setSelectedFile(e.target.files[0])} type="file" name="file" id="exampleFile" />
                                                <FormText color="muted">
                                                    Upload an image to set as profile
                                                </FormText>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Button type="submit">Change image</Button>
                                    </Row>
                                </form>
                                <hr className="my-4" />

                                
                            
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Profile;
