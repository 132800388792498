import React, { useEffect, useState } from "react";

import {
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    Button,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    UncontrolledTooltip,
    Modal
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import swal from "sweetalert";
import api from '../../../util/api';
import formatDate from '../../../util/formatDate';

function Elements() {
    const [description, setDescription] = useState('');
    const [date, setDate] = useState(null);
    const [amount, setAmount] = useState('');
    const [submit, setSubmit] = useState(false);
    const [category, setCategory] = useState('T-shirt');
    const [recurrent, setRecurrent] = useState(false);


    const [newDescription, setNewDescription] = useState(2);
    const [newDate, setNewDate] = useState(2);
    const [newAmount, setNewAmount] = useState(2);
    const [newCategory, setNewCategory] = useState(2);

    const [allExpenses, setAllExpenses] = useState([]);

    const [modal, setModal] = useState(false);
    const [updateId, setUpdateId] = useState(null);
    const [oldData, setOldData] = useState({});

    console.log(oldData);
    const token = localStorage.getItem('token');

    const categoryMap = [
        'T-shirt'
    ]

    const updateInit = (id) => {
        setUpdateId(id);
        api.get(`/cost-of-sold/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            if (res.data.success) {
                setOldData(res.data.costOfSold);
                setModal(true);
            }
        }).catch(e => console.log(e));;
    }

    useEffect(() => {
        api.get('/cost-of-sold', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            if (res.data.success) {
                console.log(res.data);
                setAllExpenses(res.data.costOfSold);
            } else {
                swal('Error!', res.data.message, 'error').then(res => window.location.href = '/auth/login');
                setAllExpenses([]);
            }
        }).catch(e => {
            console.log(e);
        });
    }, [submit, token]);

    const insertExpenses = async (e) => {
        e.preventDefault();
        if (description === '') {
            await swal('Atention', 'Insert a description', 'warning');
            return;
        }
        if (amount === '') {
            await swal('Atention', 'Insert a description', 'warning');
            return;
        }
        api.post('/cost-of-sold/create', {
            description,
            date,
            amount: Number(amount),
            category
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(async res => {
            if (res.data.success) {
                await swal('Success!', 'Cost of goods sold registered succesfully!', 'success');
                setDescription('');
                setDate('');
                setAmount('');
                setRecurrent(false);
                setSubmit(!submit);
            } else {
                await swal('Atention!', res.data.message, 'warning');
            }
        }).catch(async e => {
            await swal('Error', 'An Error ocurred', 'error');
            console.log(e);
        });
    }
    const deleteExpense = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this cost of goods sold",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    api.delete(`/cost-of-sold/${id}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }).then(async res => {
                        if (res.data.success) {
                            await swal('Success!', 'Data deleted!', 'success');
                        } else {
                            await swal('Error', 'An error ocurred', 'error');
                        }
                        setSubmit(!submit);
                    }).catch(async e => {
                        await swal('Error', 'An error ocurred', 'error');
                        console.log(e);
                    });
                } else {
                    return;
                }
            });
    }
    const saveChanges = (e) => {
        e.preventDefault();

        api.put(`/cost-of-sold/${updateId}`, {
            description: newDescription,
            date: newDate,
            amount: newAmount,
            category: newCategory
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(async res => {
            if (res.data.success) {
                await swal('Success!', 'Data edited sucessfully', 'success');
                setModal(false);
                window.location.href = '/admin/cost-of-goods-sold';
            } else {
                await swal('Error!', res.data.message, 'error');
            }
        }).catch(async e => {
            await swal('Error', 'An error ocurred!', 'error');
            console.log(e);
        });
    }

    return (
        <>
            <Modal
                style={{ minWidth: '80%' }}
                className="modal-dialog-centered"
                isOpen={modal}
                toggle={() => setModal(false)}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                        Edit this cost of goods sold
                    </h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col md="3" sm="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="description_field"
                                >
                                    Description
                                </label>
                                <Input
                                    id="description_field"
                                    placeholder={oldData.description}
                                    type="text"
                                    name="description"
                                    value={newDescription}
                                    onChange={e => setNewDescription(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="3" sm="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="date_field"
                                >
                                    Date
                                </label>
                                <Col md="10">
                                    <Input
                                        defaultValue={new Date().getFullYear() + "-11-23"} id="date_field"
                                        type="date"
                                        name="date"
                                        value={newDate === 2 ? oldData.date : newDate}
                                        onChange={e => setNewDate(e.target.value)}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col md="3" sm="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="amount_field"
                                >
                                    Amount
                                </label>
                                <Input
                                    id="amount_field"
                                    placeholder={oldData.amount}
                                    name="amount"
                                    type="number"
                                    value={newAmount}
                                    onChange={e => setNewAmount(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="3" sm="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="category_field"
                                >
                                    Category
                                </label>
                                <Input onChange={e => setNewCategory(e.target.value)} type="select" name="category" id="category_field">
                                    {categoryMap.map(item => (
                                        <>
                                            <option value={item}>{item}</option>
                                        </>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3" sm="6">

                        </Col>
                        <Col md="3" sm="6">

                        </Col>
                        <Col md="3" sm="6">

                        </Col>
                        <Col md="3" sm="6">

                        </Col>
                    </Row>
                </div>
                <div className="modal-footer">
                    <Button onClick={saveChanges} color="primary" type="button">
                        Save changes
                    </Button>
                    <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setModal(false)}
                    >
                        Close
                    </Button>
                </div>
            </Modal>
            <SimpleHeader name="Cost of goods sold" parentName="" />
            <Container className="mt--6" fluid>
                <Card className="mb-4">
                    <CardHeader>
                        <h3 className="mb-0">Cost of goods sold input</h3>
                    </CardHeader>
                    <CardBody>
                        <form onSubmit={insertExpenses}>

                            <Row>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="description_field"
                                        >
                                            Description
                                        </label>
                                        <Input
                                            id="description_field"
                                            placeholder="Description"
                                            type="text"
                                            required
                                            value={description}
                                            onChange={e => setDescription(e.target.value)}
                                            name="description"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="date_field"
                                            htmlFor="example4cols3Input"
                                        >
                                            Date
                                        </label>
                                        <Col md="10">
                                            <Input
                                                defaultValue={new Date().getFullYear() + "-11-23"}
                                                id="date_field"
                                                type="date"
                                                name="date"
                                                value={date}
                                                required
                                                onChange={e => setDate(e.target.value)}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="amount_field"
                                        >
                                            Amount
                                        </label>
                                        <Input
                                            id="amount_field"
                                            placeholder="Amount"
                                            type="number"
                                            name="amount"
                                            required
                                            value={amount}
                                            onChange={e => setAmount(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3" sm="6">
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: 20 }}>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="category_field"
                                        >
                                            Category
                                        </label>
                                        <Input required onChange={e => setCategory(e.target.value)} type="select" name="category" id="category_field">
                                            {categoryMap.map(item => (
                                                <>
                                                    <option value={item}>{item}</option>
                                                </>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md="3" sm="6">

                                </Col>


                                <Col md="3" sm="6">

                                </Col>
                                <Col md="3" sm="6">

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button color="primary" type="submit">
                                        Insert
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                </Card>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="border-0">
                                <h3 className="mb-0">Cost of goods sold</h3>
                            </CardHeader>

                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th className="sort" data-sort="name" scope="col">
                                            Description
                                        </th>
                                        <th className="sort" data-sort="budget" scope="col">
                                            Date
                                        </th>
                                        <th className="sort" data-sort="status" scope="col">
                                            Amount
                                        </th>
                                        <th scope="col">Category</th>
                                        <th className="sort" data-sort="completion" scope="col">
                                            Completion
                                        </th>
                                        <th scope="col" />
                                    </tr>
                                </thead>
                                <tbody className="list">
                                    {allExpenses.map((item) => (
                                        <tr>
                                            <th scope="row">
                                                <p>{item.description}</p>
                                                
                                            </th>
                                            <td className="budget">{formatDate(item.date)}</td>
                                            <td>
                                                US {item.amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                                                
                                            </td>
                                            <td>
                                                {item.category}
                                                
                                            </td>
                                            <td className="table-actions">
                                                <a
                                                    className="table-action"
                                                    href="#pablo"
                                                    id="tooltip564981685"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        updateInit(item.id);

                                                    }}
                                                >
                                                    <i className="fas fa-user-edit" />
                                                </a>
                                                <UncontrolledTooltip delay={0} target="tooltip564981685">
                                                    Edit product
                                                </UncontrolledTooltip>
                                                <a
                                                    className="table-action table-action-delete"
                                                    href="#pablo"
                                                    id="tooltip601065234"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        deleteExpense(item.id);
                                                    }}
                                                >
                                                    <i className="fas fa-trash" />
                                                </a>
                                                <UncontrolledTooltip delay={0} target="tooltip601065234">
                                                    Delete product
                                                </UncontrolledTooltip>
                                            </td>
                                            <td className="text-right">
                                                <UncontrolledDropdown>
                                                    <DropdownToggle
                                                        className="btn-icon-only text-light"
                                                        color=""
                                                        role="button"
                                                        size="sm"
                                                    >
                                                        <i className="fas fa-ellipsis-v" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-arrow" right>
                                                        <DropdownItem
                                                            href="#pablo"
                                                            onClick={(e) => e.preventDefault()}
                                                        >
                                                            Action
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            href="#pablo"
                                                            onClick={(e) => e.preventDefault()}
                                                        >
                                                            Another action
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            href="#pablo"
                                                            onClick={(e) => e.preventDefault()}
                                                        >
                                                            Something else here
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>

                            <CardFooter className="py-4">
                                {/* <nav aria-label="...">
                                    <Pagination
                                        className="pagination justify-content-end mb-0"
                                        listClassName="justify-content-end mb-0"
                                    >
                                        <PaginationItem className="disabled">
                                            <PaginationLink
                                                href="#pablo"
                                                onClick={(e) => e.preventDefault()}
                                                tabIndex="-1"
                                            >
                                                <i className="fas fa-angle-left" />
                                                <span className="sr-only">Previous</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem className="active">
                                            <PaginationLink
                                                href="#pablo"
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                1
                                            </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem>
                                            <PaginationLink
                                                href="#pablo"
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                2 <span className="sr-only">(current)</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem>
                                            <PaginationLink
                                                href="#pablo"
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                3
                                            </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem>
                                            <PaginationLink
                                                href="#pablo"
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                <i className="fas fa-angle-right" />
                                                <span className="sr-only">Next</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                    </Pagination>
                                </nav> */}
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default Elements;
