import React, { useEffect, useState } from "react";
import Chart from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";
import CardsHeader from "components/Headers/CardsHeader.js";
import api from "../../../util/api";
import {
  chartOptions,
  parseOptions,
  chartExample2,
} from "variables/charts.js";

function Dashboard() {
  const [activeNav, setActiveNav] = React.useState(1);
  const [chartExample1Data, setChartExample1Data] = React.useState("data1");
  const [countLeads, setCountLeads] = useState([0, 0, 0, 0, 0, 0]);

  const token = localStorage.getItem('token');

  useEffect(() => {
    api.get('/contracts/count/leads', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(res => {
      if (res.data.success) {
        const { needsAnalysis, qualifying, decide, closedWon, closedLost, inactive } = res.data.leads;
        setCountLeads([needsAnalysis, qualifying, decide, closedWon, closedLost, inactive]);
      }
    });
  }, []);


  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data(chartExample1Data === "data1" ? "data2" : "data1");
  };
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  return (
    <>
      <CardsHeader name="Leads" parentName="Dashboards" />
      <Container className="mt--6" fluid>
        <Row>
          <Col>
            <Card>
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1"> Performance </h6>
                    <h5 className="h3 mb-0">Total leads</h5>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Bar
                    data={{
                      labels: ['Needs Analysis', 'Qualifying', 'Decide', 'Closed Won', 'Closed Lost', 'Inactive'],
                      datasets: [
                        {
                          label: "Contracts",
                          data: countLeads,
                          maxBarThickness: 10
                        }
                      ]
                    }}
                    options={chartExample2.options}
                    className="chart-canvas"
                    id="chart-bars"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xl="5">
          </Col>
          <Col xl="7">
            <Row>
              <div className="col">
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
