import React, { useEffect, useState } from "react";

import {
    Card,
    CardHeader,
    CardBody,
    Collapse,
    Container,
    Row,
    Col,
    Button,
    Table,
} from "reactstrap";
// core components
import swal from "sweetalert";
import api from '../../../util/api';

export default function MailChimp(){
    const [isOpen, setIsOpen] = useState(false);
    const [contacts, setContacts] = useState([]);

    const token = localStorage.getItem('token');
    useEffect(() => {
        api.get('/mailchimp', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            if(res.data.success){
                setContacts(res.data.members);
            }
        }).catch(async e => await swal('Error!', 'An error ocurred!', 'error'));
    }, [token]);
    return(
        <>
          
          <Container>
          <Row>
          <Col>
            <Card className="mb-4">
              <CardHeader>
                <h3 className="mb-0">All Contacts</h3>
                <Button style={{ marginTop: 20 }} onClick={() => setIsOpen(!isOpen)}>{isOpen ? 'Close' : 'Open'}</Button>
              </CardHeader>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Collapse isOpen={isOpen}>
              <Card className="mb-4">
                <CardHeader>
                  <h3 className="mb-0">Contacts</h3>
                </CardHeader>
                <CardBody>
                    
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th className="sort" data-sort="name" scope="col">Name</th>
                    <th className="sort" data-sort="name" scope="col">Email address</th>
                    <th className="sort" data-sort="budget" scope="col">Phone</th>
                    <th className="sort" data-sort="status" scope="col">Tags</th>
                  </tr>
                </thead>
                <tbody className="list">
                    {contacts.map((item, i) => (
                    <tr key={i}>
                    <td scope="row">
                      {`${item.merge_fields['FNAME']} ${item.merge_fields['LNAME']}`}
                    </td>
                    <th scope="row">
                      {item.email_address}
                    </th>
                    {/* <td className="budget">{formatDate(item.date)}</td> */}
                    <td>
                      {item.merge_fields['PHONE'] !== '' ? item.merge_fields['PHONE'] : 'N/A' }
                    </td>
                    <td>
                    <ul>
                    {item.tags.map((tag, index) => (
                        <li key={index}>{tag.name}</li>
                    ))}
                    </ul>
                    </td>
                  </tr>
              
                    ))}
                </tbody>
              </Table>

                </CardBody>
              </Card>
            </Collapse>
          </Col>
        </Row>
          </Container>
        
        </>
    );
}