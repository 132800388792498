import React, { useEffect, useState } from "react";

import {
    Card,
    CardHeader,
    FormGroup,
    Container,
    Row,
    Col,
    Button,
    CardFooter,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Modal
} from "reactstrap";
import Select2 from "react-select2-wrapper";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import swal from "sweetalert";
import api from '../../../util/api';
import formatDate from '../../../util/formatDate';
import validate from '../../../util/validate';
import useList from '../../../hooks/useList';
import usePagination from '../../../hooks/usePagination';

function Elements() {

    const token = localStorage.getItem('token');

    const { list, fetchList } = useList('/user/list-customers');
    const { actualPage, setActualPage } = usePagination();


    const [newEmail, setNewEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newType, setNewType] = useState(null);
    const [newStatus, setNewStatus] = useState(true);
    const [newJobPosition, setNewJobPosition] = useState('');
    const [newCountry, setNewCountry] = useState('');
    const [newState, setNewState] = useState('');
    const [newCity, setNewCity] = useState('');
    const [newZipCode, setNewZipCode] = useState('');
    const [newAddress, setNewAddress] = useState('');


    const [modal, setModal] = useState(false);

    const [loading, setLoading] = useState(false);

    const [userCustomerId, setUserCustomerId] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [companyId, setCompanyId] = useState(null);

    const [refresh, setRefresh] = useState(true);

    console.log(companies);

    const saveChanges = async (e) => {
        e.preventDefault();

        if (companyId === '') {
            await swal('Atention!', 'Select an company', 'warning');
            return;
        }

        await linkUser(companyId);
    }

    useEffect(() => {
        fetchList(actualPage);
        api.get('/companies/all', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            if (res.data.success) {
                console.log(res.data);
                setCompanies(res.data.companies);
            } else {
                setCompanies([]);
            }
        })
    }, [refresh, actualPage]);

    const linkUser = async (companyId) => {
        if (userCustomerId === null) {
            await swal('Atention!', 'Select an user to link!', 'warning');
            return;
        }
        if (companyId === null) {
            await swal('Atention!', 'Select an company to link!', 'warning');
        }
        api.post('/companies/link', { userIdCustomer: userCustomerId, companyId }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(async res => {
            if (res.data.success) {
                await swal('Success!', 'This user is now linked to a company!', 'success');
                setModal(false);
                setRefresh(!refresh);
            } else {
                await swal('Error!', res.data.message, 'error');
            }
        }).catch(async e => {
            await swal('Error!', 'An error ocurred!', 'error');
        })
    }

    return (
        <>

            <Modal
                style={{ minWidth: '80%' }}
                className="modal-dialog-centered"
                isOpen={modal}
                toggle={() => setModal(false)}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                        Select an company to link
                    </h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col>
                            <FormGroup>
                                {companies ? companies.length > 0 ? <> <Select2
                                    id="industry_select"
                                    className="form-control"
                                    options={{
                                        placeholder: "Select a category",
                                    }}
                                    value={companyId}
                                    onChange={e => setCompanyId(e.target.value)}
                                    required
                                    data={companies.map((item) => {
                                        return { id: item.id, text: item.alias }
                                    })}
                                /></> : <p>Companies is empty</p> : <p>Companies is empty</p>}
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                <div className="modal-footer">
                    <Button onClick={saveChanges} color="primary" type="button">
                        Save changes
                    </Button>
                    <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setModal(false)}
                    >
                        Close
                    </Button>
                </div>
            </Modal>

            <SimpleHeader name="Users" parentName="Forms" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="border-0">
                                <h3 className="mb-0">Link Company</h3>
                            </CardHeader>

                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th className="sort" data-sort="name" scope="col">User Name</th>
                                        <th className="sort" data-sort="budget" scope="col">User Email</th>
                                        <th className="sort" data-sort="status" scope="col">Type</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Job Position</th>
                                        <th scope="col">Birth Date</th>
                                        <th scope="col">Full Address</th>
                                        {/* <th scope="col">City</th>
                                        <th scope="col">State</th>
                                        <th scope="col">Zip Code</th>
                                        <th scope="col">Country</th> */}
                                        <th scope="col">Stripe Customer ID</th>
                                        <th scope="col">Email confirmed</th>
                                        <th scope="col">Created at</th>
                                        <th scope="col">ID Linked</th>
                                        <th scope="col" />
                                    </tr>
                                </thead>
                                <tbody className="list">
                                    {list ? list.customers.map((item) => (
                                        <tr>
                                            <td>
                                                {item.name}
                                            </td>
                                            <td>{item.email}</td>
                                            <td>
                                                {item.type === 1 ? 'Admin' : null}
                                                {item.type === 2 ? 'Moderator' : null}
                                                {item.type === 3 ? 'Customer' : null}
                                                {item.type === 4 ? 'Redator' : null}
                                                {item.type === 5 ? 'Saller' : null}
                                            </td>
                                            <td>
                                                {item.status ? 'Active' : 'Inactive'}
                                            </td>
                                            <td>
                                                {item.job_position}
                                            </td>
                                            <td>
                                                {item.birth_date ? formatDate(item.birth_date) : 'N/A'}
                                            </td>
                                            <td>
                                                {item.address} - {item.city} - {item.state} - {item.zip_code} - {item.country}
                                            </td>
                                            {/* <td>
                                                {item.city}
                                            </td>
                                            <td>
                                                {item.state}
                                            </td>
                                            <td>
                                                {item.zip_code}
                                            </td>
                                            <td>
                                                {item.country}
                                            </td> */}
                                            <td>
                                                {item.customer_id ? item.customer_id : 'N/A'}
                                            </td>
                                            <td>
                                                {item.is_confirmed ? 'Yes' : 'No'}
                                            </td>
                                            <td>
                                                {formatDate(item.created_at)}
                                            </td>
                                            <td>
                                                {item.company_id ? item.company_id : 'Not linked'}
                                            </td>
                                            <td className="table-actions">
                                                <Button onClick={() => {
                                                    setUserCustomerId(item.id);
                                                    setModal(true);
                                                }}>Link User</Button>
                                                <Button>Unlink User</Button>
                                            </td>

                                        </tr>
                                    )) : ''}
                                </tbody>
                            </Table>

                            <CardFooter className="py-4">
                                <nav aria-label="...">

                                    <Pagination
                                        className="pagination justify-content-end mb-0"
                                        listClassName="justify-content-end mb-0"
                                    >
                                        <PaginationItem>
                                            <PaginationLink
                                                onClick={(e) => {
                                                    if (actualPage > 1) {
                                                        setActualPage(actualPage - 1);
                                                    }
                                                }}
                                                tabIndex="-1"
                                            >
                                                <i className="fas fa-angle-left" />
                                                <span className="sr-only">Previous</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                        {
                                            Array(list ? Math.round(list.customers.length / 5) === 0 ? 1 : Number((list.customers.length / 5).toFixed(1).split('.')[1]) > 5 ? Math.round(list.customers.length / 5) : Math.round(list.customers.length / 5) + 1 : 1).fill('').map((_, index) => {
                                                return (
                                                    <PaginationItem className={actualPage === (index + 1) ? 'active' : ''} key={index}>
                                                        <PaginationLink
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setActualPage(index + 1);
                                                            }}
                                                        >
                                                            {index + 1}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                )
                                            })
                                        }

                                        <PaginationItem>
                                            <PaginationLink
                                                onClick={(e) => {
                                                    if (actualPage < Math.round(list.customers.length / 5)) {
                                                        setActualPage(actualPage + 1);
                                                    }
                                                }}
                                            >
                                                <i className="fas fa-angle-right" />
                                                <span className="sr-only">Next</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                    </Pagination>

                                </nav>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default Elements;
