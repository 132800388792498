import React, { useEffect, useState } from "react";

import {
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    Button,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    // Badge,
    UncontrolledTooltip,
    Modal,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from "reactstrap";
import ReactDatetime from "react-datetime";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import swal from "sweetalert";
import api from '../../../util/api';
import formatDate from '../../../util/formatDate';
import useList from '../../../hooks/useList';
import usePagination from '../../../hooks/usePagination';


function Elements() {

    // const { list, fetchList, order, setOrder } = useList('/expenses');
    const [allExpenses, setAllExpenses] = useState([]);
    const [list, setList] = useState([]);
    // const { actualPage, setActualPage } = usePagination();
    const [order, setOrder] = useState('date-DESC')

    const [description, setDescription] = useState('');
    const [date, setStartDate] = useState(new Date(Date.now()));
    const [futureDate, setFutureDate] = useState('');
    const [amount, setAmount] = useState('');
    const [recurrent, setRecurrent] = useState(true);
    const [isVariable, setIsVariable] = useState(false);
    const [dueDate, setDueDate] = useState('');
    const [submit, setSubmit] = useState(false);
    const [category, setCategory] = useState('');
    const [months, setMonths] = useState(1);

    const [endDate, setEndDate] = useState(new Date(Date.now()));

    const [currentPage, setCurrentPage] = useState(0);
    const [offset, setOffset] = useState(0);

    const [data, setData] = useState([]);

    const [payment_frequence, setPaymentFrequence] = useState('');

    const [newDescription, setNewDescription] = useState('');
    const [newDate, setNewDate] = useState('');
    const [newAmount, setNewAmount] = useState('');
    const [newCategory, setNewCategory] = useState('');
    const [newRecurrent, setNewRecurrent] = useState(false);
    const [newVariable, setNewVariable] = useState(false);
    const [newPaymentFrequence, setNewPaymentFrequence] = useState('');

    const [modal, setModal] = useState(false);
    const [updateId, setUpdateId] = useState(null);
    const [oldData, setOldData] = useState({});
    const [editExpense, setEditExpense] = useState({});
    const [sort, setSort] = useState(0);

    // useEffect(() => {
    //     setFutureDate(date);
    //     setFutureDate(addMonths(new Date(futureDate), months).toLocaleDateString());
    // }, [months, date]);

    const compare = (a, b) => {
        if (a > b) return 1;
        if (a < b) return -1;

        return 0;
    }

    console.log(date, months);

    useEffect(() => {
        if (sort === 0) {
            setOrder('date-DESC');
        } else if (sort === 1) {
            setOrder('date-ASC');
        } else if (sort === 2) {
            setOrder('recurrent-DESC');
        } else if (sort === 3) {
            setOrder('recurrent-ASC');
        } else if (sort === 4) {
            setOrder('variable-DESC');
        } else if (sort === 5) {
            setOrder('variable-ASC');
        } else if (sort === 6) {
            setOrder('payment_frequency-ASC');
        } else if (sort === 7) {
            setOrder('payment-frequency-DESC');
        } else if (sort === 8) {
            setOrder('category-DESC');
        } else if (sort === 9) {
            setOrder('category-ASC');
        } else if (sort === 10) {
            setOrder('amount-DESC');
        } else if (sort === 11) {
            setOrder('amount-ASC');
        }
    }, [sort]);
    useEffect(() => {
        api.get(`/expenses?order=${order}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            if(res.data.success){
                setList(res.data.expenses);
            }else {
                setList(null);
            }
        })
    }, [order, submit]);
    useEffect(() => {
        const all = [...list];
        const paginated = all.splice(currentPage === 0 ? 0 : currentPage + 30, all.length - (all.length - 30));
        // const paginated = all.length > 30 ? all.splice(currentPage === 0 ? 0 : currentPage + 30, all.length - 30) : all;
        console.log(paginated);
        setData(paginated);
        console.log(currentPage)
    }, [currentPage, list])

    console.log(list);

    const token = localStorage.getItem('token');

    const categoryMap = [
        'Advertising & Promotion',
        'Depreciation & Amortization',
        'Insurance',
        'Maintenance',
        'Office Supplies',
        'Rent',
        'Salaries, Benefits & Wages',
        'Telecommunication',
        'Travel',
        'Utilities',
        'Licenses'
    ]

    const updateInit = (id) => {
        setUpdateId(id);
        api.get(`/expenses/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            if (res.data.success) {
                setOldData(res.data.expenses);
            }
        }).catch(e => console.log(e));;
    }

    const insertExpenses = async (e) => {
        e.preventDefault();
        if (description === '') {
            await swal('Atention', 'Insert a description', 'warning');
            return;
        }
        if (amount === '') {
            await swal('Atention', 'Insert a description', 'warning');
            return;
        }
        if (category === '') {
            await swal('Atention!', 'Select a category!', 'warning');
            return;
        }
        api.post('/expenses/create', {
            description,
            date,
            amount: Number(amount),
            recurrent: recurrent,
            category,
            payment_frequence,
            variable: isVariable,
            months
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(async res => {
            if (res.data.success) {
                await swal('Success!', 'Expense registered succesfully!', 'success');
                setDescription('');
                setStartDate(new Date().toJSON().slice(0, 10));
                setAmount('');
                setRecurrent(false);
                setSubmit(!submit);
            } else {
                await swal('Atention!', res.data.message, 'warning');
            }
        }).catch(async e => {
            await swal('Error', 'An Error ocurred', 'error');
            console.log(e);
        });
    }
    const deleteExpense = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this expense",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    api.delete(`/expenses/${id}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }).then(async res => {
                        if (res.data.success) {
                            await swal('Success!', 'Expense deleted!', 'success');
                        } else {
                            await swal('Error', 'An error ocurred', 'error');
                        }
                        setSubmit(!submit);
                    }).catch(async e => {
                        await swal('Error', 'An error ocurred', 'error');
                        console.log(e);
                    });
                } else {
                    return;
                }
            });
    }
    const saveChanges = (e) => {
        e.preventDefault();

        api.put(`/expenses/${updateId}`, {
            description: newDescription,
            date: newDate,
            amount: newAmount,
            recurrent: newRecurrent,
            variable: newVariable,
            category: newCategory,
            payment_frequence: newPaymentFrequence
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(async res => {
            if (res.data.success) {
                await swal('Success!', 'Expense edited sucessfully', 'success');
                setModal(false);
                window.location.href = '/admin/expenses';
            } else {
                await swal('Error!', res.data.message, 'error');
            }
        }).catch(async e => {
            await swal('Error', 'An error ocurred!', 'error');
            console.log(e);
        });
    }

    function monthDiff(from, to) {
        const years = to.getFullYear() - from.getFullYear();
        const months = to.getMonth() - from.getMonth();
        return 12 * years + months;
    }
    function addMonths(date, months) {
        const endDate = new Date(date.getTime());
        const originalTimeZoneOffset = endDate.getTimezoneOffset();
        endDate.setMonth(endDate.getMonth() + months);
        while (monthDiff(date, endDate) > months) {
            endDate.setDate(endDate.getDate() - 1);
        }
        const endTimeZoneOffset = endDate.getTimezoneOffset();
        const diff = endTimeZoneOffset - originalTimeZoneOffset;
        const finalDate = diff ? endDate.setMinutes(endDate.getMinutes() - diff) : endDate;
        return new Date(finalDate);
      }
    useEffect(() => {
        var x = Number(months); //or whatever offset
        var CurrentDate = new Date(date);
        console.log("Current date:", CurrentDate.toLocaleDateString());
        
        setFutureDate(addMonths(CurrentDate, x).toLocaleDateString());
    }, [months, date])

    console.log(recurrent);

    // function addMonths(date, months) {
    //     var d = date.getDate();
    //     date.setMonth(date.getMonth() + +months);
    //     if (date.getDate() != d) {
    //       date.setDate(0);
    //     }
    //     return date;
    // }

    return (
        <>
            <Modal
                style={{ minWidth: '80%' }}
                className="modal-dialog-centered"
                isOpen={modal}
                toggle={() => setModal(false)}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                        Edit this expense
                    </h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col md="3" sm="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="example4cols1Input"
                                >
                                    Description
                                </label>
                                <Input
                                    id="description_field"
                                    placeholder="Description"
                                    type="text"
                                    name="description"
                                    required
                                    value={newDescription}
                                    onChange={e => setNewDescription(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="3" sm="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="date_field"
                                >
                                    Date
                                </label>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-calendar-grid-58" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <ReactDatetime
                                        onChange={e => setNewDate(e)}
                                        initialViewDate={oldData.date}
                                        initialValue={oldData.date}
                                        value={newDate}
                                        inputProps={{
                                            placeholder: "New Date",
                                            required: true,
                                            id: 'date_field'
                                        }}
                                        timeFormat={false}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col md="3" sm="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="amount_field"
                                >
                                    Amount
                                </label>
                                <Input
                                    id="amount_field"
                                    placeholder="Amount"
                                    type="text"
                                    name="amount"
                                    value={newAmount}
                                    required
                                    onChange={e => setNewAmount(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="3" sm="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="recurrent_field"
                                >
                                    Is Recurrent?
                                </label><br />
                                <input type="checkbox" checked={newRecurrent} onChange={() => setNewRecurrent(!newRecurrent)} />

                            </FormGroup>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 20 }}>
                        <Col md="3" sm="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="example5cols3Input"
                                >
                                    Payment Frequence
                                </label>
                                <Input required type="select" className="form-control" onChange={e => setNewPaymentFrequence(e.target.value)}>
                                    <option value="1">Just this month</option>
                                    <option value="2">Always on the same day of month</option>
                                    <option value="3">Yearly</option>
                                </Input>
                            </FormGroup>
                        </Col>

                        <Col md="3" sm="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="category_field"
                                >
                                    Category
                                </label>
                                <Input required onChange={e => setNewCategory(e.target.value)} type="select" name="category" id="category_field">
                                    <option selected value="n">Select a category</option>
                                    {categoryMap.map(item => (
                                        <>
                                            <option value={item}>{item}</option>
                                        </>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md="3" sm="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="variable_checkbox"
                                >
                                    Is variable?
                                </label><br />
                                <input type="checkbox" checked={newVariable} onChange={() => setNewVariable(!newVariable)} />

                            </FormGroup>
                        </Col>
                        <Col md="3" sm="6">

                        </Col>
                    </Row>

                </div>
                <div className="modal-footer">
                    <Button onClick={saveChanges} color="primary" type="button">
                        Save changes
                    </Button>
                    <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setModal(false)}
                    >
                        Close
                    </Button>
                </div>
            </Modal>

            {/* // ------------ //
            // FIM DO MODAL //
            // ------------ // */}

            <SimpleHeader name="Expenses" parentName="" />
            <Container className="mt--6" fluid>
                <Card className="mb-4">
                    <CardHeader>
                        <h3 className="mb-0">Expenses input</h3>
                    </CardHeader>
                    <CardBody>
                        <form onSubmit={insertExpenses}>
                            <Row>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example4cols1Input"
                                        >
                                            Description
                                        </label>
                                        <Input
                                            id="description_field"
                                            placeholder="Description"
                                            type="text"
                                            name="description"
                                            required
                                            value={description}
                                            onChange={e => setDescription(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="date_field"
                                        >
                                            Date
                                        </label>
                                        <InputGroup className="input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-calendar-grid-58" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <ReactDatetime
                                                onChange={e => {
                                                    setStartDate(e);
                                                    setEndDate(e);
                                                }}
                                                initialViewDate={new Date(Date.now())}
                                                initialValue={new Date(Date.now())}
                                                value={date}
                                                inputProps={{
                                                    placeholder: "Date",
                                                    required: true,
                                                    id: 'date_field'
                                                }}
                                                timeFormat={false}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                </Col>
                                <Col md="2" sm="3">

                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="recurrent_field"
                                        >
                                            Is Recurrent?
                                        </label><br />
                                        <input type="checkbox" defaultChecked={true} checked={recurrent} onChange={() => setRecurrent(!recurrent)} />
                                    </FormGroup>
                                </Col>
                                <Col md="2" sm="3">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="variable_checkbox"
                                        >
                                            Is variable?
                                        </label><br />
                                        <input type="checkbox" checked={isVariable} onChange={() => setIsVariable(!isVariable)} />
                                    </FormGroup>
                                </Col>

                                <Col md="2" sm="6">
                                    {recurrent && !isVariable ? 
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example4cols1Input"
                                        >
                                            Months
                                        </label>
                                        <Input
                                            id="description_field"
                                            placeholder="Months"
                                            type="number"
                                            min={1}
                                            max={12}
                                            name="description"
                                            required
                                            value={months}
                                            onChange={e => {
                                                setMonths(e.target.value);
                                                // setFutureDate(addMonths(endDate, Number(e.target.value)));
                                            }}
                                        />
                                    </FormGroup> : ''}
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: 20 }}>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="example5cols3Input"
                                        >
                                            Payment Frequence
                                        </label>
                                        <Input required type="select" className="form-control" onChange={e => setPaymentFrequence(e.target.value)}>
                                            <option value="1">Just this month</option>
                                            <option value="2">Always on the same day of month</option>
                                            <option value="3">Yearly</option>
                                        </Input>
                                    </FormGroup>
                                </Col>

                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="category_field"
                                        >
                                            Category
                                        </label>
                                        <Input required onChange={e => setCategory(e.target.value)} type="select" name="category" id="category_field">
                                            <option selected value="n">Select a category</option>
                                            {categoryMap.map(item => (
                                                <>
                                                    <option value={item}>{item}</option>
                                                </>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="amount_field"
                                        >
                                            Amount
                                        </label>
                                        <Input
                                            id="amount_field"
                                            placeholder="Amount"
                                            type="text"
                                            name="amount"
                                            value={amount}
                                            required
                                            onChange={e => setAmount(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3" sm="6">
                                    <label
                                        className="form-control-label"
                                        htmlFor="final_date_field"
                                    >
                                        Final Date
                                    </label>
                                    <Input
                                        id="final_date_field"
                                        placeholder={futureDate}
                                        disabled={true}
                                        type="text"
                                        name="final_date_field"
                                        value={futureDate}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Button color="primary" type="submit">
                                        Insert
                                    </Button>
                                </Col>
                            </Row>

                        </form>
                    </CardBody>
                </Card>
                <Row>
                    <div className="col">

                        <Card>
                            <CardHeader className="border-0">
                                <h3 className="mb-0">Expenses</h3>
                            </CardHeader>

                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col"> Description </th>
                                        <th onClick={() => {
                                            if (sort === 0) {
                                                setSort(1);
                                            } else if (sort === 1) {
                                                setSort(0);
                                            } else {
                                                setSort(0);
                                            }
                                        }} data-sort="date" className="sort" scope="col"> Date </th>
                                        <th onClick={() => {
                                            if (sort === 10) {
                                                setSort(11);
                                            } else if (sort === 11) {
                                                setSort(10);
                                            } else {
                                                setSort(10);
                                            }
                                        }} data-sort="amount" scope="col" className="sort"> Amount </th>
                                        <th className="sort" >Recurrent</th>
                                        <th scope="col">Variable</th>
                                        <th scope="col">Payment Frequence</th>
                                        <th className="sort" scope="col" data-sort="category">Category</th>
                                        <th scope="col"> Action </th>
                                    </tr>
                                </thead>
                                <tbody className="list">
                                    {data ? data.map((item) => (
                                        <tr>
                                            <td scope="row"> {item.description} </td>
                                            <td className="budget">{formatDate(item.date)}</td>
                                            <td onClick={() => {
                                                if (sort === 10) {
                                                    setSort(11);
                                                } else if (sort === 11) {
                                                    setSort(10);
                                                } else {
                                                    setSort(10);
                                                }
                                            }}>
                                                US {item.amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                                            </td>
                                            <td> {item.recurrent ? 'Yes' : 'No'} </td>
                                            <td> {item.variable ? 'Yes' : 'No'} </td>
                                            <td> {item.payment_frequency} </td>
                                            <td> {item.category} </td>

                                            <td className="text-right">
                                                <UncontrolledDropdown>
                                                    <DropdownToggle
                                                        className="btn-icon-only text-light"
                                                        color=""
                                                        role="button"
                                                        size="sm"
                                                    >
                                                        <i className="fas fa-ellipsis-v" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-arrow" right>
                                                        <DropdownItem
                                                            href="#pablo"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                updateInit(item.id);
                                                                setModal(true);
                                                            }}
                                                        >
                                                            Edit
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            href="#pablo"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                deleteExpense(item.id);
                                                            }}
                                                        >
                                                            Delete
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </td>
                                        </tr>
                                    )) : ''}
                                </tbody>
                            </Table>

                            <CardFooter className="py-4">
                                <nav aria-label="...">

                                    <Pagination
                                        className="pagination justify-content-end mb-0"
                                        listClassName="justify-content-end mb-0"
                                    >
                                        <PaginationItem>
                                            <PaginationLink
                                                onClick={(e) => {
                                                    setCurrentPage(currentPage === 0 ? 0 : currentPage - 1);
                                                }}
                                                tabIndex="-1"
                                            >
                                                <i className="fas fa-angle-left" />
                                                <span className="sr-only">Previous</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                        {
                                            Array(list ? Math.round(list.length / 30) === 0 ? 1 : Number((list.length / 30).toFixed(1).split('.')[1]) > 5 ? Math.round(list.length / 30) : Math.round(list.length / 30) + 1 : 1).fill('').map((_, index) => {
                                                return (
                                                    <PaginationItem className={index === currentPage ? "active" : ''} key={index}>
                                                        <PaginationLink
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setCurrentPage(index)
                                                            }}
                                                        >
                                                            {index + 1}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                )
                                            })
                                        }

                                        <PaginationItem>
                                            <PaginationLink
                                                onClick={(e) => {
                                                    setCurrentPage(currentPage === (list ? Math.round(list.length / 30) === 0 ? 1 : Number((list.length / 30).toFixed(1).split('.')[1]) > 5 ? Math.round(list.length / 30) : Math.round(list.length / 30) + 1 : 1) - 1 ? (list ? Math.round(list.length / 30) === 0 ? 1 : Number((list.length / 30).toFixed(1).split('.')[1]) > 5 ? Math.round(list.length / 30) : Math.round(list.length / 30) + 1 : 1) - 1 : currentPage + 1)
                                                }}
                                            >
                                                <i className="fas fa-angle-right" />
                                                <span className="sr-only">Next</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                    </Pagination>


                                </nav>
                            </CardFooter>
                        </Card>

                    </div>
                </Row>
            </Container>
        </>
    );
}

export default Elements;
