import React, { useEffect, useState } from "react";
import Chart from "chart.js";

import { Line, Bar } from "react-chartjs-2";

import {
    Card,
    CardHeader,
    Container,
    Row,
    Table,
    Button,
    Col,
    CardBody
} from "reactstrap";

import SimpleHeader from "components/Headers/SimpleHeader.js";
import ReactLoading from 'react-loading';
import CardsHeader from "components/Headers/CardsHeader.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import api from "../../../util/api";


import {
    chartOptions,
    parseOptions,
    chartExample1,
    chartExample2,
  } from "variables/charts.js";

const currentMonth = Number(new Date().getMonth() + 1);

function Elements() {

    const [date, setStartDate] = useState(new Date(Date.now()));
    const [submit, setSubmit] = useState(false);
    const [months, setMonths] = useState(1);

    const [activeNav, setActiveNav] = React.useState(1);
    const [chartExample1Data, setChartExample1Data] = React.useState("data1");

    const [profitdata, setP] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [lossdata, setL] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

    console.log(profitdata, lossdata);

    const [refresh, setRefresh] = useState(false);

    const [expenses, setExpenses] = useState([]);
    const [allInvoices, setAllInvoices] = useState([]);
    
    const [allP, setAllP] = useState([])

    const [expensesByCategory, setExpensesByCategory] = useState([]);

    const token = localStorage.getItem('token'); 

    const [loading, setLoading] = useState(true);

    
    const categoryMap = [
        'Advertising & Promotion',
        'Depreciation & Amortization',
        'Insurance',
        'Maintenance',
        'Office Supplies',
        'Rent',
        'Salaries, Benefits & Wages',
        'Telecommunication',
        'Travel',
        'Utilities',
        'Licenses'
    ]

    function sumAllAmount(month) {
        const values = [];
        allP.map((item, i) => {
            if (item.invoices.length === 0) {
                values.push(0);
            } else {
                values.push(item.invoices.filter(function (value) {
                    if (getStripeMonth(value.created) === month) {
                        return true;
                    }
                    return false;
                }).reduce(function (tot, arr) {
                    return tot + arr.total;
                }, 0))
            }
        })
        const total = values.reduce(function (tot, arr) {
            return tot + arr;
        }, 0);
        return total / 100;
    }
        
    function getStripeMonth(time) {
        const mydate = new Date(time * 1000);
        return mydate.getMonth() + 1;
    }

    function sumByCategory(category, month) {
        const newArr = expenses.filter(function (value) {
            if (value.category === category && (new Date(value.date)).getMonth() + 1 === month) {
                return true;
            }
            return false;
        })
        if (newArr.length === 0) {
            return 0;
        }
        var total = 0;
        newArr.map((item, i) => {
            total += item.amount;
        });
        return total;
    }
        
    function sumAllExpensePerMonth(month) {
        const newArr = expenses.filter(function (value) {
            if ((new Date(value.date)).getMonth() + 1 === month) {
                return true;
            }
            return false;
        })
        if (newArr.length === 0) {
            return 0;
        }
        var total = 0;
        newArr.map((item, i) => {
            total += item.amount;
        });
        return total;
    }

    useEffect(() => {
        
          api.get('/sales', {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }).then(res => {
            if (res.data.success) {
            
            console.log(res.data.sales);
              setP(res.data.sales);
              setLoading(false);
            }
          })
        api.get('/expenses?offset=0&order=category-DESC', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            if (res.data.success) {
                
                console.log(res.data.expensesByMonth);
                setL(res.data.expensesByMonth)
                setExpensesByCategory(res.data.expensesByCategory);
                setExpenses(res.data.allExpenses);
            }
        })
    }, []);
    if (window.Chart) {
        parseOptions(Chart, chartOptions());
    }
   
    return (
        <>
        <SimpleHeader name="P&L Analytics" parentName="" />
        <Container className="mt--6" fluid>
            <Row>
                <Col xl="12">
                    <Card className="bg-white">
                    <CardHeader className="bg-transparent">
                        <Row className="align-items-center">
                        <div className="col">
                            <h6 className="text-light text-uppercase ls-1 mb-1"> Overview </h6>
                            <h5 className="h3 text-white mb-0">Total loss by category</h5>
                        </div>
                        <div className="col"> 
                            {loading ?
                            <>
                            <p>Wait... loading stripe data</p>
                            <ReactLoading type="spinningBubbles" color="#c9c9c9" width="60px" />
                            </>
                            : ''}
                        </div>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <div className="chart">
                                <Bar
                                    data={{
                                        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                                        datasets: [
                                            {
                                            label: "Loss",
                                            data: lossdata,
                                            backgroundColor: "#ff0008",
                                            borderColor: "#ff0008",
                                            borderWidth: 1,
                                            // data: [100, 200, 300, 400, 100, 200, 500, 600, 500, 100, 400, 800]
                                            datalabels: {
                                                anchor: 'end',
                                                align: 'end',
                                                offset: 5
                                              },
                                            },

                                            {
                                            label: "Profits",
                                            backgroundColor: "#0373fc",
                                            borderColor: "#0373fc",
                                            borderWidth: 1,
                                            data: profitdata,
                                            // data: [100, 200, 300, 400, 100, 200, 500, 600, 500, 100, 400, 800]
                                            datalabels: {
                                                anchor: 'end',
                                                align: 'end',
                                                offset: 5
                                              },
                                            }
                                        ]
                                    }}
                                    plugins={[ChartDataLabels]}
                                    options={chartExample2.options}
                                    className="chart-canvas"
                                    id="chart-bars"
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>

                <Col xl="12">
                    <Card className="bg-white">
                        <CardHeader className="bg-transparent">
                            <Row className="align-items-center">
                                <div className="col">
                                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                                        Loss
                                    </h6>
                                    <h5 className="h3 mb-0">Total loss by category</h5>
                                </div>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <div className="chart">
                                <Bar
                                    data={{
                                        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                                        datasets: expensesByCategory
                                    }}
                                    options={{
                                        responsive: true,
                                        legend: {
                                            display: true,
                                            position: 'top'
                                        },
                                    }}
                                    
                                />
                            </div>
                        </CardBody>
                    </Card>

                </Col>
            </Row>
        </Container>
        </>
    );
}

export default Elements;
