import {useState} from 'react';
import api from '../util/api';

export default function useList(url){
    const [list, setList] = useState();
    const [order, setOrder] = useState('date-DESC');

    function fetchList(page){
        const token = localStorage.getItem('token');
        const virtualPage = ((page - 1) * 5) <= 0 ? 0 : ((page - 1) * 5);
        
        api.get(`${url}?offset=${virtualPage}&order=${order}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => {
            setList(res.data);
        })
        .catch(window.alert);
    }

    return {
        fetchList,
        list,
        setOrder,
        order
    }
}