import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, FormGroup, Button } from 'reactstrap';
import api from 'util/api';

const ModalCompany = ({ modal, setModal, id }) => {
    const [company, setCompany] = useState({});
    useEffect(() => {
        if(modal){
            api.get(`/companies/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then(res => {
                if(res.data.success){
                    setCompany(res.data.company);
                }
            })
        }
    }, [modal, id]);
    return(
        <Modal
				style={{ minWidth: '80%' }}
				className="modal-dialog-centered"
				isOpen={modal}
				toggle={() => setModal(false)}
			>
				<div className="modal-header">
					<h6 className="modal-title" id="modal-title-default">
						Company Info
					</h6>
					<button
						aria-label="Close"
						className="close"
						data-dismiss="modal"
						type="button"
						onClick={() => setModal(false)}
					>
						<span aria-hidden={true}>×</span>
					</button>
				</div>
				<div className="modal-body">
					<Row>
						<Col>
							<ul>
                                <li>Alias: {company.alias ? company.alias : ''}</li>
                                <li>Decision Maker: {company.decision_maker ? company.decision_maker : ''}</li>
                                <li>Email: {company.email ? company.email : ''}</li>
                                <li>Phone: {company.phone ? company.phone : ''}</li>
                            </ul>
						</Col>
					</Row>
				</div>
				<div className="modal-footer">
					<Button
						className="ml-auto"
						color="link"
						data-dismiss="modal"
						type="button"
						onClick={() => setModal(false)}
					>
						Close
					</Button>
				</div>
			</Modal>
    );
}

export default ModalCompany;