import React, { useEffect, useState } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Modal,
  Collapse,
  InputGroupAddon,
  InputGroupText,
  InputGroup
} from "reactstrap";
import ReactDatetime from "react-datetime";
import formatDate from '../../../util/formatDate';
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import swal from "sweetalert";
import api from '../../../util/api';
import ModalCompany from '../../../components/ModalCompany';
import changeTypes from '../../../util/changeTypes';
import sum from '../../../util/sum';
import usePagination from '../../../hooks/usePagination';
import useList from '../../../hooks/useList';


function Elements() {

  const token = localStorage.getItem('token');

  const { setActualPage, actualPage } = usePagination();
  const { list, fetchList } = useList('/contracts');

  const [modal, setModal] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const [dealSizeList, setDealSizeList] = useState([{ dealSize: 0, price_id: '', price_nickname: '', price_frequence: {}, product_id: '' }]);
  const [expect_close_date, setExpectCloseDate] = useState(new Date(Date.now()));
  const [deal_close_date, setDealCloseDate] = useState(new Date(Date.now()));
  const [length, setLength] = useState('');
  const [fup_date, setFupDate] = useState(new Date(Date.now()));
  const [reason_out, setReasonOut] = useState('');
  const [trial_period, setTrialPeriod] = useState(0);
  const [company_id, setCompanyId] = useState(1);
  const [deal_stage_id, setDealStageId] = useState(1);

  const [dealStageId, setDealStageId2] = useState(null);

  const [allCompanies, setAllCompanies] = useState([]);
  const [allDealStage, setAllDealStage] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [allPrices, setAllPrices] = useState([]);
  const [pricesId, setPricesId] = useState([]);

  // console.log(allPrices)

  const [submit, setSubmit] = useState(true);

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...dealSizeList];
    list[index]['dealSize'] = value.split('%')[0];
    list[index]['price_id'] = value.split('%')[1];
    list[index]['price_nickname'] = value.split('%')[2];
    list[index]['price_frequence'] = {
      interval: value.split('%')[3].split('&')[0],
      intervalCount: value.split('%')[3].split('&')[1]
    }
    list[index]['product_id'] = value.split('%')[4];
    const dealSizeTrunc = list.filter(function (a) {
      return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
    }, Object.create(null))
    setDealSizeList(dealSizeTrunc);
  };

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...dealSizeList];
    list.splice(index, 1);
    setDealSizeList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setDealSizeList([...dealSizeList, { dealSize: 0, price_id: '', price_nickname: '', price_frequence: {}, product_id: '' }]);
  };

  const dealStageName = ['Prospect', 'Contacted', 'Decision Maker', 'Proposal Sent', 'Contract Sent', 'Closed Won', 'Closed Lost', 'Inactive'];

  const [contractId, setContractId] = useState(null);

  const [companyId, setCompanyIde] = useState(1);
  const [companyModal, setCompanyModal] = useState(false);

  useEffect(() => {
    api.get('/products/all', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(res => {
      if (res.data.success) {
        setAllProducts(res.data.products);
        setAllPrices(res.data.prices);
      }
    })
  }, [token, submit]);

  const showCompany = async (id) => {
    setCompanyIde(id);
    setCompanyModal(true);
  }

  const saveChanges = (e) => {
    e.preventDefault();
    api.put(`/contracts/${contractId}`, {
      dealStageId
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(async res => {
      if (res.data.success) {
        await swal('Success!', 'Deal stage edited sucessfully', 'success');
        setModal(false);
        window.location.href = '/admin/contracts';
      } else {
        await swal('Error!', res.data.message, 'error');
      }
    }).catch(async e => {
      await swal('Error', 'An error ocurred!', 'error');

    });
  }

  const deleteContract = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this contract",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          api.delete(`/contracts/${id}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }).then(async res => {
            if (res.data.success) {
              await swal('Deleted!', 'Contract deleted successfully', 'success');
              window.location.href = '/admin/contracts'
            }
          }).catch(e => console.log(e));
        } else {
          return;
        }
      });
    setSubmit(!submit);
  }

  useEffect(() => {
    api.get('/companies/all', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(res => {
      if (res.data.success) {
        setAllCompanies(res.data.companies);
      }
    }).catch(e => console.log(e));
    api.get('/contracts/deal-stages', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(res => {
      if (res.data.success) {
        setAllDealStage(res.data.dealStages);
      }
    }).catch(e => console.log(e));
  }, [token]);

  useEffect(() => {
    fetchList(actualPage ? actualPage : 1);
  }, [submit, actualPage])

  const insertContract = (e) => {
    const dealSizeTotal = sum(dealSizeList) / 100;
    e.preventDefault();
    api.post('/contracts/create', {
      deal_size: dealSizeTotal, expect_close_date, deal_close_date, length, fup_date, trial_period, company_id, deal_stage_id, product_id: dealSizeList
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(async res => {
      if (res.data.success) {
        await swal('Success!', 'This contract is registered succesfully!', 'success');
        console.log('Ok');
        setSubmit(!submit);
        setIsOpen(false)
        setDealSizeList([{ dealSize: 0, price_id: '', price_nickname: '', price_frequence: {}, product_id: '' }])
        setLength('')
        setReasonOut('')
        setTrialPeriod('')
      } else {
        await swal('Error!', res.data.message, 'error');
      }
    }).catch(e => console.log(e));
  }

  return (
    <>
      <ModalCompany modal={companyModal} setModal={setCompanyModal} id={companyId} />

      <Modal
        style={{ minWidth: '80%' }}
        className="modal-dialog-centered"
        isOpen={modal}
        toggle={() => setModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Edit this contract
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col>
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="deal_stage_next_field"
                >
                  Select the next deal stage
                </label>
                {allDealStage.length > 1 ? <><Input onChange={e => setDealStageId2(e.target.value)} id="deal_stage_next_field" type="select" name="select" id="example4cols3Input">
                  {allDealStage.map(item => (
                    <>
                      <option key={item.id} value={item.id}>{item.name}</option>
                    </>
                  ))}
                </Input></> : ''}
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button onClick={saveChanges} color="primary" type="button">
            Save changes
          </Button>
          <Button
            className="ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setModal(false)}
          >
            Close
          </Button>
        </div>
      </Modal>

      <SimpleHeader name="Contracts" parentName="" />
      <Container className="mt--6" fluid>

        <Row>
          <Col>
            <Card className="mb-4">
              <CardHeader>
                <h3 className="mb-0">Create contracts</h3>
                <Button style={{ marginTop: 20 }} onClick={() => setIsOpen(!isOpen)}>{isOpen ? 'Close' : 'Open'}</Button>
              </CardHeader>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Collapse isOpen={isOpen}>
              <Card className="mb-4">
                <CardHeader>
                  <h3 className="mb-0">Contract input</h3>
                </CardHeader>
                <CardBody>
                  <form onSubmit={insertContract}>
                    <Row>
                      <Col md="6" sm="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="deal_size_field"
                          >
                            Products
                          </label>
                          <Row>

                            {dealSizeList.map((x, i) => {
                              return (
                                <>
                                  <Col key={i} style={{ marginBottom: 10 }} md={6}>

                                    <Input required name="deal_size" onChange={e => handleInputChange(e, i)} type="select">
                                      <option selected>Select a product</option>
                                      {allProducts.map(item => (
                                        <>
                                          <optgroup key={item.id} label={item.name}>
                                            {allPrices.filter(function (value) {
                                              if (value.product === item.id)
                                                return value;
                                            }).map(price => (
                                              <>
                                                <option key={price.id} value={`${price.unit_amount}%${price.id}%${price.nickname}%${price.type === 'recurring' ? price.recurring.interval + '&' + price.recurring.interval_count : 'one_time&1'}%${item.id}`}>{`${price.nickname === null ? 'Unnamed' : price.nickname} - US ${(price.unit_amount / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} - ${changeTypes(price.type)}`}</option>
                                              </>
                                            ))}
                                          </optgroup>
                                        </>
                                      ))}
                                    </Input>
                                  </Col>
                                  <Col md={6}>
                                    {dealSizeList.length - 1 === i && <Button onClick={handleAddClick}>Add</Button>}
                                    {dealSizeList.length !== 1 && <Button onClick={() => handleRemoveClick(i)}>Delete</Button>}
                                  </Col>
                                </>
                              );
                            })}
                          </Row>
                        </FormGroup>
                      </Col>
                      <Col md="4" sm="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="expect_close_date"
                          >
                            Deal Size
                          </label>
                          <Col md="10">
                            <h3>{isNaN(sum(dealSizeList)) ? 'US $0.00' : (sum(dealSizeList) / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</h3>
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3" sm="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="expect_close_date"
                          >
                            Expect close date
                          </label>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-calendar-grid-58" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <ReactDatetime
                              onChange={e => setExpectCloseDate(e)}
                              initialViewDate={new Date(Date.now())}
                              initialValue={new Date(Date.now())}
                              value={expect_close_date}
                              inputProps={{
                                placeholder: "Expect close date",
                                required: true,
                                id: 'expect_close_date'
                              }}
                              isValidDate={(current) => {
                                return current.day() !== 0 && current.day() !== 6;
                              }}
                              timeFormat={false}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md="3" sm="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="lenght_field"
                          >
                            Contract duration
                          </label>
                          <Input
                            id="lenght_field"
                            placeholder="Contract duration"
                            type="text"
                            name="lenght"
                            value={length}
                            required
                            onChange={e => setLength(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3" sm="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="fup_date_field"
                          >
                            FUP date
                          </label>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-calendar-grid-58" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <ReactDatetime
                              onChange={e => setFupDate(e)}
                              initialViewDate={new Date(Date.now())}
                              initialValue={new Date(Date.now())}
                              value={fup_date}
                              inputProps={{
                                placeholder: "FUP date",
                                required: true,
                                id: 'fup_date_field'
                              }}
                              isValidDate={(current) => {
                                return current.day() !== 0 && current.day() !== 6;
                              }}
                              timeFormat={false}
                            />
                          </InputGroup>
                        </FormGroup>

                      </Col>
                      <Col md="3" sm="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="trial_period"
                          >
                            Trial period
                          </label>
                          <Col md="10">
                            <Input
                              placeholder="Trial period"
                              id="trial_period"
                              type="text"
                              name="trial_period"
                              value={trial_period}
                              onChange={e => setTrialPeriod(e.target.value)}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>


                      <Col md="3" sm="6">

                      </Col>
                      <Col md="3" sm="6">
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3" sm="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="company_field"
                          >
                            Company
                          </label>
                          <Input required onChange={e => setCompanyId(e.target.value)} type="select" name="select" id="company_field">
                            {allCompanies.map(item => (
                              <>
                                <option key={item.id} value={item.id}>{item.alias}</option>
                              </>
                            ))}
                          </Input>
                        </FormGroup>

                      </Col>
                      <Col md="3" sm="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="deal_stage_field"
                          >
                            Deal Stage
                          </label>
                          <Input required onChange={e => setDealStageId(e.target.value)} type="select" name="select" id="deal_stage_field">
                            {allDealStage.map(item => (
                              <>
                                <option disabled={item.id === 6} key={item.id} value={item.id}>{item.name}</option>
                              </>
                            ))}
                          </Input>
                        </FormGroup>

                      </Col>
                      <Col md="3" sm="6">
                        {deal_stage_id === 7 || deal_stage_id === "7" ? <>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="reason_out_field"
                            >
                              Reason Out
                            </label>
                            <Col md="10">
                              <Input
                                placeholder="Reason out"
                                id="reason_out_field"
                                type="text"
                                value={reason_out}
                                onChange={e => setReasonOut(e.target.value)}
                              />
                            </Col>
                          </FormGroup>
                        </> : ''}
                      </Col>
                      <Col md="3" sm="6">

                      </Col>

                    </Row>
                    <Row>
                      <Col md="3" sm="6">

                      </Col>
                      <Col md="3" sm="6">

                      </Col>
                      <Col md="3" sm="6">

                      </Col>
                      <Col md="3" sm="6">

                      </Col>

                    </Row>
                    <Row>
                      <Col>
                        <Button color="primary" type="submit">
                          Insert
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Collapse>
          </Col>
        </Row>

        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <h3 className="mb-0">Contracts</h3>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th className="sort" data-sort="name" scope="col">Company ID</th>
                    <th className="sort" data-sort="name" scope="col">Deal size</th>
                    <th className="sort" data-sort="budget" scope="col">Expect close date</th>
                    <th className="sort" data-sort="status" scope="col">Deal close date</th>
                    <th className="sort" data-sort="completion" scope="col">Lenght</th>
                    <th className="sort" data-sort="completion" scope="col">FUP Date</th>
                    <th className="sort" data-sort="completion" scope="col">Sub Start Date</th>
                    <th className="sort" data-sort="completion" scope="col">Sub end date</th>
                    <th className="sort" data-sort="completion" scope="col">Trial Period</th>
                    <th className="sort" data-sort="completion" scope="col">Deal stage</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody className="list">
                  {list ? list.contracts.map((item, i) => (
                    <tr key={i}>
                      <th scope="row">
                        <a onClick={() => showCompany(item.company_id)} title="Click here to see company name" style={{ cursor: 'pointer' }}>{item.company_id}</a>
                      </th>
                      <td scope="row">
                        US {Number(item.deal_size).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                      </td>
                      <td> {formatDate(item.expect_close_date)} </td>
                      <td> {formatDate(item.deal_close_date)} </td>
                      <td> {item.lenght} </td>
                      <td> {formatDate(item.fup_date)} </td>
                      <td> {item.sub_start_date ? formatDate(item.sub_start_date) : ''} </td>
                      <td> {item.sub_end_date ? formatDate(item.sub_end_date) : ''} </td>
                      <td> {item.trial_period} </td>
                      <td> {dealStageName[Number(item.deal_stage_id) - 1]} </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            color=""
                            role="button"
                            size="sm"
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              onClick={(e) => deleteContract(item.id)}
                            >
                              Delete
                            </DropdownItem>
                            <DropdownItem
                              onClick={(e) => {
                                setContractId(item.id);
                                setModal(true);
                              }}
                            >
                              Change stage
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  )) : ''}
                </tbody>
              </Table>

              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem className="disabled">
                      <PaginationLink
                        onClick={(e) => {
                          if (actualPage > 1) {
                            setActualPage(actualPage - 1);
                          }
                        }}
                        tabIndex="-1"
                      >
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    {
                      Array(list ? Math.round(list.allContracts.length / 5) === 0 ? 1 : Number((list.allContracts.length / 5).toFixed(1).split('.')[1]) >= 5 ? Math.round(list.allContracts.length / 5) : Math.round(list.allContracts.length / 5) + 1 : 1).fill('').map((_, index) => {
                        return (
                          <PaginationItem className={actualPage === (index + 1) ? 'active' : ''} key={index}>
                            <PaginationLink
                              onClick={(e) => {
                                e.preventDefault();
                                setActualPage(index + 1);
                              }}
                            >
                              {index + 1}
                            </PaginationLink>
                          </PaginationItem>
                        )
                      })
                    }
                    <PaginationItem>
                      <PaginationLink
                        onClick={(e) => {
                          if (actualPage < Math.round(list.allContracts.length / 5)) {
                            setActualPage(actualPage + 1);
                          }
                        }}
                      >
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Next</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>

                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Elements;
