import React, { useEffect, useState } from "react";

import {
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    Button,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    UncontrolledTooltip,
    Modal
} from "reactstrap";
import Select2 from "react-select2-wrapper";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import swal from "sweetalert";
import api from '../../../util/api';
import formatDate from '../../../util/formatDate';
import validate from '../../../util/validate';
import useList from '../../../hooks/useList';
import usePagination from '../../../hooks/usePagination';
import ReactLoading from 'react-loading';

function Elements() {

    const token = localStorage.getItem('token');

    const { list, fetchList } = useList('/user/all');
    const { actualPage, setActualPage } = usePagination();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [type, setType] = useState(null);
    const [birth_date, setBirthDate] = useState(new Date());
    const [status, setStatus] = useState(true);
    const [job_position, setJobPosition] = useState('Customer');
    const [avatar_url, setAvatarUrl] = useState('none');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [zip_code, setZipCode] = useState('');
    const [address, setAddress] = useState('');

    const [newEmail, setNewEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newType, setNewType] = useState(null);
    const [newStatus, setNewStatus] = useState(true);
    const [newJobPosition, setNewJobPosition] = useState('');
    const [newCountry, setNewCountry] = useState('');
    const [newState, setNewState] = useState('');
    const [newCity, setNewCity] = useState('');
    const [newZipCode, setNewZipCode] = useState('');
    const [newAddress, setNewAddress] = useState('');

    const [userId, setUserId] = useState(null);

    const [modal, setModal] = useState(false);

    const [loading, setLoading] = useState(false);

    const [refresh, setRefresh] = useState(true);

    const [userInfo, setUserInfo] = useState({});

    const saveChanges = async (e) => {
        e.preventDefault();

        const email = newEmail === '' ? false : newEmail;
        const password = newPassword === '' ? false : newPassword;
        const job_position = newJobPosition === '' ? false : newJobPosition;
        const country = newCountry === '' ? false : newCountry;
        const state = newState === '' ? false : newState;
        const zip_code = newZipCode === '' ? false : newZipCode;
        const address = newAddress === '' ? false : newAddress;
        const city = newCity === '' ? false : newCity;

        if (email) {
            if (!validate.validateEmail(email)) {
                await swal('Atention!', 'Invalid email!', 'warning');
                return;
            }
        }

        console.log(state);

        api.put(`/user/${userId}`, { email, password, type: newType, status: newStatus, job_position, country, state, zip_code, address, city }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(async res => {
            if (res.data.success) {
                await swal('Success!', 'User updated succesfully!', 'success');
                setRefresh(!refresh);
                setModal(false)
            } else {
                await swal('Error!', res.data.message, 'error');
                setModal(false)
            }
        }).catch(e => {
            console.log(e);
            setModal(false)
        });
    }

    useEffect(() => {
        fetchList(actualPage);
    }, [refresh, actualPage]);
    useEffect(() => {
        if(userId !== null){
            api.get(`/user/${userId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then(res => {
                if(res.data.success){
                    setUserInfo(res.data.user)
                }
            })
        }
    }, [userId])

    console.log(list)

    const deleteUser = async (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this user",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    api.delete(`/user/${id}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }).then(async res => {
                        if (res.data.success) {
                            await swal('Success!', 'User deleted!', 'success');
                        } else {
                            await swal('Error', res.data.message, 'error');
                        }
                        setRefresh(!refresh);
                    }).catch(async e => {
                        console.log(e);
                        await swal('Error', 'An error ocurred', 'error');

                    });
                } else {
                    return;
                }
            });
    }

    console.log(type);

    const createUser = async (e) => {
        setLoading(true);
        e.preventDefault();
        if (name === '') {
            await swal('Atention!', 'Name is required!', 'warning');
            setLoading(false);
            return;
        }
        if (email === '') {
            await swal('Atention!', 'Email is required!', 'warning');
            setLoading(false);
            return;
        }
        if (!validate.validateEmail(email)) {
            await swal('Atention!', 'Invalid email!', 'warning');
            setLoading(false);
            return;
        }
        if (password === '') {
            await swal('Atention!', 'Password is required!', 'warning');
            setLoading(false);
            return;
        }
    
        if(type === null){
            await swal('Atention!', 'Select an user type', 'warning');
            setLoading(false);
            return;
        }
        if(country === ''){
            await swal('Atention!', 'Select an country', 'warning');
            setLoading(false);
            return;
        }

        

        api.post('/user/create-admin', { name, email, password, type, status, jobPosition: job_position, birthDate: birth_date, avatarUrl: avatar_url, country, state, city, zipCode: zip_code, address, projectId: String(Math.random() * 91293) }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(async res => {
            if (res.data.success) {
                await swal('Success!', 'This user is registred successfully! Email verification is required.', 'success');
                setName('');
                setEmail('');
                setPassword('');
                setCity('');
                setCountry('');
                setZipCode('');
                setRefresh(!refresh);
                setLoading(false);
            } else {
                await swal('Error!', res.data.message, 'error');
                setRefresh(!refresh);
                setLoading(false);
            }
        }).catch(async e => {
            console.log(e);
            await swal('Error', 'An error ocurred!', 'error');

            setLoading(false);
            setRefresh(!refresh);
        })
    }

    return (
        <>
            <Modal
                style={{ minWidth: '80%' }}
                className="modal-dialog-centered"
                isOpen={modal}
                toggle={() => setModal(false)}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                        Edit this users
                    </h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col md="3" sm="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="email_input_edit_user"
                                >
                                    Email
                                </label>
                                <Input
                                    id="email_input_edit_user"
                                    name="email"
                                    placeholder={userInfo.email}
                                    type="email"
                                    value={newEmail}
                                    onChange={e => setNewEmail(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="3" sm="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="password_field"
                                >
                                    Password
                                </label>
                                <Input
                                    id="password_field"
                                    placeholder="Password"
                                    type="password"
                                    name="password"
                                    value={newPassword}
                                    onChange={e => setNewPassword(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="3" sm="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="is_active_checkbox"
                                >
                                    Is Active?
                                </label><br />
                                <label className="custom-toggle mr-1">
                                    <input id="is_active_checkbox" type="checkbox" checked={newStatus} onChange={() => setNewStatus(!newStatus)} />
                                    <span
                                        className="custom-toggle-slider rounded-circle"
                                        data-label-off="No"
                                        data-label-on="Yes"
                                    />
                                </label>
                            </FormGroup>
                        </Col>
                        <Col md="3" sm="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="job_position_field"
                                >
                                    Job Position
                                </label>
                                <Input
                                    id="job_position_field"
                                    placeholder={userInfo.jobPosition}
                                    name="job_position"
                                    type="text"
                                    value={newJobPosition}
                                    onChange={e => setNewJobPosition(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3" sm="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="user_type_field"
                                >
                                    User type
                                </label>
                                <Input id="user_type_field"
                                    name="user_type"
                                    className="form-control"
                                    onChange={e => setNewType(e.target.value)}
                                    type="select"
                                >
                                    <option selected>Select an option</option>
                                    <option value={1}>Admin</option> 
                                    <option value={2}>Marketing</option>
                                    <option value={3}>Customer</option>
                                    <option value={4}>Sales</option>
                                    <option value={5}>Finance</option>

                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md="3" sm="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="country_field"
                                >
                                    Country
                                </label>
                                <Input
                                    id="country_field"
                                    placeholder={userInfo.country}
                                    type="text"
                                    name="country"
                                    value={newCountry}
                                    onChange={e => setNewCountry(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="3" sm="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="state_field"
                                >
                                    State
                                </label>
                                <Input
                                    id="state_field"
                                    placeholder={userInfo.state}
                                    type="text"
                                    value={state}
                                    name="state"
                                    onChange={e => setState(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="3" sm="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="city_field"
                                >
                                    City
                                </label>
                                <Input
                                    id="city_field"
                                    placeholder={userInfo.city}
                                    type="text"
                                    value={newCity}
                                    name="city"
                                    onChange={e => setNewCity(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3" sm="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="address_field"
                                >
                                    Address
                                </label>
                                <Input
                                    id="address_field"
                                    placeholder={userInfo.address}
                                    type="text"
                                    value={newAddress}
                                    name="address"
                                    onChange={e => setNewAddress(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="3" sm="6">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="zip_code_field"
                                >
                                    Zip Code
                                </label>
                                <Input
                                    id="zip_code_field"
                                    placeholder={userInfo.zipCode}
                                    type="text"
                                    value={newZipCode}
                                    name="zip_code"
                                    onChange={e => setNewZipCode(e.target.value)}
                                    minLength={5}
                                    maxLength={5}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="3" sm="6">

                        </Col>
                        <Col md="3" sm="6">

                        </Col>
                    </Row>
                </div>
                <div className="modal-footer">
                    <Button onClick={saveChanges} color="primary" type="button">
                        Save changes
                    </Button>
                    <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setModal(false)}
                    >
                        Close
                    </Button>
                </div>
            </Modal>
            <SimpleHeader name="Users" parentName="Forms" />
            <Container className="mt--6" fluid>
                <Card className="mb-4">
                    <CardHeader>
                        <h3 className="mb-0">Create new user</h3>
                    </CardHeader>
                    <CardBody>
                        <form onSubmit={createUser}>
                            <Row>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="name_field"
                                        >
                                            Name
                                        </label>
                                        <Input
                                            id="name_field"
                                            placeholder="Name"
                                            type="text"
                                            name="name"
                                            value={name}
                                            required
                                            onChange={e => setName(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="email_field"
                                        >
                                            Email
                                        </label>
                                        <Input
                                            id="email_field"
                                            placeholder="Email"
                                            type="email"
                                            name="email"
                                            required
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="password_field"
                                        >
                                            Password
                                        </label>
                                        <Input
                                            id="password_field"
                                            placeholder="Password"
                                            type="password"
                                            name="password"
                                            required
                                            value={password}
                                            onChange={e => setPassword(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="user_type_field"
                                        >
                                            User type
                                        </label>
                                        <Input id="user_type_field"
                                            name="user_type"
                                            className="form-control"
                                            onChange={e => setType(e.target.value)}
                                            type="select"
                                        >
                                            <option selected>Select an option</option>
                                            <option value={1}>Admin</option> 
                                            <option value={2}>Marketing</option>
                                            <option value={3}>Customer</option>
                                            <option value={4}>Sales</option>
                                            <option value={5}>Finance</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: 20 }}>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="job_position_field"
                                        >
                                            Job Position
                                        </label>
                                        <Input
                                            id="job_position_field"
                                            placeholder="Job Position"
                                            type="text"
                                            name="job_position"
                                            required
                                            value={job_position}
                                            onChange={e => setJobPosition(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="birth_date_field"
                                        >
                                            Birth Date
                                        </label>
                                        <Col md="10">
                                            <Input
                                                id="birth_date_field"
                                                type="date"
                                                value={birth_date}
                                                name="birth date"
                                                onChange={e => setBirthDate(e.target.value)}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="country_select"
                                        >
                                            Country
                                        </label>
                                        <Input required id="country_select" className="form-control" type="select" onChange={e => setCountry(e.target.value)}>
                                        <option selected>Select an option</option>
                                            <option value="US">United States</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="state_select"
                                        >
                                            State
                                        </label>
                                        <Select2
                                            id="state_select"
                                            className="form-control"
                                            defaultValue="AL"
                                            required
                                            options={{
                                                placeholder: "Select a state",
                                            }}
                                            value={state}
                                            onChange={e => setState(e.target.value)}
                                            data={[
                                                { id: "AL", text: "Alabama" },
                                                { id: "AK", text: "Alaska" },
                                                { id: "AZ", text: "Arizona" },
                                                { id: "AR", text: "Arkansas" },
                                                { id: "CA", text: "California" },
                                                { id: "CO", text: "Colorado" },
                                                { id: "CT", text: "Connecticut" },
                                                { id: "DE", text: "Delaware" },
                                                { id: "DC", text: "District of Columbia" },
                                                { id: "FL", text: "Florida" },
                                                { id: "GA", text: "Georgia" },
                                                { id: "HI", text: "Hawaii" },
                                                { id: "ID", text: "Idaho" },
                                                { id: "IL", text: "Illinois" },
                                                { id: "IN", text: "Indiana" },
                                                { id: "IA", text: "Iowa" },
                                                { id: "KS", text: "Kansas" },
                                                { id: "KY", text: "Kentucky" },
                                                { id: "LA", text: "Louisiana" },
                                                { id: "ME", text: "Maine" },
                                                { id: "MD", text: "Maryland" },
                                                { id: "MA", text: "Massachusetts" },
                                                { id: "MI", text: "Michigan" },
                                                { id: "MN", text: "Minnesota" },
                                                { id: "MS", text: "Mississippi" },
                                                { id: "MO", text: "Missouri" },
                                                { id: "MT", text: "Montana" },
                                                { id: "NE", text: "Nebraska" },
                                                { id: "NV", text: "Nevada" },
                                                { id: "NH", text: "New Hampshire" },
                                                { id: "NJ", text: "New Jersey" },
                                                { id: "NM", text: "New Mexico" },
                                                { id: "NY", text: "New York" },
                                                { id: "NC", text: "North Carolina" },
                                                { id: "ND", text: "North Dakota" },
                                                { id: "OH", text: "Ohio" },
                                                { id: "OK", text: "Oklahoma" },
                                                { id: "PA", text: "Pennsylvania" },
                                                { id: "RI", text: "Rhode Island" },
                                                { id: "SC", text: "South Carolina" },
                                                { id: "SD", text: "South Dakota" },
                                                { id: "TN", text: "Tennessee" },
                                                { id: "TX", text: "Texas" },
                                                { id: "UT", text: "Utah" },
                                                { id: "VT", text: "Vermont" },
                                                { id: "VA", text: "Virginia" },
                                                { id: "WA", text: "Washington" },
                                                { id: "WV", text: "West Virginia" },
                                                { id: "WI", text: "Wisconsin" },
                                                { id: "WY", text: "Wyoming" }
                                            ]}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: 20 }}>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="city_field"
                                        >
                                            City
                                        </label>
                                        <Input
                                            id="city_field"
                                            placeholder="City"
                                            type="text"
                                            required
                                            value={city}
                                            name="city"
                                            onChange={e => setCity(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="address_field"
                                        >
                                            Address
                                        </label>
                                        <Input
                                            id="address_field"
                                            placeholder="Address"
                                            type="text"
                                            value={address}
                                            name="address"
                                            required
                                            onChange={e => setAddress(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="zip_code_field"
                                        >
                                            Zip Code
                                        </label>
                                        <Input
                                            id="zip_code_field"
                                            placeholder="Zip Code"
                                            type="text"
                                            value={zip_code}
                                            name="zip code"
                                            required
                                            maxLength="5"
                                            onChange={e => setZipCode(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3" sm="6">

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button disabled={loading} color="primary" type="submit">
                                        {loading ? <ReactLoading type="spinningBubbles" color="#fff" height="20px" width="20px" /> : 'Create user'}
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                </Card>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="border-0">
                                <h3 className="mb-0">Users</h3>
                            </CardHeader>

                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th className="sort" data-sort="name" scope="col">
                                            Name
                                        </th>
                                        <th className="sort" data-sort="budget" scope="col">
                                            Email
                                        </th>
                                        <th className="sort" data-sort="status" scope="col">
                                            Type
                                        </th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Job Position</th>
                                        <th scope="col">Birth Date</th>
                                        <th scope="col">Full Address</th>
                                        {/* <th scope="col">City</th>
                                        <th scope="col">State</th>
                                        <th scope="col">Zip Code</th>
                                        <th scope="col">Country</th> */}
                                        <th scope="col">Customer ID</th>
                                        <th scope="col">Email confirmed</th>
                                        <th scope="col">Created at</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody className="list">
                                    {list ? list.users.map((item) => (
                                        <tr>
                                            <td scope="row">
                                                {item.name}
                                            </td>
                                            <td>{item.email}</td>
                                            <td>
                                                {item.type === 1 ? 'Admin' : null}
                                                {item.type === 2 ? 'Moderator' : null}
                                                {item.type === 3 ? 'Customer' : null}
                                                {item.type === 4 ? 'Redator' : null}
                                                {item.type === 5 ? 'Saller' : null}
                                            </td>
                                            <td>
                                                {item.status ? 'Active' : 'Inactive'}
                                            </td>
                                            <td>
                                                {item.job_position}
                                            </td>
                                            <td>
                                                {item.birth_date ? formatDate(item.birth_date) : 'N/A'}
                                            </td>
                                            <td>
                                                {item.address} - {item.city} - {item.state} - {item.zip_code} - {item.country}
                                            </td>
                                            {/* <td>
                                                {item.city}
                                            </td>
                                            <td>
                                                {item.state}
                                            </td>
                                            <td>
                                                {item.zip_code}
                                            </td>
                                            <td>
                                                {item.country}
                                            </td> */}
                                            <td>
                                                {item.customer_id ? item.customer_id : 'N/A'}
                                            </td>
                                            <td>
                                                {item.is_confirmed ? 'Yes' : 'No'}
                                            </td>
                                            <td>
                                                {formatDate(item.created_at)}
                                            </td>
                                            <td className="text-right">
                                                <UncontrolledDropdown>
                                                    <DropdownToggle
                                                        className="btn-icon-only text-light"
                                                        color=""
                                                        role="button"
                                                        size="sm"
                                                    >
                                                        <i className="fas fa-ellipsis-v" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-arrow" right>
                                                        <DropdownItem
                                                            href="#pablo"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setNewType(item.type);
                                                                setUserId(item.id);
                                                                setModal(true);
                                                            }}
                                                        >
                                                            Edit
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            href="#pablo"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                deleteUser(item.id);
                                                            }}
                                                        >
                                                            Delete
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </td>
                                        </tr>
                                    )) : ''}
                                </tbody>
                            </Table>

                            <CardFooter className="py-4">
                                <nav aria-label="...">

                                    <Pagination
                                        className="pagination justify-content-end mb-0"
                                        listClassName="justify-content-end mb-0"
                                    >
                                        <PaginationItem>
                                            <PaginationLink
                                                onClick={(e) => {
                                                    if (actualPage > 1) {
                                                        setActualPage(actualPage - 1);
                                                    }
                                                }}
                                                tabIndex="-1"
                                            >
                                                <i className="fas fa-angle-left" />
                                                <span className="sr-only">Previous</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                        {
                                            Array(list ? Math.round(list.allUsers.length / 5) === 0 ? 1 : Number((list.allUsers.length / 5).toFixed(1).split('.')[1]) > 5 ? Math.round(list.allUsers.length / 5) : Math.round(list.allUsers.length / 5) + 1 : 1).fill('').map((_, index) => {
                                                return (
                                                    <PaginationItem className={actualPage === (index + 1) ? 'active' : ''} key={index}>
                                                        <PaginationLink
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setActualPage(index + 1);
                                                            }}
                                                        >
                                                            {index + 1}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                )
                                            })
                                        }

                                        <PaginationItem>
                                            <PaginationLink
                                                onClick={(e) => {
                                                    if (actualPage < Math.round(list.allUsers.length / 5)) {
                                                        setActualPage(actualPage + 1);
                                                    }
                                                }}
                                            >
                                                <i className="fas fa-angle-right" />
                                                <span className="sr-only">Next</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                    </Pagination>

                                </nav>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default Elements;
