import React from "react";
import classnames from "classnames";
import {
    Button,
    Card,
    // CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
} from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";
import AuthFooter from "components/Footers/AuthFooter.js";
// import { useLocation } from 'react-router-dom';
import api from '../../../util/api';
import swal from 'sweetalert';

const savedEmail = localStorage.getItem('email');

function Login() {
    const mainContentRef = React.useRef(null);
    const [focusedEmail, setfocusedEmail] = React.useState(false);
    const [focusedPassword, setfocusedPassword] = React.useState(false);

    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [remember, setRemember] = React.useState(false);

    React.useEffect(() => {
        if (savedEmail) {
            setEmail(savedEmail);
        }
    }, []);
    React.useEffect(() => {
        document.body.classList.add("bg-default");
        // Specify how to clean up after this effect:
        return function cleanup() {
            document.body.classList.remove("bg-default");
        };
    });

    const login = async e => {
        e.preventDefault();
        if (remember) {
            localStorage.setItem('email', email);
        } else {
            if (localStorage.getItem('email')) {
                localStorage.removeItem('email');
            }
        }
        if (email === '') {
            await swal('Atention!', 'Please insert an email', 'warning');
            return;
        }
        if (password === '') {
            await swal('Atention!', 'Please insert a password', 'warning');
            return;
        }
        api.post('/user/login', { email, password }).then(async res => {
            if (res.data.success && res.data.type !== 3) {
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('type', String(res.data.type));
                window.location.href = '/';
            } else {
                if (res.data.success) {
                    if (res.data.type === 3) {
                        await swal('Not authorized', "Your account isn't authorized to performe this action", 'error');
                    }
                } else {
                    await swal('Atention!', res.data.message, 'warning');
                    setPassword('');
                }
            }
        }).catch(async e => {
            await swal('Error!', 'An error ocurred', 'error');
            console.log(e);
        });
    }

    return (
        <>
            <div className="main-content" ref={mainContentRef}>
                <AuthHeader
                    img
                    title="Welcome!"
                    lead="MSWebmaker Administration Dashboard. Login using your company email."
                />
                <Container className="mt--8 pb-5">
                    <Row className="justify-content-center">
                        <Col lg="5" md="7">
                            <Card className="bg-secondary border-0 mb-0">
                                {/* <CardHeader className="bg-transparent pb-5">
                                    <div className="text-muted text-center mt-2 mb-3">
                                        <small>Sign in with your credentials</small>
                                    </div>
                                    <div className="btn-wrapper text-center">
                                    
                                    </div>
                                </CardHeader> */}
                                <CardBody className="px-lg-5 py-lg-5">
                                <div className="text-muted text-center mt-2 mb-3">
                                        <small>Sign in with your credentials</small>
                                    </div>
                                    <Form onSubmit={login} role="form">
                                        <FormGroup
                                            className={classnames("mb-3", {
                                                focused: focusedEmail,
                                            })}
                                        >
                                            <InputGroup className="input-group-merge input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-email-83" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="Email"
                                                    type="email"
                                                    onFocus={() => setfocusedEmail(true)}
                                                    onBlur={() => setfocusedEmail(true)}
                                                    value={email}
                                                    onChange={e => setEmail(e.target.value)}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                        <FormGroup
                                            className={classnames({
                                                focused: focusedPassword,
                                            })}
                                        >
                                            <InputGroup className="input-group-merge input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-lock-circle-open" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="Password"
                                                    type="password"
                                                    onFocus={() => setfocusedPassword(true)}
                                                    onBlur={() => setfocusedPassword(true)}
                                                    value={password}
                                                    onChange={e => setPassword(e.target.value)}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                        <div className="custom-control custom-control-alternative custom-checkbox">
                                            <input
                                                className="custom-control-input"
                                                id=" customCheckLogin"
                                                type="checkbox"
                                                value={remember}
                                                onChange={() => setRemember(!remember)}
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor=" customCheckLogin"
                                            >
                                                <span className="text-muted">Remember my email</span>
                                            </label>
                                        </div>
                                        <div className="text-center">
                                            <Button className="my-4" color="info" type="submit">
                                                Sign in
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                            <Row className="mt-3">
                                <Col xs="6">
                                    <a
                                        className="text-light"
                                        href="/forgot-password"
                                        onClick={(e) => window.location.href = '/auth/forgot-password'}
                                    >
                                        <small>Forgot password?</small>
                                    </a>
                                </Col>
                                <Col className="text-right" xs="6">
                                    <a
                                        className="text-light"
                                        href="/create-profile"
                                        onClick={(e) => window.location.href = '/auth/create-profile'}
                                    >
                                        <small>Create new account</small>
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            <AuthFooter />
        </>
    );
}

export default Login;
