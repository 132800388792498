import React from "react";
import classnames from "classnames";
import {
    Button,
    Card,
    // CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
    Modal
} from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";
import AuthFooter from "components/Footers/AuthFooter.js";
// import { useLocation } from 'react-router-dom';
import api from '../../../util/api';
import swal from 'sweetalert';
import ReactLoading from 'react-loading';


function Login() {
    const mainContentRef = React.useRef(null);
    const [focusedEmail, setfocusedEmail] = React.useState(false);
    // const [focusedPassword, setfocusedPassword] = React.useState(false);

    const savedEmail = localStorage.getItem('email');

    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [code, setCode] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');

    const [loading, setLoading] = React.useState(false);

    const [modal, setModal] = React.useState(false);

    const recover = async (e) => {
        e.preventDefault();
        if (password !== password) {
            await swal('Error!', 'Passwords do not match!', 'error');
            return;
        }
        api.post(`/user/forgot-verify`, { email, password, codigo: code }).then(async res => {
            if (res.data.success) {
                await swal('Success!', 'Your password has been changed successfully!', 'success');
                window.location.href = '/';
            } else {
                await swal('Error!', res.data.message, 'error');
            }
        }).catch(async e => await swal('Error', 'An error ocurred', 'error'));
    }

    React.useEffect(() => {
        if (savedEmail) {
            setEmail(savedEmail);
        }
    }, [savedEmail]);
    React.useEffect(() => {
        document.body.classList.add("bg-default");
        // Specify how to clean up after this effect:
        return function cleanup() {
            document.body.classList.remove("bg-default");
        };
    });

    const forgot = async e => {
        e.preventDefault();
        setLoading(true)

        api.post('/user/forgot', { email }).then(async res => {
            if (res.data.success) {
                // open modal
                await swal('Success!', 'You will receive an email with your code to reset your password', 'success');
                setModal(true);
                setLoading(false);
            } else {
                await swal('Error!', res.data.message, 'error');
                setLoading(false);
            }
        }).catch(async e => {
            await swal('Error!', 'An error ocurred', 'error');
            console.log(e);
            setLoading(false);
        });
    }

    return (
        <>

            <Modal
                style={{ minWidth: '80%' }}
                className="modal-dialog-centered"
                isOpen={modal}
                toggle={() => setModal(false)}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                        Password Recover
                    </h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <form onSubmit={recover}>
                    <div className="modal-body">
                        <Row>
                            <Col md="3" sm="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="example4cols1Input"
                                    >
                                        Reset Code
                                    </label>
                                    <Input
                                        id="example4cols1Input"
                                        placeholder="Reset Code"
                                        type="text"
                                        name="reset_code"
                                        value={code}
                                        onChange={e => setCode(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="3" sm="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="example4cols3Input"
                                    >
                                        New Password
                                    </label>
                                    <Col md="10">
                                        <Input
                                            id="example-date-input"
                                            type="password"
                                            name="new_password"
                                            placeholder="New Password"
                                            value={password}
                                            onChange={e => setPassword(e.target.value)}
                                        />
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col md="3" sm="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="example4cols3Input"
                                    >
                                        Confirm Password
                                    </label>
                                    <Input
                                        id="example4cols3Input"
                                        placeholder="Confirm Password"
                                        type="password"
                                        name="password_confirm"
                                        value={confirmPassword}
                                        onChange={e => setConfirmPassword(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="3" sm="6">
                            </Col>
                        </Row>
                    </div>
                    <div className="modal-footer">
                        <Button color="primary" type="submit">
                            Recover
                        </Button>
                        <Button
                            className="ml-auto"
                            color="link"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setModal(false)}
                        >
                            Close
                        </Button>
                    </div>
                </form>
            </Modal>

            <div className="main-content" ref={mainContentRef}>
                <AuthHeader
                    img
                    title="Forgot your password?"
                    lead="Don't worry! Just fill in your email and we'll send you a link to reset your passwrod."
                />
                <Container className="mt--8 pb-5">
                    <Row className="justify-content-center">
                        <Col lg="5" md="7">
                            <Card className="bg-secondary border-0 mb-0">
                                <CardBody className="px-lg-5 py-lg-5">
                                    <div className="text-muted text-center mt-2 mb-3">
                                        <small>Retrieve your credentials</small>
                                    </div>
                                    <Form onSubmit={forgot} role="form">
                                        <FormGroup
                                            className={classnames("mb-3", {
                                                focused: focusedEmail,
                                            })}
                                        >
                                            <InputGroup className="input-group-merge input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-email-83" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    placeholder="Email"
                                                    type="email"
                                                    onFocus={() => setfocusedEmail(true)}
                                                    onBlur={() => setfocusedEmail(true)}
                                                    value={email}
                                                    onChange={e => setEmail(e.target.value)}
                                                />
                                            </InputGroup>
                                        </FormGroup>
                                        <div className="text-center">
                                            <Button disabled={loading} className="my-4" color="info" type="submit">
                                                {loading ? <ReactLoading type="spinningBubbles" color="#fff" height="20px" width="20px" /> : 'Reset password'}
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                            <Row className="mt-3">
                                <Col xs="6">
                                    <a
                                        className="text-light"
                                        href="/auth/login"
                                        onClick={(e) => window.location.href = '/auth/login'}
                                    >
                                        <small>Try Login</small>
                                    </a>
                                </Col>
                                <Col className="text-right" xs="6">
                                    <a
                                        className="text-light"
                                        href="/create-profile"
                                        onClick={(e) => window.location.href = '/auth/create-profile'}
                                    >
                                        <small>Create new account</small>
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
            <AuthFooter />
        </>
    );
}

export default Login;
