import React from "react";

import {
    Card,
    CardBody,
    Container,
    Row,
    Col,
} from "reactstrap";

function Profile() {

    return (
        <>
            <Container className="mt--6" fluid>

                <Row>
                    <Col>
                        <Card>
                            <CardBody>

                                <h1>Coming Soon</h1>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </Container>
        </>
    );
}

export default Profile;
