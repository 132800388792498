import Alternative from "views/pages/dashboards/Alternative.js";
import Buttons from "views/pages/components/Buttons.js";
import Calendar from "views/pages/Calendar.js";
import Cards from "views/pages/components/Cards.js";
import Charts from "views/pages/Charts.js";
import Components from "views/pages/forms/Components.js";
import Dashboard from "views/pages/dashboards/Dashboard.js";
import Elements from "views/pages/forms/Elements.js";
import Google from "views/pages/maps/Google.js";
import Grid from "views/pages/components/Grid.js";
import Icons from "views/pages/components/Icons.js";
import Lock from "views/pages/examples/Lock.js";
import LoginCopy from "views/pages/examples/Login copy.js";
import Notifications from "views/pages/components/Notifications.js";
import Pricing from "views/pages/examples/Pricing.js";
import Profile from "views/pages/examples/Profile.js";
import ReactBSTables from "views/pages/tables/ReactBSTables.js";
import Register from "views/pages/examples/Register.js";
import RTLSupport from "views/pages/examples/RTLSupport.js";
import Sortable from "views/pages/tables/Sortable.js";
import Tables from "views/pages/tables/Tables.js";
import Timeline from "views/pages/examples/Timeline.js";
import Typography from "views/pages/components/Typography.js";
import Validation from "views/pages/forms/Validation.js";
import Vector from "views/pages/maps/Vector.js";
import Widgets from "views/pages/Widgets.js";
import Expenses from "views/pages/finance/Expenses";
import Companies from "views/pages/sales/Companies";
import Contracts from "views/pages/sales/Contracts";
import CostOfSold from "views/pages/finance/CostOfSold";
import User from "views/pages/admin/Users";
import Products from "views/pages/admin/Products";
import MyProfile from "views/pages/admin/Profile";
import Support from "views/pages/admin/Support";
import CustomerControl from "views/pages/admin/Profile"
import Leads from "views/pages/sales/Leads";
import Customers from "views/pages/admin/Customers";
import ComingSoon from "views/pages/admin/ComingSoon";
import CompanyControl from "views/pages/admin/CompanyControl"
import BlogPost from "views/pages/admin/BlogPost";
import Pandl from "views/pages/finance/PandL";
import Revenue from "views/pages/finance/Revenue";
import PandlAnalytics from "views/pages/finance/PandLAnalytics";
import MailChimp from "views/pages/admin/MailChimp";
import AnalyticsControl from "views/pages/sales/AnalyticsControl";
import Forecast from "views/pages/sales/Forecast";

const uType = Number(localStorage.getItem('type') || 0);

const routes = uType === 1 ? [
  {
    // collapse: true,
    path: "/dashboard",
    name: "Company Overview",
    miniName: "D",
    icon: "ni ni-building text-primary",
    component: Dashboard,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Finance Dashboard",
    icon: "ni ni-money-coins text-gray-dark",
    state: "examplesCollapse",
    views: [
      {
        path: "/expenses",
        name: "Expenses",
        miniName: "E",
        component: Expenses,
        layout: "/admin",
      },
      
      {
        path: "/revenue",
        name: "Revenue",
        miniName: "PL",
        component: Revenue,
        layout: "/admin",
      },
      {
        path: "/cost-of-goods-sold",
        name: "Cost of goods sold",
        miniName: "PL",
        component: CostOfSold,
        layout: "/admin",
      },
      {
        path: "/p&l",
        name: "P & L",
        miniName: "PL",
        component: Pandl,
        layout: "/admin",
      },
      {
        path: "/planalytics",
        name: "P & L Analytics",
        miniName: "PLA",
        component: PandlAnalytics,
        layout: "/admin",
      },
      
    ],
  },
  {
    collapse: true,
    name: "Sales Dashboard",
    icon: "ni ni-briefcase-24 text-success",
    state: "componentsCollapse",
    views: [
      {
        path: "/companies",
        name: "Companies",
        miniName: "C",
        component: Companies,
        layout: "/admin",
      },
      {
        path: "/leads",
        name: "Leads",
        miniName: "L",
        component: Leads,
        layout: "/admin",
      },
      {
        path: "/contracts",
        name: "Contracts",
        miniName: "C",
        component: Contracts,
        layout: "/admin",
      },
      {
        path: "/forecast",
        name: "Forecast",
        miniName: "F",
        component: Forecast,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Marketing Dashboard",
    icon: "ni ni-notification-70 text-orange",
    state: "formsCollapse",
    views: [
      {
        path: "/analytics-setup",
        name: "Analytics Setup",
        miniName: "AS",
        component: AnalyticsControl,
        layout: "/admin",
      },
      {
        path: "/mailchimp",
        name: "MailChimp",
        miniName: "MC",
        component: MailChimp,
        layout: "/admin",
      },
      {
        path: "/components",
        name: "Social Media Management",
        miniName: "SM",
        component: ComingSoon,
        layout: "/admin",
      },
      {
        path: "/blog-posts",
        name: "Blog Posts",
        miniName: "BP",
        component: BlogPost,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "CRM",
    icon: "ni ni-bulb-61 text-blue",
    state: "tablesCollapse",
    views: [
      {
        path: "/tables",
        name: "Lead Source",
        miniName: "LS",
        component: ComingSoon,
        layout: "/admin",
      },
      {
        path: "/sortable",
        name: "Pipeline Stages",
        miniName: "PS",
        component: ComingSoon,
        layout: "/admin",
      },
      {
        path: "/react-bs-table",
        name: "Email Status",
        miniName: "ES",
        component: ComingSoon,
        layout: "/admin",
      },
      {
        path: "/react-bs-table",
        name: "Contracts",
        miniName: "C",
        component: ComingSoon,
        layout: "/admin",
      },
      {
        path: "/react-bs-table",
        name: "Tasks",
        miniName: "T",
        component: ComingSoon,
        layout: "/admin",
      }
    ],
  },
  {
    collapse: true,
    name: "Administration",
    icon: "ni ni-lock-circle-open text-default",
    state: "administrationCollapse",
    views: [
      {
        path: "/products",
        name: "Products",
        miniName: "P",
        component: Products,
        layout: "/admin",
      },
      {
        path: "/user",
        name: "Users",
        miniName: "U",
        component: User,
        layout: "/admin",
      },
      {
        path: "/company-control",
        name: "Company Control",
        miniName: "CC",
        component: CompanyControl,
        layout: "/admin",
      }
    ],
  },

  {
    // collapse: true,
    path: "/my-profile",
    name: "",
    miniName: "",
    icon: "",
    component: MyProfile,
    layout: "/admin",
  },
  {
    path: "/support",
    name: "",
    miniName: "",
    icon: "",
    component: ComingSoon,
    layout: "/admin",
  },
  {
    // collapse: true,
    path: "/coming-soon",
    name: "",
    miniName: "",
    icon: "",
    component: ComingSoon,
    layout: "/admin",
  },
] : uType === 2 ? [
  {
    // collapse: true,
    path: "/dashboard",
    name: "Company Overview",
    miniName: "D",
    icon: "ni ni-building text-primary",
    component: Dashboard,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Marketing Dashboard",
    icon: "ni ni-notification-70 text-orange",
    state: "formsCollapse",
    views: [
      {
        path: "/analytics-setup",
        name: "Analytics Setup",
        miniName: "AS",
        component: AnalyticsControl,
        layout: "/admin",
      },
      {
        path: "/mailchimp",
        name: "MailChimp",
        miniName: "MC",
        component: MailChimp,
        layout: "/admin",
      },
      {
        path: "/components",
        name: "Social Media Management",
        miniName: "SM",
        component: ComingSoon,
        layout: "/admin",
      },
      {
        path: "/blog-posts",
        name: "Blog Posts",
        miniName: "BP",
        component: BlogPost,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "CRM",
    icon: "ni ni-bulb-61 text-blue",
    state: "tablesCollapse",
    views: [
      {
        path: "/tables",
        name: "Lead Source",
        miniName: "LS",
        component: ComingSoon,
        layout: "/admin",
      },
      {
        path: "/sortable",
        name: "Pipeline Stages",
        miniName: "PS",
        component: ComingSoon,
        layout: "/admin",
      },
      {
        path: "/react-bs-table",
        name: "Email Status",
        miniName: "ES",
        component: ComingSoon,
        layout: "/admin",
      },
      {
        path: "/react-bs-table",
        name: "Contracts",
        miniName: "C",
        component: ComingSoon,
        layout: "/admin",
      },
      {
        path: "/react-bs-table",
        name: "Tasks",
        miniName: "T",
        component: ComingSoon,
        layout: "/admin",
      }
    ],
  },

  {
    // collapse: true,
    path: "/my-profile",
    name: "",
    miniName: "",
    icon: "",
    component: MyProfile,
    layout: "/admin",
  },
  {
    path: "/support",
    name: "",
    miniName: "",
    icon: "",
    component: ComingSoon,
    layout: "/admin",
  },
  {
    // collapse: true,
    path: "/coming-soon",
    name: "",
    miniName: "",
    icon: "",
    component: ComingSoon,
    layout: "/admin",
  },
] : uType === 4 ? [
  {
    // collapse: true,
    path: "/dashboard",
    name: "Company Overview",
    miniName: "D",
    icon: "ni ni-building text-primary",
    component: Dashboard,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Sales Dashboard",
    icon: "ni ni-briefcase-24 text-success",
    state: "componentsCollapse",
    views: [
      {
        path: "/companies",
        name: "Companies",
        miniName: "C",
        component: Companies,
        layout: "/admin",
      },
      {
        path: "/leads",
        name: "Leads",
        miniName: "L",
        component: Leads,
        layout: "/admin",
      },
      {
        path: "/contracts",
        name: "Contracts",
        miniName: "C",
        component: Contracts,
        layout: "/admin",
      },
      {
        path: "/forecast",
        name: "Forecast",
        miniName: "F",
        component: Forecast,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "CRM",
    icon: "ni ni-bulb-61 text-blue",
    state: "tablesCollapse",
    views: [
      {
        path: "/tables",
        name: "Lead Source",
        miniName: "LS",
        component: ComingSoon,
        layout: "/admin",
      },
      {
        path: "/sortable",
        name: "Pipeline Stages",
        miniName: "PS",
        component: ComingSoon,
        layout: "/admin",
      },
      {
        path: "/react-bs-table",
        name: "Email Status",
        miniName: "ES",
        component: ComingSoon,
        layout: "/admin",
      },
      {
        path: "/react-bs-table",
        name: "Contracts",
        miniName: "C",
        component: ComingSoon,
        layout: "/admin",
      },
      {
        path: "/react-bs-table",
        name: "Tasks",
        miniName: "T",
        component: ComingSoon,
        layout: "/admin",
      }
    ],
  },
  {
    // collapse: true,
    path: "/my-profile",
    name: "",
    miniName: "",
    icon: "",
    component: MyProfile,
    layout: "/admin",
  },
  {
    path: "/support",
    name: "",
    miniName: "",
    icon: "",
    component: ComingSoon,
    layout: "/admin",
  },
  {
    // collapse: true,
    path: "/coming-soon",
    name: "",
    miniName: "",
    icon: "",
    component: ComingSoon,
    layout: "/admin",
  },
] : uType === 5 ? [
  {
    // collapse: true,
    path: "/dashboard",
    name: "Company Overview",
    miniName: "D",
    icon: "ni ni-building text-primary",
    component: Dashboard,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Finance Dashboard",
    icon: "ni ni-money-coins text-gray-dark",
    state: "examplesCollapse",
    views: [
      {
        path: "/expenses",
        name: "Expenses",
        miniName: "E",
        component: Expenses,
        layout: "/admin",
      },
      
      {
        path: "/revenue",
        name: "Revenue",
        miniName: "PL",
        component: Revenue,
        layout: "/admin",
      },
      {
        path: "/cost-of-goods-sold",
        name: "Cost of goods sold",
        miniName: "PL",
        component: CostOfSold,
        layout: "/admin",
      },
      {
        path: "/p&l",
        name: "P & L",
        miniName: "PL",
        component: Pandl,
        layout: "/admin",
      },
      {
        path: "/planalytics",
        name: "P & L Analytics",
        miniName: "PLA",
        component: PandlAnalytics,
        layout: "/admin",
      },
      
    ],
  },
  {
    collapse: true,
    name: "Sales Dashboard",
    icon: "ni ni-briefcase-24 text-success",
    state: "componentsCollapse",
    views: [
      {
        path: "/companies",
        name: "Companies",
        miniName: "C",
        component: Companies,
        layout: "/admin",
      },
      {
        path: "/leads",
        name: "Leads",
        miniName: "L",
        component: Leads,
        layout: "/admin",
      },
      {
        path: "/contracts",
        name: "Contracts",
        miniName: "C",
        component: Contracts,
        layout: "/admin",
      },
      {
        path: "/forecast",
        name: "Forecast",
        miniName: "F",
        component: Forecast,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Marketing Dashboard",
    icon: "ni ni-notification-70 text-orange",
    state: "formsCollapse",
    views: [
      {
        path: "/analytics-setup",
        name: "Analytics Setup",
        miniName: "AS",
        component: AnalyticsControl,
        layout: "/admin",
      },
      {
        path: "/mailchimp",
        name: "MailChimp",
        miniName: "MC",
        component: MailChimp,
        layout: "/admin",
      },
      {
        path: "/components",
        name: "Social Media Management",
        miniName: "SM",
        component: ComingSoon,
        layout: "/admin",
      },
      {
        path: "/blog-posts",
        name: "Blog Posts",
        miniName: "BP",
        component: BlogPost,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "CRM",
    icon: "ni ni-bulb-61 text-blue",
    state: "tablesCollapse",
    views: [
      {
        path: "/tables",
        name: "Lead Source",
        miniName: "LS",
        component: ComingSoon,
        layout: "/admin",
      },
      {
        path: "/sortable",
        name: "Pipeline Stages",
        miniName: "PS",
        component: ComingSoon,
        layout: "/admin",
      },
      {
        path: "/react-bs-table",
        name: "Email Status",
        miniName: "ES",
        component: ComingSoon,
        layout: "/admin",
      },
      {
        path: "/react-bs-table",
        name: "Contracts",
        miniName: "C",
        component: ComingSoon,
        layout: "/admin",
      },
      {
        path: "/react-bs-table",
        name: "Tasks",
        miniName: "T",
        component: ComingSoon,
        layout: "/admin",
      }
    ],
  },

  {
    // collapse: true,
    path: "/my-profile",
    name: "",
    miniName: "",
    icon: "",
    component: MyProfile,
    layout: "/admin",
  },
  {
    path: "/support",
    name: "",
    miniName: "",
    icon: "",
    component: ComingSoon,
    layout: "/admin",
  },
  {
    // collapse: true,
    path: "/coming-soon",
    name: "",
    miniName: "",
    icon: "",
    component: ComingSoon,
    layout: "/admin",
  },
] : [];

export default routes;
