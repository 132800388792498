export default function handleTypes(frequence, interval){
    if(frequence === 'month' && interval === 1){
        return 'Monthly';
    }
    if(frequence === 'week'){
        return 'Weekly';
    }
    if(frequence === 'year'){
        return 'Yearly';
    }
    if(frequence === 'day'){
        return 'Daily';
    }
    if(frequence === 'month' && interval === 3){
        return 'Every 3 months';
    }
    if(frequence === 'month' && interval === 6){
        return 'Every 6 months';
    }
}