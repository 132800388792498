import React, { useEffect, useState } from "react";

import {
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    Button,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown,
    Pagination,
    PaginationItem,
    PaginationLink,
    Modal,
    Collapse,
    Table
} from "reactstrap";
// core components
import InputMask from 'react-input-mask';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import swal from "sweetalert";
import api from '../../../util/api';
import validate from '../../../util/validate';
import Select2 from "react-select2-wrapper";
import useList from '../../../hooks/useList';
import usePagination from '../../../hooks/usePagination';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';  
import draftToHtml from "draftjs-to-html";

function Elements() {

    const token = localStorage.getItem('token');

    const { list, fetchList } = useList('/blog');
    const { actualPage, setActualPage } = usePagination();


    const [isOpen, setIsOpen] = useState(false);

    const [submit, setSubmit] = useState(true);
    const [title, setTitle] = useState('');
    const [slug, setSlug] = useState('');
    const [content, setContent] = useState('');
    const [category, setCategory] = useState(null);
    const [image, setImage] = useState(null);

    const [posts, setPosts] = useState([]);

    const [categories, setCategories] = useState([]);

    console.log(content);

    console.log(categories[1]);

    const deletePost = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this post!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    api.delete(`/blog/p/${id}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }).then(async res => {
                        if (res.data.success) {
                            await swal('Success!', 'Post deleted!', 'success');
                        } else {
                            await swal('Error', 'An error ocurred', 'error');
                        }
                        setSubmit(!submit);
                    }).catch(async e => {
                        await swal('Error', 'An error ocurred', 'error');
                        console.log(e);
                    });
                } else {
                    return;
                }
            });
    }

    const post = async (e) => {
        e.preventDefault();
        const rawContentState = convertToRaw(content.getCurrentContent());
        const markup = draftToHtml(rawContentState);
        console.log(markup);
        if(category === null){
            await swal('Atention!', 'Select a category', 'warning');
            return;
        }
        const data = new FormData();
        data.append('image', image);
        data.append('title', title);
        data.append('slug', slug);
        data.append('html', markup);
        data.append('category', category);
        api.post('/blog/create', data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(async res => {
            if(res.data.success){
                await swal('Success!', 'Post published!', 'success');
                setIsOpen(false);
                setTitle('');
                setSlug('');
                setContent('');
                setCategory(null);
                setImage(null);
                setSubmit(!submit);
            }else {
                await swal('Error!', res.data.message, 'warning');
            }
        }).catch(async e => await swal('Error!', 'An error ocurred!', 'error'));
    }

    useEffect(() => {
        api.get('/blog/category').then(res => {
            if(res.data.success){
                setCategories(res.data.categories);
            }
        })
        
    }, [submit]);
    useEffect(() => {
        fetchList(actualPage);
    }, [submit, actualPage])



    return (
        <>

            <SimpleHeader name="Blog Posts" parentName="MSWebmaker" />
            <Container className="mt--6" fluid>
                <Row>
                    <Col>
                        <Card className="mb-4">
                            <CardHeader>
                                <h3 className="mb-0">Create a new Post</h3>
                                <Button style={{ marginTop: 20 }} onClick={() => setIsOpen(!isOpen)}>{isOpen ? 'Close' : 'Open'}</Button>
                            </CardHeader>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Collapse isOpen={isOpen}>
                            <Card className="mb-4">
                                <CardHeader>
                                    <h3 className="mb-0">Blog Input</h3>
                                </CardHeader>

                                <CardBody>
                                    <form onSubmit={post}>

                                        <Row>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="name_company_field"
                                                    >
                                                        Title
                                                    </label>
                                                    <Input
                                                        id="name_company_field"
                                                        placeholder="Title"
                                                        type="text"
                                                        name="title"
                                                        value={title}
                                                        required
                                                        onChange={e => setTitle(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="name_company_field"
                                                    >
                                                        Slug
                                                    </label>
                                                    <Input
                                                        id="name_company_field"
                                                        placeholder="Slug"
                                                        type="text"
                                                        name="company_name"
                                                        value={slug}
                                                        required
                                                        onChange={e => setSlug(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="industry_select"
                                                    >
                                                        Category
                                                    </label>
                                                    <Select2
                                                        id="industry_select"
                                                        className="form-control"
                                                        options={{
                                                            placeholder: "Select a category",
                                                        }}
                                                        value={category}
                                                        onChange={e => setCategory(e.target.value)}
                                                        required
                                                        data={categories}
                                                    />

                                                </FormGroup>

                                            </Col>
                                            <Col md="3" sm="6">
                                            <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="name_company_field"
                                                    >
                                                        Upload an image
                                                    </label>
                                                    <Input
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={e => setImage(e.target.files[0])}
                                                    />
                                                </FormGroup>
                                            </Col>

                                        </Row>


                                        <Row>
                                            <Col>
                                            <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="phone_field"
                                                    >
                                                        Content
                                                    </label>
                                                    <Editor 
                                                    editorStyle={{
                                                        minHeight: 300
                                                    }}
                                                    editorState={content}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="form-control"
                                                    onEditorStateChange={value => setContent(value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        
                                        <Row>
                                            <Col>
                                                <Button color="primary" type="submit">
                                                    Insert
                                                </Button>
                                            </Col>
                                        </Row>
                                    </form>
                                </CardBody>

                            </Card>
                        </Collapse>
                    </Col>
                </Row>

                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="border-0">
                                <h3 className="mb-0">Companies</h3>
                            </CardHeader>

                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th className="sort" data-sort="name" scope="col">
                                           Title
                                        </th>
                                        <th className="sort" data-sort="name" scope="col">
                                            Slug
                                        </th>
                                        <th className="sort" data-sort="budget" scope="col">
                                            Category
                                        </th>
                                        <th scope="col" />
                                    </tr>
                                </thead>
                                <tbody className="list">
                                    {list && list.posts.length > 0 ? list.posts.map((item) => (
                                        <tr>
                                            <th scope="row">
                                                <p>{item.title}</p>

                                            </th>
                                            <th scope="row">
                                                <p>{item.slug}</p>

                                            </th>
                                            {/* <td className="budget">{formatDate(item.date)}</td> */}
                                            <td>
                                                <p>{categories[item.id - 1].text}</p>
                                            </td>
                                            
                                            <td className="text-right">
                                                <UncontrolledDropdown>
                                                    <DropdownToggle
                                                        className="btn-icon-only text-light"
                                                        color=""
                                                        role="button"
                                                        size="sm"
                                                    >
                                                        <i className="fas fa-ellipsis-v" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-arrow" right>
                                                        <DropdownItem
                                                            onClick={(e) => {
                                                                
                                                            }}
                                                        >
                                                            Edit post
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            href="#pablo"
                                                            onClick={() => deletePost(item.id)}
                                                        >
                                                            Delete post
                                                        </DropdownItem>
                                                        {/* <DropdownItem
                                                            href="#pablo"
                                                            onClick={(e) => e.preventDefault()}
                                                        >
                                                            Something else here
                                                        </DropdownItem> */}
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </td>
                                        </tr>
                                    )) : ''}
                                </tbody>
                            </Table>

                            <CardFooter className="py-4">
                                <nav aria-label="...">

                                    <Pagination
                                        className="pagination justify-content-end mb-0"
                                        listClassName="justify-content-end mb-0"
                                    >
                                        <PaginationItem>
                                            <PaginationLink
                                                onClick={(e) => {
                                                    if (actualPage > 1) {
                                                        setActualPage(actualPage - 1);
                                                    }
                                                }}
                                                tabIndex="-1"
                                            >
                                                <i className="fas fa-angle-left" />
                                                <span className="sr-only">Previous</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                        {
                                            Array(list ? Math.round(list.allPosts.length / 5) === 0 ? 1 : Math.round(list.allPosts.length / 5) + 1 : 1).fill('').map((_, index) => {
                                                return (
                                                    <PaginationItem className={actualPage === (index + 1) ? 'active' : ''} key={index}>
                                                        <PaginationLink
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setActualPage(index + 1);
                                                            }}
                                                        >
                                                            {index + 1}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                )
                                            })
                                        }

                                        <PaginationItem>
                                            <PaginationLink
                                                onClick={(e) => {
                                                    if (actualPage < Math.round(list.allPosts.length / 5)) {
                                                        setActualPage(actualPage + 1);
                                                    }
                                                }}
                                            >
                                                <i className="fas fa-angle-right" />
                                                <span className="sr-only">Next</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                    </Pagination>

                                </nav>
                            </CardFooter>
                        
                        </Card>
                    </div>
                </Row>
            
            </Container>
        </>
    );
}

export default Elements;
