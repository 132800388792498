import React, { useEffect, useState } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    Button,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    UncontrolledTooltip,
    Modal,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import swal from "sweetalert";
import api from '../../../util/api';
import handleTypes from '../../../util/handleTypes';
import useList from '../../../hooks/useList';
import usePagination from '../../../hooks/usePagination';
import ReactLoading from 'react-loading';

const categories = [
    'Social Media',
    'Paid Social',
    'PPC',
    'Email marketing',
    'Brand identity',
    'Content',
    'SEO',
    'Web Development',
    'Web Dev Using Wix',
    'Tech Solutions',
    'Email Solutions',
    'Web Design',
    'Web Maintenance',
    'Server Features'
];

function Elements() {

    const token = localStorage.getItem('token');

    const { list, fetchList } = useList('/products');
    const { actualPage, setActualPage } = usePagination();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('Marketing');

    const [loading, setLoading] = useState(false);

    const [refresh, setRefresh] = useState(true);

    const [allPrices, setAllPrices] = useState([]);

    const [priceList, setPriceList] = useState([{ prices: '', recurrence: 0, frequence: false, priceName: '' }]);
    const [otherPriceList, setOtherPriceList] = useState([{ prices: '', recurrence: 0, frequence: false, priceName: '' }]);

    const [priceModal, setPriceModal] = useState(false);
    const [productPriceList, setProductPriceList] = useState([]);

    const [modal, setModal] = useState(false);

    const [newName, setNewName] = useState('');
    const [newDescription, setNewDescription] = useState('');

    const [productId, setProductId] = useState('');

    const [editLoading, setEditLoading] = useState(false);

    const [productFromId, setProductFromId] = useState({});

    const editProducts = () => {
        setEditLoading(true);
        api.put(`/products/${productId}`, {
            name: newName,
            description: newDescription,
            pricesArray: otherPriceList
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(async res => {
            if (res.data.success) {
                await swal('Success!', 'Product edited succesfully!', 'success');
                setModal(false);
                setEditLoading(false);
                setRefresh(!refresh);
                setOtherPriceList([{ prices: '', recurrence: 0, frequence: false, priceName: '' }]);
            } else {
                await swal('Error!', res.data.message, 'error');
                setEditLoading(false);
            }
        }).catch(async e => {
            console.log(e);
            await swal('Error!', 'An Error ocurred!', 'error');
            setEditLoading(false);
        });
    }

    const showPrices = (id) => {
        api.get(`/products/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            if (res.data.success) {
                setProductPriceList(res.data.prices);
            }
        })
        setPriceModal(true);
    }

    useEffect(() => {
        fetchList(actualPage);
    }, [refresh, actualPage]);
    useEffect(() => {
        if (productId !== '') {
            api.get(`/products/${productId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then(res => {
                if (res.data.success) {
                    setProductFromId(res.data.product);
                }
            })
        }
    }, [productId])

    const deleteUser = async (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this product",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    api.delete(`/products/${id}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }).then(async res => {
                        if (res.data.success) {
                            await swal('Success!', 'Product deleted!', 'success');
                        } else {
                            await swal('Error', res.data.message, 'error');
                        }
                        setRefresh(!refresh);
                    }).catch(async e => {
                        console.log(e);
                        await swal('Error', 'An error ocurred', 'error');

                    });
                } else {
                    return;
                }
            });
    }

    const createProducts = async (e) => {
        setLoading(true);
        e.preventDefault();

        api.post('/products/create', { name, description, pricesArray: priceList, type: 'none', category, is_visible: true }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(async res => {
            if (res.data.success) {
                await swal('Success!', 'Product registered succesfully', 'success');
                setRefresh(!refresh);
                setLoading(false);
                setName('');
                setDescription('');
                setPriceList([{ prices: '', recurrence: 0, frequence: false, priceName: '' }]);
            } else {
                await swal('Error!', res.data.message, 'error');
                setLoading(false);
            }
        }).catch(e => setLoading(false));

    }

    // handle input change
    const handleInputChange = (value, index) => {
        const list = [...priceList];
        list[index]['prices'] = value;
        setPriceList(list);
    };

    const handlePriceNameChange = (value, index) => {
        const list = [...priceList];
        list[index]['priceName'] = value;
        setPriceList(list);
    }

    const handleButton = (index, value) => {
        const list = [...priceList];
        list[index]['recurrence'] = value;
        setPriceList(list);
    }

    const handleSelection = (index, value) => {
        const list = [...priceList];
        list[index]['frequence'] = value;
        setPriceList(list);
    }


    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...priceList];
        list.splice(index, 1);
        setPriceList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setPriceList([...priceList, { prices: '', recurrence: 0, frequence: false, priceName: '' }]);
    };
    // --- //
    // handle input change
    const handleInputChangeEdit = (value, index) => {
        const list = [...otherPriceList];
        list[index]['prices'] = value;
        setOtherPriceList(list);
    };

    const handlePriceNameChangeEdit = (value, index) => {
        const list = [...otherPriceList];
        list[index]['priceName'] = value;
        setOtherPriceList(list);
    }

    const handleButtonEdit = (index, value) => {
        const list = [...otherPriceList];
        list[index]['recurrence'] = value;
        setOtherPriceList(list);
    }

    const handleSelectionEdit = (index, value) => {
        const list = [...otherPriceList];
        list[index]['frequence'] = value;
        setOtherPriceList(list);
    }


    // handle click event of the Remove button
    const handleRemoveClickEdit = index => {
        const list = [...otherPriceList];
        list.splice(index, 1);
        setOtherPriceList(list);
    };

    // handle click event of the Add button
    const handleAddClickEdit = () => {
        setOtherPriceList([...otherPriceList, { prices: '', recurrence: 0, frequence: false, priceName: '' }]);
    };

    return (
        <>
            <Modal
                style={{ minWidth: '80%' }}
                className="modal-dialog-centered"
                isOpen={modal}
                toggle={() => setModal(false)}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                        Edit product
                    </h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <h3 className="mb-0">Product information</h3>
                    <Row>
                        <Col md="4" sm="4">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="name_field"
                                >
                                    Name
                                </label>
                                <Input
                                    id="name_field"
                                    placeholder={productFromId.name}
                                    type="text"
                                    name="product_name"
                                    value={newName}
                                    onChange={e => setNewName(e.target.value)}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md="4" sm="4">
                            <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="description_field"
                                >
                                    Description
                                </label>
                                <Input
                                    id="description_field"
                                    placeholder={productFromId.description}
                                    type="text"
                                    name="product_description"
                                    required
                                    value={newDescription}
                                    onChange={e => setNewDescription(e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="4" sm="4">

                        </Col>
                    </Row>
                    {otherPriceList.map((x, i) => {
                        return (
                            <>
                                <Row key={i} className="align-items-center" style={{ marginBottom: 20 }}>
                                    <Col md="3" sm="6">
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="price_field"
                                                    >
                                                        Price Name
                                                    </label>
                                                    <Input
                                                        id="price_field"
                                                        placeholder="Price Name"
                                                        type="text"
                                                        name="prices"
                                                        value={x.priceName}
                                                        onChange={e => handlePriceNameChangeEdit(e.target.value, i)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="price_field"
                                                    >
                                                        Price
                                                    </label>
                                                    <Input
                                                        id="price_field"
                                                        placeholder="Price"
                                                        type="text"
                                                        name="prices"
                                                        value={x.prices}
                                                        onChange={e => handleInputChangeEdit(e.target.value, i)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md="3" sm="6">
                                        <Row>
                                            <Button style={{ border: x.recurrence === 0 ? '2px solid #000' : '' }} onClick={() => handleButtonEdit(i, 0)}>One time</Button>
                                            <Button style={{ border: x.recurrence === 1 ? '2px solid #000' : '' }} onClick={() => handleButtonEdit(i, 1)}>Recurring</Button>
                                        </Row>
                                    </Col>
                                    <Col md="3" sm="6">
                                        {x.recurrence === 1 ? <>
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="cycle_interval"
                                                >
                                                    Cycle Interval
                                                </label>
                                                <Input id="cycle_interval"
                                                    name="cycle_interval"
                                                    className="form-control"
                                                    onChange={e => handleSelectionEdit(i, e.target.value)}
                                                    type="select"
                                                >
                                                    <option value="day">Daily</option>
                                                    <option value="week">Weekly</option>
                                                    <option value="month">Monthly</option>
                                                    <option value="3month">Every 3 Months</option>
                                                    <option value="6month">Every 6 Months</option>
                                                    <option value="year">Yearly</option>
                                                </Input>
                                            </FormGroup>
                                        </> : ''}
                                    </Col>
                                    <Col md="3" sm="6">
                                        {otherPriceList.length !== 1 && <Button onClick={() => handleRemoveClickEdit(i)}>Delete</Button>}
                                        {otherPriceList.length - 1 === i && <Button onClick={handleAddClickEdit}>+ Add price</Button>}
                                    </Col>
                                    <Col md="3" sm="6">

                                    </Col>
                                    <Col md="3" sm="6">

                                    </Col>
                                    <Col md="3" sm="6">

                                    </Col>
                                </Row>

                            </>
                        );
                    })}
                    <Row>
                        <Col>
                            <Button onClick={editProducts} disabled={editLoading} color="primary" type="button">
                                {editLoading ? <ReactLoading type="spinningBubbles" color="#fff" height="40px" width="40px" /> : 'Edit product'}
                            </Button>
                        </Col>
                    </Row>

                </div>
                <div className="modal-footer">
                    <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setModal(false)}
                    >
                        Close
                    </Button>
                </div>
            </Modal>
            <Modal
                style={{ minWidth: '80%' }}
                className="modal-dialog-centered"
                isOpen={priceModal}
                toggle={() => setPriceModal(false)}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                        Prices for this product
                    </h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setPriceModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    {productPriceList.map((item, index) => (
                        <>
                            <ul key={index}>
                                <li>Name: {item.nickname ? item.nickname : 'Unnamed'}</li>
                                <li>Recurrent: {item.type === 'one_time' ? 'No' : 'Yes'}</li>
                                <li>Interval: {item.type === 'one_time' ? 'N/A' : handleTypes(item.recurring.interval, item.recurring.interval_count)}</li>
                                <li>Price: US {(item.unit_amount / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</li>
                            </ul>
                        </>
                    ))}
                </div>
                <div className="modal-footer">
                    <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setPriceModal(false)}
                    >
                        Close
                    </Button>
                </div>
            </Modal>
            <SimpleHeader name="Products" parentName="Forms" />
            <Container className="mt--6" fluid>
                <Card className="mb-4">
                    <CardHeader>
                        <h3 className="mb-0">Create new product</h3>
                    </CardHeader>
                    <form onSubmit={createProducts}>
                        <CardBody>
                            <h3 className="mb-0">Product information</h3>
                            <Row>
                                <Col md="4" sm="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="name_field"
                                        >
                                            Name
                                        </label>
                                        <Input
                                            id="name_field"
                                            placeholder="Name"
                                            type="text"
                                            name="product_name"
                                            value={name}
                                            onChange={e => setName(e.target.value)}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4" sm="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="description_field"
                                        >
                                            Description
                                        </label>
                                        <Input
                                            id="description_field"
                                            placeholder="Description"
                                            type="text"
                                            name="product_description"
                                            required
                                            value={description}
                                            onChange={e => setDescription(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4" sm="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="category_field"
                                        >
                                            Category
                                        </label>
                                        <Col md="10">
                                            <Input required name="category" id="category_field" type="select" onChange={e => setCategory(e.target.value)}>
                                                {categories.map(item => (
                                                    <option value={item}>{item}</option>
                                                ))}
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="3" sm="4">

                                </Col>
                                <Col md="6" sm="4">

                                </Col>
                                <Col md="2" sm="4">

                                </Col>
                            </Row>
                            <h3 className="mb-0">Price information</h3>
                            {priceList.map((x, i) => {
                                return (
                                    <>
                                        <Row key={i} className="align-items-center" style={{ marginBottom: 20 }}>
                                            <Col md="3" sm="6">
                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="price_field"
                                                            >
                                                                Price Name
                                                            </label>
                                                            <Input
                                                                id="price_field"
                                                                placeholder="Price Name"
                                                                type="text"
                                                                name="prices"
                                                                value={x.priceName}
                                                                onChange={e => handlePriceNameChange(e.target.value, i)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="price_field"
                                                            >
                                                                Price
                                                            </label>
                                                            <Input
                                                                id="price_field"
                                                                placeholder="Price"
                                                                type="text"
                                                                name="prices"
                                                                value={x.prices}
                                                                onChange={e => handleInputChange(e.target.value, i)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md="3" sm="6">
                                                <Row>
                                                    <Button style={{ border: x.recurrence === 0 ? '2px solid #000' : '' }} onClick={() => handleButton(i, 0)}>One time</Button>
                                                    <Button style={{ border: x.recurrence === 1 ? '2px solid #000' : '' }} onClick={() => handleButton(i, 1)}>Recurring</Button>
                                                </Row>
                                            </Col>
                                            <Col md="3" sm="6">
                                                {x.recurrence === 1 ? <>
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="cycle_interval"
                                                        >
                                                            Cycle Interval
                                                        </label>
                                                        <Input id="cycle_interval"
                                                            name="cycle_interval"
                                                            className="form-control"
                                                            onChange={e => handleSelection(i, e.target.value)}
                                                            type="select"
                                                        >
                                                            <option value="day">Daily</option>
                                                            <option value="week">Weekly</option>
                                                            <option value="month">Monthly</option>
                                                            <option value="3month">Every 3 Months</option>
                                                            <option value="6month">Every 6 Months</option>
                                                            <option value="year">Yearly</option>
                                                        </Input>
                                                    </FormGroup>
                                                </> : ''}
                                            </Col>
                                            <Col md="3" sm="6">
                                                {priceList.length !== 1 && <Button onClick={() => handleRemoveClick(i)}>Delete</Button>}
                                                {priceList.length - 1 === i && <Button onClick={handleAddClick}>+ Add price</Button>}
                                            </Col>
                                            <Col md="3" sm="6">

                                            </Col>
                                            <Col md="3" sm="6">

                                            </Col>
                                            <Col md="3" sm="6">

                                            </Col>
                                        </Row>

                                    </>
                                );
                            })}
                            <Row>
                                <Col>
                                    <Button disabled={loading} color="primary" type="submit">
                                        {loading ? <ReactLoading type="spinningBubbles" color="#fff" height="20px" width="20px" /> : 'Create product'}
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </form>
                </Card>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="border-0">
                                <h3 className="mb-0">Products</h3>
                            </CardHeader>

                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th className="sort" data-sort="name" scope="col">
                                            Name
                                        </th>
                                        <th className="sort" data-sort="budget" scope="col">
                                            Description
                                        </th>
                                        <th className="sort" data-sort="status" scope="col">
                                            Amount
                                        </th>
                                        <th className="sort" data-sort="status" scope="col">
                                            Active
                                        </th>
                                        <th scope="col">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="list">
                                    {list ? list.products.map((item) => (
                                        <tr>
                                            <td style={{ minWidth: 400, whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} scope="row">
                                                {item.name}
                                            </td>
                                            <td style={{ minWidth: 400, whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                                                {item.description}
                                            </td>
                                            <td onClick={() => showPrices(item.id)} title="Click to see prices" style={{ cursor: 'pointer' }}>
                                                {allPrices.filter(function (value) {
                                                    if (value.product === item.id)
                                                        return value;
                                                }).length} prices
                                            </td>
                                            <td>
                                                {item.active ? 'Yes' : 'No'}
                                            </td>
                                            <td className="text-right">
                                                <UncontrolledDropdown>
                                                    <DropdownToggle
                                                        className="btn-icon-only text-light"
                                                        color=""
                                                        role="button"
                                                        size="sm"
                                                    >
                                                        <i className="fas fa-ellipsis-v" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-arrow" right>
                                                        <DropdownItem
                                                            href="#pablo"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setProductId(item.id);
                                                                setModal(true);
                                                            }}
                                                        >
                                                            Edit
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            href="#pablo"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                deleteUser(item.stripe_product_id);
                                                            }}
                                                        >
                                                            Delete
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </td>
                                        </tr>
                                    )) : ''}
                                </tbody>
                            </Table>

                            <CardFooter className="py-4">
                                <nav aria-label="...">

                                    <Pagination
                                        className="pagination justify-content-end mb-0"
                                        listClassName="justify-content-end mb-0"
                                    >
                                        <PaginationItem>
                                            <PaginationLink
                                                onClick={(e) => {
                                                    if (actualPage > 1) {
                                                        setActualPage(actualPage - 1);
                                                    }
                                                }}
                                                tabIndex="-1"
                                            >
                                                <i className="fas fa-angle-left" />
                                                <span className="sr-only">Previous</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                        {
                                            Array(list ? Number((list.allProducts.length / 5).toFixed(1).split('.')[1]) > 5 ? Math.round(list.allProducts.length / 5) : Math.round(list.allProducts.length / 5) + 1 : 1).fill('').map((_, index) => {
                                                return (
                                                    <PaginationItem className={actualPage === (index + 1) ? 'active' : ''} key={index}>
                                                        <PaginationLink
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setActualPage(index + 1);
                                                            }}
                                                        >
                                                            {index + 1}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                )
                                            })
                                        }

                                        <PaginationItem>
                                            <PaginationLink
                                                onClick={(e) => {
                                                    if (actualPage < Math.round(list.allProducts.length / 5)) {
                                                        setActualPage(actualPage + 1);
                                                    }
                                                }}
                                            >
                                                <i className="fas fa-angle-right" />
                                                <span className="sr-only">Next</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                    </Pagination>

                                </nav>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default Elements;
