import api from '../../util/api';

const isAuthenticate = () => {
    const token = localStorage.getItem('token');
    if(!token){
        return false;
    }
    return api.get('/user/', {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then(res => {
        if(res.data.success){
            return true;
        }else {
            return false;
        }
    }).catch(e => {
        return false;
    });
} 

export default isAuthenticate;