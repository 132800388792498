import React, { useEffect, useState } from "react";
import Chart from "chart.js";
import {
    Card,
    CardHeader,
    Container,
    Row,
    CardBody,
    Table,
} from "reactstrap";
// import ReactDatetime from "react-datetime";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import swal from "sweetalert";
import api from '../../../util/api';
// import formatDate from '../../../util/formatDate';
// import useList from '../../../hooks/useList';
import usePagination from '../../../hooks/usePagination';
import {
    chartOptions,
    parseOptions,
    chartExample1,
    chartExample2,
} from "variables/charts.js";
import { Line, Bar } from "react-chartjs-2";

function Elements() {

    const { actualPage, setActualPage } = usePagination();

    const [description, setDescription] = useState('');
    const [date, setStartDate] = useState(new Date(Date.now()));
    const [futureDate, setFutureDate] = useState('');
    const [amount, setAmount] = useState('');
    const [recurrent, setRecurrent] = useState(true);
    const [isVariable, setIsVariable] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [category, setCategory] = useState('');
    const [months, setMonths] = useState(1);

    const [p, setP] = useState([]);
    const [l, setL] = useState([]);

    // console.log(allInvoices);

    const [endDate, setEndDate] = useState(new Date(Date.now()));

    const [payment_frequence, setPaymentFrequence] = useState('');

    const [newDescription, setNewDescription] = useState('');
    const [newDate, setNewDate] = useState('');
    const [newAmount, setNewAmount] = useState('');
    const [newCategory, setNewCategory] = useState('');
    const [newRecurrent, setNewRecurrent] = useState(false);
    const [newVariable, setNewVariable] = useState(false);
    const [newPaymentFrequence, setNewPaymentFrequence] = useState('');

    const [modal, setModal] = useState(false);
    const [updateId, setUpdateId] = useState(null);
    const [oldData, setOldData] = useState({});
    const [editExpense, setEditExpense] = useState({});
    const [sort, setSort] = useState(0);
    const [expenses, setExpenses] = useState([]);
    const [allInvoices, setAllInvoices] = useState([]);

    const [customers, setCustomers] = useState([]);
    const [customersAmount, setCustomersAmount] = useState([])

    console.log(allInvoices);
    
    // useEffect(() => {
    //     setFutureDate(date);
    //     setFutureDate(addMonths(new Date(futureDate), months).toLocaleDateString());
    // }, [months, date]);

    const compare = (a, b) => {
        if (a > b) return 1;
        if (a < b) return -1;

        return 0;
    }
    const token = localStorage.getItem('token');

    const categoryMap = [
        'Advertising & Promotion',
        'Depreciation & Amortization',
        'Insurance',
        'Maintenance',
        'Office Supplies',
        'Rent',
        'Salaries, Benefits & Wages',
        'Telecommunication',
        'Travel',
        'Utilities',
        'Licenses'
    ]

    const updateInit = (id) => {
        setUpdateId(id);
        api.get(`/expenses/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            if (res.data.success) {
                setOldData(res.data.expenses);
            }
        }).catch(e => console.log(e));;
    }
    useEffect(() => {
        api.get(`/pandl`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            if(res.data.success){
                const customersName = [];
                const amounts = [];
                res.data.p.map((item) => {
                    customersName.push(item.company)
                    amounts.push(sumAllAmountYearCus(item.invoices));
                });
                setCustomersAmount(amounts)
                setCustomers(customersName);
                setP(res.data.p);
                setL(res.data.l);
                setAllInvoices(res.data.allInvoices.data);
            }
        })
        api.get('/expenses?offset=0&order=date-DESC', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            if(res.data.success){
                setExpenses(res.data.allExpenses);
            }
        })
    }, [submit]);
    if (window.Chart) {
        parseOptions(Chart, chartOptions());
    }

    const insertExpenses = async (e) => {
        e.preventDefault();
        if (description === '') {
            await swal('Atention', 'Insert a description', 'warning');
            return;
        }
        if (amount === '') {
            await swal('Atention', 'Insert a description', 'warning');
            return;
        }
        if (category === '') {
            await swal('Atention!', 'Select a category!', 'warning');
            return;
        }
        api.post('/expenses/create', {
            description,
            date,
            amount: Number(amount),
            recurrent: recurrent,
            category,
            payment_frequence,
            variable: isVariable,
            months
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(async res => {
            if (res.data.success) {
                await swal('Success!', 'Expense registered succesfully!', 'success');
                setDescription('');
                setStartDate(new Date().toJSON().slice(0, 10));
                setAmount('');
                setRecurrent(false);
                setSubmit(!submit);
            } else {
                await swal('Atention!', res.data.message, 'warning');
            }
        }).catch(async e => {
            await swal('Error', 'An Error ocurred', 'error');
            console.log(e);
        });
    }
    const deleteExpense = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this expense",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    api.delete(`/expenses/${id}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }).then(async res => {
                        if (res.data.success) {
                            await swal('Success!', 'Expense deleted!', 'success');
                        } else {
                            await swal('Error', 'An error ocurred', 'error');
                        }
                        setSubmit(!submit);
                    }).catch(async e => {
                        await swal('Error', 'An error ocurred', 'error');
                        console.log(e);
                    });
                } else {
                    return;
                }
            });
    }
    const saveChanges = (e) => {
        e.preventDefault();

        api.put(`/expenses/${updateId}`, {
            description: newDescription,
            date: newDate,
            amount: newAmount,
            recurrent: newRecurrent,
            variable: newVariable,
            category: newCategory,
            payment_frequence: newPaymentFrequence
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(async res => {
            if (res.data.success) {
                await swal('Success!', 'Expense edited sucessfully', 'success');
                setModal(false);
                window.location.href = '/admin/expenses';
            } else {
                await swal('Error!', res.data.message, 'error');
            }
        }).catch(async e => {
            await swal('Error', 'An error ocurred!', 'error');
            console.log(e);
        });
    }

    useEffect(() => {
        var x = Number(months); //or whatever offset
        var CurrentDate = new Date(date);
        CurrentDate.setMonth(CurrentDate.getMonth() + x);
        setFutureDate(CurrentDate.toLocaleDateString());
    }, [months, date])


    // function addMonths(date, months) {
    //     var d = date.getDate();
    //     date.setMonth(date.getMonth() + +months);
    //     if (date.getDate() != d) {
    //       date.setDate(0);
    //     }
    //     return date;
    // }

    
    function sumAmount(array, month){
        if(array.length === 0){
            return 0;
        }
        const total = array.filter(function(value){
            if(getStripeMonth(value.created) === month && value.paid){
                return true;
            }
            return false;
        }).reduce(function(tot, arr){
            console.log(tot)
            return tot + arr.total
        }, 0);                                                                           
        return total / 100;
    }
    function sumAllAmount(month){

        const values = [];
        p.map((item, i) => {
            console.log(item)
            if(item.invoices.length === 0){
                values.push(0);
            }else {
                values.push(item.invoices.filter(function(value){
                    if(getStripeMonth(value.created) === month){
                        return true;
                    }
                    return false;
                }).reduce(function(tot, arr){
                    return tot + arr.total;
                }, 0))
            }
        })
        const total = values.reduce(function(tot, arr){
            return tot + arr;
        }, 0);
        return total / 100;
    }
    function sumAllAmountYearCus(array){
        var total = 0;
        if(array.length === 0){
            return 0;
        }
        array.map((item, i) => {
            total += item.total;
        });
        return total / 100; 
    }
    function sumAllAmountYear(array){
        const values = [];
        p.map((item, i) => {
            console.log(item)
            if(item.invoices.length === 0){
                values.push(0);
            }else {
                values.push(item.invoices.reduce(function(tot, arr){
                    return tot + arr.total;
                }, 0))
            }
        })
        const total = values.reduce(function(tot, arr){
            return tot + arr;
        }, 0);
        return total / 100;
    }

    function getStripeMonth(time){
        const mydate = new Date(time * 1000);
        return mydate.getMonth() + 1;
    }
    function sumByCategory(category, month){
        const newArr = expenses.filter(function(value){
            if(value.category === category && (new Date(value.date)).getMonth() + 1 === month){
                return true;
            }
            return false;
        })
        if(newArr.length === 0){
            return 0;
        }
        var total = 0;
        newArr.map((item, i) => {
            total += item.amount;
        });
        return total;
    }
    function sumAllByCategory(category){
        const newArr = expenses.filter(function(value){
            if(value.category === category){
                return true;
            }
            return false;
        })
        if(newArr.length === 0){
            return 0;
        }
        var total = 0;
        newArr.map((item, i) => {
            total += item.amount;
        });
        return total;
    }
    function sumAllExpense(){
        if(expenses.length === 0){
            return 0;
        }
        var total = 0;
        expenses.map((item, i) => {
            total += item.amount;
        });
        return total;
    }
    function sumAllExpensePerMonth(month){
        const newArr = expenses.filter(function(value){
            if((new Date(value.date)).getMonth() + 1 === month){
                return true;
            }
            return false;
        })
        if(newArr.length === 0){
            return 0;
        }
        var total = 0;
        newArr.map((item, i) => {
            total += item.amount;
        });
        return total;
    }
    function calcProfitMargin(totalRevenue, earnings){
        if(totalRevenue > 0){
            return `${(earnings / totalRevenue).toLocaleString("en", { style: "percent", minimumFractionDigits: 2 }) }`;
        }else {
            return null;
        }
    }
    return (
        <>
            <SimpleHeader name="Revenue" parentName="" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card className="pandl">
                            <CardHeader className="border-0">
                                <h3 className="mb-0">Revenue</h3>
                            </CardHeader>

                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Company</th>
                                        <th scope="col">Jan</th>
                                        <th scope="col">Feb</th>
                                        <th scope="col">Mar</th>
                                        <th scope="col">Apr</th>
                                        <th scope="col">May</th>
                                        <th scope="col">Jun</th>
                                        <th scope="col">Jul</th>
                                        <th scope="col">Aug</th>
                                        <th scope="col">Sep</th>
                                        <th scope="col">Oct</th>
                                        <th scope="col">Nov</th>
                                        <th scope="col">Dec</th>
                                        <th scope="col">Full Year</th>
                                    </tr>
                                </thead>
                                <tbody className="tbody-pandl" className="list">
                                    {p.map((item) => (
                                        <tr>
                                            <th className="budget-head" scope="row"> {item.company} </th>
                                            <td className="budget">{sumAmount(item.invoices, 1).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                            <td className="budget">{sumAmount(item.invoices, 2).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                            <td className="budget">{sumAmount(item.invoices, 3).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                            <td className="budget">{sumAmount(item.invoices, 4).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                            <td className="budget">{sumAmount(item.invoices, 5).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                            <td className="budget">{sumAmount(item.invoices, 6).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                            <td className="budget">{sumAmount(item.invoices, 7).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                            <td className="budget">{sumAmount(item.invoices, 8).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                            <td className="budget">{sumAmount(item.invoices, 9).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                            <td className="budget">{sumAmount(item.invoices, 10).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                            <td className="budget">{sumAmount(item.invoices, 11).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                            <td className="budget">{sumAmount(item.invoices, 12).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                            <td className="budget">{sumAllAmountYearCus(item.invoices).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td><b>TOTAL REVENUE</b></td>
                                        <td className="budget">{sumAllAmount(1).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="budget">{sumAllAmount(2).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="budget">{sumAllAmount(3).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="budget">{sumAllAmount(4).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="budget">{sumAllAmount(5).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="budget">{sumAllAmount(6).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="budget">{sumAllAmount(7).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="budget">{sumAllAmount(8).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="budget">{sumAllAmount(9).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="budget">{sumAllAmount(10).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="budget">{sumAllAmount(11).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="budget">{sumAllAmount(12).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td className="budget">{sumAllAmountYear().toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <hr/>
                            <Card>
                            <CardHeader className="bg-transparent">
                                <Row className="align-items-center">
                                <div className="col">
                                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                                    Compare customers revenue
                                    </h6>
                                </div>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <div className="chart">
                                <Bar
                                    data={{
                                    labels: customers,
                                    datasets: [
                                        {
                                        label: "Customers revenue",
                                        data: customersAmount,
                                        maxBarThickness: 10
                                        }
                                    ]
                                    }}
                                    options={chartExample2.options}
                                    className="chart-canvas"
                                    id="chart-bars"
                                />
                                </div>
                            </CardBody>
                            </Card>                      
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default Elements;
