import React from "react";
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

function AuthFooter() {
  return (
    <>
      <footer className="py-5" id="footer-main">
        <Container>
        <Row className="align-items-center justify-content-lg-between">
            <Col lg="6">
              <div className="copyright text-center text-lg-left text-muted">
                © {new Date().getFullYear()}{" "}
                <a
                  className="font-weight-bold ml-1"
                  href="https://mswebmaker.com"
                  target="_blank"
                >
                  MSWebmaker
                </a>
              </div>
            </Col>
            <Col lg="6">
              <Nav className="nav-footer justify-content-center justify-content-lg-end">
                <NavItem>
                  <NavLink
                    href="https://mswebmaker.com/terms"
                    target="_blank"
                  >
                    Terms of Use
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="https://mswebmaker.com/privacy-policy"
                    target="_blank"
                  >
                    Privacy Policy
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default AuthFooter;
