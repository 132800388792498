import React, { useEffect, useState } from "react";

import {
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    Button,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown,
    Pagination,
    PaginationItem,
    PaginationLink,
    Modal,
    Collapse,
    Table
} from "reactstrap";
// core components
import InputMask from 'react-input-mask';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import swal from "sweetalert";
import api from '../../../util/api';
import validate from '../../../util/validate';
import Select2 from "react-select2-wrapper";
import useList from '../../../hooks/useList';
import usePagination from '../../../hooks/usePagination';



function Elements() {

    const token = localStorage.getItem('token');

    const { list, fetchList } = useList('/companies');
    const { actualPage, setActualPage } = usePagination();

    const [user, setUser] = useState('');
    const [companyEdit, setCompanyEdit] = useState('');
    const [noteModal, setNoteModal] = useState(false);
    const [submit, setSubmit] = useState(true);

    const [companyId, setCompanyId] = useState(null);

    const [propModal, setPropModal] = useState(false);
    const [propertyId, setPropertyId] = useState('');
    const [cid, setCid] = useState('');

    const addPropertyId = (id) => {
        setPropModal(true);
        setCid(id)
    }
    const addPropertyIdNow = async () => {
        if(propertyId === ''){
            await swal('Atention!', 'Please insert a property ID', 'warning');
            return;
        }
        if(cid === ''){
            await swal('Atention!', "Can't identify this company!", 'warning');
            setPropModal(false);
            return;
        }
        api.post('/companies/add-property', {
            companyId: cid,
            propertyId: propertyId
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(async res => {
            if(res.data.success){
                await swal('Success!', 'This property ID is associated!', 'success');
                setPropModal(false);
            }else {
                await swal('Atention!', res.data.message, 'warning');
            }
        }).catch(async e => await swal('Error!', 'An error ocurred!', 'error'));
    }


    useEffect(() => {

        fetchList(actualPage)
        api.get('/user', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            if (res.data.success) {
                setUser(res.data.user);
            }
        });

    }, [submit, actualPage]);
    useEffect(() => {
        api.get(`/companies/${companyId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            if (res.data.success) {
                setCompanyEdit(res.data.company);
            }
        })
    }, [companyId])


    return (
        <>

<Modal
                style={{ minWidth: '80%' }}
                className="modal-dialog-centered"
                isOpen={propModal}
                toggle={() => setNoteModal(false)}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                        Add Property ID
                    </h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setPropModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col>
                            <Input type="text" value={propertyId} onChange={e => setPropertyId(e.target.value)} />
                        </Col>
                    </Row>
                </div>
                <div className="modal-footer">
                    <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={addPropertyIdNow}
                    >
                        Add property ID
                    </Button>
                    <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setPropModal(false)}
                    >
                        Close
                    </Button>
                    
                </div>
            </Modal>

            <SimpleHeader name="Analytics Control" parentName="" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="border-0">
                                <h3 className="mb-0">Companies</h3>
                            </CardHeader>

                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th className="sort" data-sort="name" scope="col">Alias</th>
                                        <th className="sort" data-sort="status" scope="col">Industry</th>
                                        <th className="sort" data-sort="completion" scope="col">Decision Maker</th>
                                        <th className="sort" data-sort="completion" scope="col">Phone</th>
                                        <th className="sort" data-sort="completion" scope="col">Email</th>
                                        <th className="sort" data-sort="completion" scope="col">Site</th>
                                        <th className="sort" data-sort="completion" scope="col">Facebook</th>
                                        <th className="sort" data-sort="completion" scope="col">Instagram</th>
                                        <th className="sort" data-sort="completion" scope="col">Linkedin</th>
                                        <th className="sort" data-sort="completion" scope="col">Other social medias</th>
                                        <th className="sort" data-sort="completion" scope="col">Analytics(Propery) ID</th>
                                        <th className="sort" data-sort="completion" scope="col">Options</th>
                                    </tr>
                                </thead>
                                <tbody className="list">
                                    {list ? list.companies.map((item) => (
                                        <tr>
                                            <th scope="row">{item.alias}</th>
                                            <td>{item.industry}</td>
                                            <td>{item.decision_maker}</td>
                                            <td>{item.phone}</td>
                                            <td>{item.email}</td>
                                            <td>{item.site}</td>
                                            <td>{item.facebook}</td>
                                            <td>{item.instagram}</td>
                                            <td>{item.linkedin}</td>
                                            <td>{item.other_social_medias}</td>
                                            <td>{item.property_id}</td>
                                            <td className="text-right">
                                                <UncontrolledDropdown>
                                                    <DropdownToggle
                                                        className="btn-icon-only text-light"
                                                        color=""
                                                        role="button"
                                                        size="sm"
                                                    >
                                                        <i className="fas fa-ellipsis-v" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-arrow" right>
                                                        <DropdownItem
                                                            onClick={() => addPropertyId(item.id)}
                                                        >
                                                            Add Analytics ID
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </td>
                                        </tr>
                                    )) : ''}
                                </tbody>
                            </Table>

                            <CardFooter className="py-4">
                                <nav aria-label="...">

                                    <Pagination
                                        className="pagination justify-content-end mb-0"
                                        listClassName="justify-content-end mb-0"
                                    >
                                        <PaginationItem>
                                            <PaginationLink
                                                onClick={(e) => {
                                                    if (actualPage > 1) {
                                                        setActualPage(actualPage - 1);
                                                    }
                                                }}
                                                tabIndex="-1"
                                            >
                                                <i className="fas fa-angle-left" />
                                                <span className="sr-only">Previous</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                        {
                                            Array(list ? Math.round(list.allCompanies.length / 10) === 0 ? 1 : Number((list.allCompanies.length / 10).toFixed(1).split('.')[1]) > 5 ? Math.round(list.allCompanies.length / 10) : Math.round(list.allCompanies.length / 10) + 1 : 1).fill('').map((_, index) => {
                                                return (
                                                    <PaginationItem className={actualPage === (index + 1) ? 'active' : ''} key={index}>
                                                        <PaginationLink
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setActualPage(index + 1);
                                                            }}
                                                        >
                                                            {index + 1}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                )
                                            })
                                        }

                                        <PaginationItem>
                                            <PaginationLink
                                                onClick={(e) => {
                                                    if (actualPage < Math.round(list.allCompanies.length / 5)) {
                                                        setActualPage(actualPage + 1);
                                                    }
                                                }}
                                            >
                                                <i className="fas fa-angle-right" />
                                                <span className="sr-only">Next</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                    </Pagination>

                                </nav>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>

            </Container>
        </>
    );
}

export default Elements;
