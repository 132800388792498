import React, { useEffect, useState } from "react";

import {
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    Button,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledDropdown,
    Pagination,
    PaginationItem,
    PaginationLink,
    Modal,
    Collapse,
    Table
} from "reactstrap";
// core components
import InputMask from 'react-input-mask';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import swal from "sweetalert";
import api from '../../../util/api';
import validate from '../../../util/validate';
import Select2 from "react-select2-wrapper";
import useList from '../../../hooks/useList';
import usePagination from '../../../hooks/usePagination';



function Elements() {

    const token = localStorage.getItem('token');

    const { list, fetchList } = useList('/companies');
    const { actualPage, setActualPage } = usePagination();

    const [modal, setModal] = useState(false);

    const [isOpen, setIsOpen] = useState(false);


    const [channel, setChannel] = useState('');
    const [alias, setAlias] = useState('');
    const [entity_name, setEntityName] = useState('');
    const [industry, setIndustry] = useState('');
    const [decisionMaker, setDecisionMaker] = useState('');
    const [phone, setPhone] = useState('');
    const [phone2, setPhone2] = useState('');
    const [email, setEmail] = useState('');
    const [site, setSite] = useState('');
    const [isResponsive, setIsResponsive] = useState('');
    const [country, setCountry] = useState('US');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [business_address, setBusinessAddress] = useState('');
    const [mailing_address, setMailingAddress] = useState('');
    const [isSame, setIsSame] = useState(false);
    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [linkedin, setLinkedin] = useState('');
    const [otherSocialMedias, setOtherSocialMedias] = useState('');
    const [reviews, setReviews] = useState('');
    const [notes, setNotes] = useState('');
    const [einNumber, setEinNumber] = useState('');

    const [new_channel, setNewChannel] = useState('');
    const [new_alias, setNewAlias] = useState('');
    const [new_entity_name, setNewEntityName] = useState('');
    const [new_industry, setNewIndustry] = useState('');
    const [new_decisionMaker, setNewDecisionMaker] = useState('');
    const [new_phone, setNewPhone] = useState('');
    const [new_phone2, setNewPhone2] = useState('');
    const [new_email, setNewEmail] = useState('');
    const [new_site, setNewSite] = useState('');
    const [new_isResponsive, setNewIsResponsive] = useState('');
    const [new_country, setNewCountry] = useState('US');
    const [new_state, setNewState] = useState('');
    const [new_city, setNewCity] = useState('');
    const [new_zipCode, setNewZipCode] = useState('');
    const [new_business_address, setNewBusinessAddress] = useState('');
    const [new_mailing_address, setNewMailingAddress] = useState('');
    const [new_isSame, setNewIsSame] = useState(false);
    const [new_facebook, setNewFacebook] = useState('');
    const [new_instagram, setNewInstagram] = useState('');
    const [new_linkedin, setNewLinkedin] = useState('');
    const [new_otherSocialMedias, setNewOtherSocialMedias] = useState('');
    const [new_reviews, setNewReviews] = useState('');
    const [new_notes, setNewNotes] = useState('');
    const [new_einNumber, setNewEinNumber] = useState('');

    const [submit, setSubmit] = useState(true);

    const [modalView, setModalView] = useState(false);

    const [companyId, setCompanyId] = useState(null);
    const [user, setUser] = useState({});
    const [companyEdit, setCompanyEdit] = useState(null);

    const [viewNote, setViewNote] = useState('');
    const [noteModal, setNoteModal] = useState(false);

    const [propModal, setPropModal] = useState(false);
    const [propertyId, setPropertyId] = useState('');
    const [cid, setCid] = useState('');

    const [compInfo, setCompInfo] = useState({
        channel: '',
        entity_name: '',
        alias: '',
        industry: '',
        decision_maker: '',
        phone: '',
        phone2: '',
        email: '',
        site: '',
        is_responsive: false,
        country: '',
        state: '',
        city: '',
        zip_code: '',
        business_address: '',
        mailing_address: '',
        facebook: '',
        instagram: '',
        linkedin: '',
        other_social_medias: '',
        reviews: '',
        notes: '',
        ein_number: '',
        property_id: '',
        jira_id: '',
        jira_key: ''
    });

    console.log(companyEdit, companyId);

    async function showNotes(note) {
        if (!note) {
            await swal('Ops!', 'This note is empty!', 'info');
            return;
        }
        setNoteModal(true);
        setViewNote(note);
    }


    const getCompany = (id) => {
        api.get(`/companies/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(async res => {
            if(res.data.success){
                setCompInfo(res.data.company);
            }else {
                await swal('Atention!', res.data.message || 'Error!', 'warning');
                setModalView(false);
            }
        }).catch(async e => {
            await swal('Atention!', 'An error ocurred!', 'warning');
            setModalView(false);
        })
    }

    const deleteCompany = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this company",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    api.delete(`/companies/${id}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }).then(async res => {
                        if (res.data.success) {
                            await swal('Success!', 'Company deleted!', 'success');
                        } else {
                            await swal('Error', 'An error ocurred', 'error');
                        }
                        setSubmit(!submit);
                    }).catch(async e => {
                        await swal('Error', 'An error ocurred', 'error');
                        console.log(e);
                    });
                } else {
                    return;
                }
            });
    }

    const addPropertyId = (id) => {
        setPropModal(true);
        setCid(id)
    }
    const addPropertyIdNow = async () => {
        if(propertyId === ''){
            await swal('Atention!', 'Please insert a property ID', 'warning');
            return;
        }
        if(cid === ''){
            await swal('Atention!', "Can't identify this company!", 'warning');
            setPropModal(false);
            return;
        }
        api.post('/companies/add-property', {
            companyId: cid,
            propertyId: propertyId
        }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then(async res => {
            if(res.data.success){
                await swal('Success!', 'This property ID is associated!', 'success');
                setPropModal(false);
            }else {
                await swal('Atention!', res.data.message, 'warning');
            }
        }).catch(async e => await swal('Error!', 'An error ocurred!', 'error'));
    }

    const saveChanges = async (e) => {
        e.preventDefault();
        if (new_email !== '') {
            if (!validate.validateEmail(new_email)) {
                await swal('Atention!', 'Invalid email!', 'warning');
                return;
            }
        }
        if (companyId === null) {
            await swal('Atention!', 'Select an company to edit', 'warning');
            return;
        }
        api.put(`/companies/${companyId}`, {
            channel: new_channel,
            alias: new_alias,
            entity_name: new_entity_name,
            industry: new_industry,
            decisionMaker: new_decisionMaker,
            phone: new_phone,
            phone2: new_phone2,
            email: new_email,
            site: new_site,
            isResponsive: new_isResponsive,
            country: new_country,
            state: new_state,
            city: new_city,
            zipCode: new_zipCode,
            business_address: new_business_address,
            mailing_address: new_mailing_address,
            isSame: new_isSame,
            facebook: new_facebook,
            instagram: new_instagram,
            linkedin: new_linkedin,
            otherSocialMedias: new_otherSocialMedias,
            reviews: new_reviews,
            notes: new_notes,
            einNumber: new_einNumber
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(async res => {
            if (res.data.success) {
                await swal('Success!', 'Company updated successfully!', 'success');
                setModal(false);
                setSubmit(!submit);
                setNewChannel('');
                setNewAlias('');
                setNewEntityName('');
                setNewIndustry('');
                setNewDecisionMaker('');
                setNewPhone('');
                setNewPhone2('');
                setNewEmail('');
                setNewSite('');
                setNewCountry('');
                setNewState('');
                setNewCity('');
                setNewZipCode('');
                setNewBusinessAddress('');
                setNewMailingAddress('');
                setNewFacebook('');
                setNewInstagram('');
                setNewLinkedin('');
                setNewReviews('');
                setNewNotes('');
                setNewEinNumber('');
            } else {
                await swal('Error!', res.data.message, 'Error!');
            }
        }).catch(async e => await swal('Error!', 'An error ocurred!', 'error'));
    }



    useEffect(() => {

        fetchList(actualPage)
        api.get('/user', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            if (res.data.success) {
                setUser(res.data.user);
            }
        });

    }, [submit, actualPage]);
    useEffect(() => {
        api.get(`/companies/${companyId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            if (res.data.success) {
                setCompanyEdit(res.data.company);
            }
        })
    }, [companyId])

    const insertCompany = (e) => {
        e.preventDefault();
        api.post('/companies/create', {
            channel, entity_name, alias, industry, decisionMaker, phone, phone2, email, site, is_responsive: isResponsive, country, state, city, zipCode, business_address, mailing_address: isSame ? business_address : mailing_address, facebook, instagram, linkedin, otherSocialMedias, reviews, notes, einNumber
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(async res => {
            if (res.data.success) {
                await swal('Success!', 'Company created succesfully!', 'success');
                console.log('Ok');
                setChannel('');
                setEntityName('');
                setAlias('');
                setIndustry('')
                setDecisionMaker('')
                setPhone('')
                setPhone2('')
                setEmail('')
                setSite('')
                setIsResponsive(false)
                setCountry('')
                setState('')
                setCity('')
                setZipCode('')
                setBusinessAddress('');
                setMailingAddress('');
                setIsSame(false);
                setFacebook('')
                setInstagram('')
                setLinkedin('')
                setOtherSocialMedias('')
                setReviews('')
                setNotes('')
                setEinNumber('')
                setSubmit(!submit);
                setIsOpen(false);
            } else {
                await swal('Error!', res.data.message, 'error');
            }
        });
    }

    return (
        <>

            <Modal
                style={{ minWidth: '80%' }}
                className="modal-dialog-centered"
                isOpen={noteModal}
                toggle={() => setNoteModal(false)}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                        View this note
                    </h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setNoteModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col>
                            <h1>Note:</h1>
                            <p>{viewNote}</p>
                        </Col>
                    </Row>
                </div>
                <div className="modal-footer">
                    <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setNoteModal(false)}
                    >
                        Close
                    </Button>
                </div>
            </Modal>


            <Modal
                style={{ minWidth: '80%' }}
                className="modal-dialog-centered"
                isOpen={modalView}
                toggle={() => setModalView(false)}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                        View this company
                    </h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setModalView(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    
                <Row>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="name_company_field"
                                                    >
                                                        Alias
                                                    </label>
                                                    <p>{modalView ? compInfo.alias : ''}</p>
                                                </FormGroup>
                                            </Col>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="name_company_field"
                                                    >
                                                        Entity Name
                                                    </label>
                                                    <p>{modalView ? compInfo.entity_name : ''}</p>
                                                </FormGroup>
                                            </Col>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="industry_select"
                                                    >
                                                        Industry
                                                    </label>
                                                    <p>{modalView ? compInfo.industry : ''}</p>

                                                </FormGroup>

                                            </Col>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="decision_maker_field"
                                                    >
                                                        Decision Maker
                                                    </label>
                                                    <p>{modalView ? compInfo.decision_maker : ''}</p>
                                                </FormGroup>
                                            </Col>

                                        </Row>


                                        <Row>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="channel_select"
                                                    >
                                                        Channel
                                                    </label>
                                                    <p>{modalView ? compInfo.channel : ''}</p>

                                                </FormGroup>
                                            </Col>
                                            <Col md="3" sm="6">

                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="site_field"
                                                    >
                                                        Site
                                                    </label>
                                                    <p>{modalView ? compInfo.site : ''}</p>
                                                </FormGroup>

                                            </Col>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="ein_number_field"
                                                    >
                                                        EIN Number
                                                    </label>
                                                    <p>{modalView ? compInfo.ein_number : ''}</p>
                                                </FormGroup>


                                            </Col>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                    >
                                                        Site is responsive?
                                                    </label><br />
                                                    <p>{modalView ? compInfo.is_responsive ? 'Yes' : 'No' : ''}</p>
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <hr />
                                        <h4>Contact information</h4>
                                        <Row>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="phone_field"
                                                    >
                                                        Phone
                                                    </label>
                                                    <p>{modalView ? compInfo.phone : ''}</p>
                                                </FormGroup>
                                            </Col>

                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="second_phone_field"
                                                    >
                                                        Second Phone(optional)
                                                    </label>
                                                    <p>{modalView ? compInfo.phone2 : ''}</p>
                                                </FormGroup>
                                            </Col>

                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="email_field"
                                                    >
                                                        Email
                                                    </label>
                                                    <p>{modalView ? compInfo.email : ''}</p>
                                                </FormGroup>
                                            </Col>
                                            <Col md="3" sm="6"> </Col>
                                        </Row>
                                        <hr />
                                        <h4>Address information</h4>
                                        
                                        <Row>
                                            <Col md="3" sm="6">

                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="address_field"
                                                    >
                                                        Business Address
                                                    </label>
                                                    <p>{modalView ? compInfo.business_address : ''}</p>
                                                </FormGroup>

                                            </Col>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="address_field"
                                                    >
                                                        Mailing Address
                                                    </label>
                                                    <p>{modalView ? !compInfo.mailing_address ? compInfo.business_address : compInfo.mailing_address : ''}</p>
                                                </FormGroup>
                                            </Col>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="city_field"
                                                    >
                                                        City
                                                    </label>
                                                    <p>{modalView ? compInfo.city : ''}</p>
                                                </FormGroup>
                                            </Col>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="state_select"
                                                    >
                                                        State
                                                    </label>
                                                    <p>{modalView ? compInfo.state : ''}</p>
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col md="3" sm="6">

                                                

                                            </Col>
                                            <Col md="3" sm="6">
                                            <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="country_select"
                                                    >
                                                        Country
                                                    </label>
                                                    <p>{modalView ? compInfo.country : ''}</p>
                                                </FormGroup>
                                                

                                            </Col>
                                            <Col md="3" sm="6">

                                            <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="zip_code_field"
                                                    >
                                                        Zip Code
                                                    </label>
                                                    <p>{modalView ? compInfo.zip_code : ''}</p>
                                                </FormGroup>                                                

                                            </Col>
                                            <Col md="3" sm="6">

                                                

                                            </Col>

                                        </Row>
                                        <hr />
                                        <h4>Social media information</h4>
                                        <Row>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="facebook_field"
                                                    >
                                                        Facebook
                                                    </label>
                                                    <p>{modalView ? compInfo.facebook : ''}</p>
                                                </FormGroup>
                                            </Col>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="instagram_field"
                                                    >
                                                        Instagram
                                                    </label>
                                                    <p>{modalView ? compInfo.instagram : ''}</p>
                                                </FormGroup>
                                            </Col>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="linkedin_field"
                                                    >
                                                        Linkedin
                                                    </label>
                                                    <p>{modalView ? compInfo.linkedin : ''}</p>
                                                </FormGroup>
                                            </Col>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="other_social_medias"
                                                    >
                                                        Other social medias
                                                    </label>
                                                    <p>{modalView ? compInfo.other_social_medias : ''}</p>
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <hr />
                                        <h4>Observation</h4>
                                        <Row>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="reviews_field"
                                                    >
                                                        Reviews
                                                    </label>
                                                    <p>{modalView ? compInfo.reviews : ''}</p>
                                                </FormGroup>
                                            </Col>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="notes_field"
                                                    >
                                                        Notes
                                                    </label>
                                                    <p>{modalView ? compInfo.notes : ''}</p>
                                                </FormGroup>
                                            </Col>
                                            <Col md="3" sm="6">
                                            <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="notes_field"
                                                    >
                                                        Analytics ID
                                                    </label>
                                                    <p>{modalView ? compInfo.property_id : ''}</p>
                                                </FormGroup>
                                            </Col>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="notes_field"
                                                    >
                                                        Jira ID
                                                    </label>
                                                    <p>{modalView ? compInfo.jira_id : ''}</p>
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="reviews_field"
                                                    >
                                                        Jira Key
                                                    </label>
                                                    <p>{modalView ? compInfo.jira_key : ''}</p>
                                                </FormGroup>
                                            </Col>
                                            <Col md="3" sm="6">
                                                
                                            </Col>
                                            <Col md="3" sm="6">
                                            
                                            </Col>
                                            <Col md="3" sm="6">
                                                
                                            </Col>

                                        </Row>
                                    
                </div>
                <div className="modal-footer">
                    <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setModalView(false)}
                    >
                        Close
                    </Button>
                </div>
            </Modal>

            <Modal
                style={{ minWidth: '80%' }}
                className="modal-dialog-centered"
                isOpen={propModal}
                toggle={() => setNoteModal(false)}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                        Add Property ID
                    </h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setPropModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col>
                            <Input type="text" value={propertyId} onChange={e => setPropertyId(e.target.value)} />
                        </Col>
                    </Row>
                </div>
                <div className="modal-footer">
                    <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={addPropertyIdNow}
                    >
                        Add property ID
                    </Button>
                    <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setPropModal(false)}
                    >
                        Close
                    </Button>
                    
                </div>
            </Modal>

            <Modal
                style={{ minWidth: '80%' }}
                className="modal-dialog-centered"
                isOpen={modal}
                toggle={() => setModal(false)}
            >
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                        Edit this company
                    </h6>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setModal(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    {user.type === 1 ? <>

                        <h4>Business Information</h4>
                        <form onSubmit={insertCompany}>

                            <Row>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="name_company_field"
                                        >
                                            Alias
                                        </label>
                                        <Input
                                            id="name_company_field"
                                            placeholder={companyEdit && companyEdit.alias}
                                            type="text"
                                            name="company_alias"
                                            value={new_alias}
                                            required
                                            onChange={e => setNewAlias(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="name_company_field"
                                        >
                                            Entity Name
                                        </label>
                                        <Input
                                            id="name_company_field"
                                            placeholder={companyEdit && companyEdit.entity_name}
                                            type="text"
                                            name="company_name"
                                            value={new_entity_name}
                                            required
                                            onChange={e => setNewEntityName(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="industry_select"
                                        >
                                            Industry
                                        </label>
                                        <Select2
                                            id="industry_select"
                                            className="form-control"
                                            defaultValue="Aerospace industry"
                                            options={{
                                                placeholder: "Select a industry",
                                            }}
                                            value={new_industry}
                                            onChange={e => setNewIndustry(e.target.value)}
                                            required
                                            data={[
                                                { id: "Aerospace industry", text: "Aerospace industry" },
                                                { id: "Agriculture", text: "Agriculture" },
                                                { id: "Fishing industry", text: "Fishing industry" },
                                                { id: "Timber industry", text: "Timber industry" },
                                                { id: "Tobacco industry", text: "Tobacco industry" },
                                                { id: "Chemical industry", text: "Chemical industry" },
                                                { id: "Pharmaceutical industry", text: "Pharmaceutical industry" },
                                                { id: "Computer industry", text: "Computer industry" },
                                                { id: "Software industry", text: "Software industry" },
                                                { id: "Construction industry", text: "Construction industry" },

                                                { id: "Defense industry", text: "Defense industry" },

                                                { id: "Energy industry", text: "Energy industry" },

                                                { id: "Electrical power industry", text: "Electrical power industry" },

                                                { id: "Petroleum industry", text: "Petroleum industry" },

                                                { id: "Entertainment industry", text: "Entertainment industry" },
                                                { id: "Food industry", text: "Food industry" },
                                                { id: "Health care industry", text: "Health care industry" },
                                                { id: "Hospitality industry", text: "Hospitality industry" },
                                                { id: "Information industry", text: "Information industry" },
                                                { id: "Insurance industry", text: "Insurance industry" },
                                                { id: "Manufacturing", text: "Manufacturing" },
                                                { id: "Arms industry", text: "Arms industry" },
                                                { id: "Automotive industry", text: "Automotive industry" },
                                                { id: "Pulp and paper industry", text: "Pulp and paper industry" },
                                                { id: "Steel industry", text: "Steel industry" },
                                                { id: "Toy industry", text: "Toy industry" },
                                                { id: "Shipbuilding industry", text: "Shipbuilding industry" },
                                                { id: "Mass media", text: "Mass media" },
                                                { id: "Broadcasting", text: "Broadcasting" },
                                                { id: "Film industry", text: "Film industry" },
                                                { id: "Internet", text: "Internet" },
                                                { id: "Music industry", text: "Music industry" },
                                                { id: "News media", text: "News media" },
                                                { id: "Publishing", text: "Publishing" },
                                                { id: "Telecommunications industry", text: "Telecommunications industry" },
                                                { id: "Water industry", text: "Water industry" }
                                            ]}
                                        />

                                    </FormGroup>

                                </Col>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="decision_maker_field"
                                        >
                                            Decision Maker
                                        </label>
                                        <Input
                                            id="decision_maker_field"
                                            placeholder={companyEdit && companyEdit.decision_maker}
                                            type="text"
                                            name="decision_maker"
                                            value={new_decisionMaker}
                                            onChange={e => setNewDecisionMaker(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>

                            </Row>


                            <Row>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="channel_select"
                                        >
                                            Channel
                                        </label>
                                        <Select2
                                            id="channel_select"
                                            className="form-control"
                                            defaultValue="Facebook"
                                            options={{
                                                placeholder: "Select a channel",
                                            }}
                                            value={new_channel}
                                            required
                                            onChange={e => setNewChannel(e.target.value)}
                                            data={[
                                                { id: "Facebook", text: "Facebook" },
                                                { id: "Instagram", text: "Instagram" },
                                                { id: "Google Ads", text: "Google Ads" },
                                                { id: "Email Campaign", text: "Email Campaign" },
                                                { id: "Website", text: "Website" },
                                                { id: "Referral", text: "Referral" },
                                                { id: "Direct Contact", text: "Direct Contact" }
                                            ]}
                                        />

                                    </FormGroup>
                                </Col>
                                <Col md="3" sm="6">

                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="site_field"
                                        >
                                            Site
                                        </label>
                                        <Input
                                            id="site_field"
                                            placeholder={companyEdit && companyEdit.site}
                                            name="site"
                                            type="url"
                                            value={new_site}
                                            onChange={e => setNewSite(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="ein_number_field"
                                        >
                                            EIN Number
                                        </label>
                                        <InputMask mask="99-9999999" value={new_einNumber} onChange={e => setNewEinNumber(e.target.value)}>
                                            {inputProps => <Input
                                                {...inputProps}
                                                id="ein_number_field"
                                                placeholder={companyEdit && companyEdit.ein_number}
                                                type="text"
                                                name="ein_number"
                                                required
                                            />}
                                        </InputMask>
                                    </FormGroup>


                                </Col>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                        >
                                            Site is responsive?
                                        </label><br />
                                        <label className="custom-toggle mr-1">
                                            <input type="checkbox" checked={new_isResponsive} onChange={() => setNewIsResponsive(!new_isResponsive)} name="is_responsive" />
                                            <span
                                                className="custom-toggle-slider rounded-circle"
                                                data-label-off="No"
                                                data-label-on="Yes"
                                            />
                                        </label>
                                    </FormGroup>
                                </Col>

                            </Row>
                            <hr />
                            <h4>Contact information</h4>

                            <Row>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="phone_field"
                                        >
                                            Phone
                                        </label>
                                        <Input
                                            id="phone_field"
                                            placeholder={companyEdit && companyEdit.phone}
                                            type="tel"
                                            mask="999 999 9999"
                                            maskChar=" "
                                            tag={InputMask}
                                            name="phone"
                                            value={new_phone} onChange={e => setNewPhone(e.target.value)}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="second_phone_field"
                                        >
                                            Second Phone(optional)
                                        </label>
                                        <Col md="10">
                                            <Input
                                                id="second_phone_field"
                                                placeholder={companyEdit && companyEdit.phone2 ? companyEdit.phone2 : 'Second Phone'}
                                                type="tel"
                                                mask="999 999 9999"
                                                maskChar=" "
                                                tag={InputMask}
                                                name="second_phone"
                                                value={new_phone2} onChange={e => setNewPhone2(e.target.value)}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="email_field"
                                        >
                                            Email
                                        </label>
                                        <Input
                                            id="email_field"
                                            placeholder={companyEdit && companyEdit.email}
                                            type="text"
                                            name="email"
                                            value={new_email}
                                            required
                                            onChange={e => setNewEmail(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3" sm="6">



                                </Col>

                            </Row>
                            <hr />
                            <h4>Address information</h4>
                            <Row>
                                <Col md="3" sm="6">

                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="country_select"
                                        >
                                            Country
                                        </label>
                                        <Input required id="country_select" className="form-control" type="select" onChange={e => setNewCountry(e.target.value)}>
                                            <option value="US">United States</option>
                                        </Input>
                                    </FormGroup>

                                </Col>
                                <Col md="3" sm="6">

                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="state_select"
                                        >
                                            State
                                        </label>
                                        <Select2
                                            id="state_select"
                                            className="form-control"
                                            defaultValue="AL"
                                            required
                                            options={{
                                                placeholder: "Select a state",
                                            }}
                                            value={new_state}
                                            onChange={e => setNewState(e.target.value)}
                                            data={[
                                                { id: "AL", text: "Alabama" },
                                                { id: "AK", text: "Alaska" },
                                                { id: "AZ", text: "Arizona" },
                                                { id: "AR", text: "Arkansas" },
                                                { id: "CA", text: "California" },
                                                { id: "CO", text: "Colorado" },
                                                { id: "CT", text: "Connecticut" },
                                                { id: "DE", text: "Delaware" },
                                                { id: "DC", text: "District of Columbia" },
                                                { id: "FL", text: "Florida" },

                                                { id: "GA", text: "Georgia" },

                                                { id: "HI", text: "Hawaii" },

                                                { id: "ID", text: "Idaho" },

                                                { id: "IL", text: "Illinois" },

                                                { id: "IN", text: "Indiana" },
                                                { id: "IA", text: "Iowa" },
                                                { id: "KS", text: "Kansas" },
                                                { id: "KY", text: "Kentucky" },
                                                { id: "LA", text: "Louisiana" },
                                                { id: "ME", text: "Maine" },
                                                { id: "MD", text: "Maryland" },
                                                { id: "MA", text: "Massachusetts" },
                                                { id: "MI", text: "Michigan" },
                                                { id: "MN", text: "Minnesota" },
                                                { id: "MS", text: "Mississippi" },
                                                { id: "MO", text: "Missouri" },
                                                { id: "MT", text: "Montana" },
                                                { id: "NE", text: "Nebraska" },
                                                { id: "NV", text: "Nevada" },
                                                { id: "NH", text: "New Hampshire" },
                                                { id: "NJ", text: "New Jersey" },
                                                { id: "NM", text: "New Mexico" },
                                                { id: "NY", text: "New York" },
                                                { id: "NC", text: "North Carolina" },
                                                { id: "ND", text: "North Dakota" },
                                                { id: "OH", text: "Ohio" },
                                                { id: "OK", text: "Oklahoma" },
                                                { id: "PA", text: "Pennsylvania" },
                                                { id: "RI", text: "Rhode Island" },
                                                { id: "SC", text: "South Carolina" },
                                                { id: "SD", text: "South Dakota" },
                                                { id: "TN", text: "Tennessee" },
                                                { id: "TX", text: "Texas" },
                                                { id: "UT", text: "Utah" },
                                                { id: "VT", text: "Vermont" },
                                                { id: "VA", text: "Virginia" },
                                                { id: "WA", text: "Washington" },
                                                { id: "WV", text: "West Virginia" },
                                                { id: "WI", text: "Wisconsin" },
                                                { id: "WY", text: "Wyoming" }
                                            ]}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md="3" sm="6">

                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="city_field"
                                        >
                                            City
                                        </label>
                                        <Input
                                            id="city_field"
                                            placeholder={companyEdit && companyEdit.city}
                                            name="city"
                                            type="text"
                                            value={new_city}
                                            required
                                            onChange={e => setNewCity(e.target.value)}
                                        />
                                    </FormGroup>

                                </Col>
                                <Col md="3" sm="6">

                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="zip_code_field"
                                        >
                                            Zip Code
                                        </label>
                                        <Input
                                            id="zip_code_field"
                                            placeholder={companyEdit && companyEdit.zip_code}
                                            type="text"
                                            name="zip_code"
                                            value={new_zipCode}
                                            onChange={e => setNewZipCode(e.target.value)}
                                            maxLength={5}
                                            minLength={5}
                                            required
                                        />
                                    </FormGroup>

                                </Col>

                            </Row>
                            <Row>
                                <Col md="3" sm="6">

                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="address_field"
                                        >
                                            Business Address
                                        </label>
                                        <Col md="10">
                                            <Input
                                                placeholder={companyEdit && companyEdit.business_address}
                                                id="address_field"
                                                type="text"
                                                name="address"
                                                value={new_business_address}
                                                required
                                                onChange={e => setNewBusinessAddress(e.target.value)}
                                            />
                                        </Col>
                                    </FormGroup>

                                </Col>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="address_field"
                                        >
                                            Mailing Address
                                        </label>
                                        <Col md="10">
                                            <Input
                                                placeholder={companyEdit && companyEdit.mailing_address}
                                                id="address_field"
                                                type="text"
                                                name="address"
                                                disabled={new_isSame}
                                                value={new_mailing_address}
                                                onChange={e => setNewMailingAddress(e.target.value)}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                        >
                                            Addresses is same?
                                        </label><br />
                                        <label className="custom-toggle mr-1">
                                            <input type="checkbox" checked={new_isSame} onChange={() => setNewIsSame(!new_isSame)} name="is_same" />
                                            <span
                                                className="custom-toggle-slider rounded-circle"
                                                data-label-off="No"
                                                data-label-on="Yes"
                                            />
                                        </label>
                                    </FormGroup>
                                </Col>
                                <Col md="3" sm="6">

                                </Col>

                            </Row>
                            <hr />
                            <h4>Social media information</h4>
                            <Row>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="facebook_field"
                                        >
                                            Facebook
                                        </label>
                                        <Input
                                            id="facebook_field"
                                            placeholder={companyEdit && companyEdit.facebook ? companyEdit.facebook : 'Facebook'}
                                            name="facebook"
                                            type="text"
                                            value={new_facebook}
                                            onChange={e => setNewFacebook(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="instagram_field"
                                        >
                                            Instagram
                                        </label>
                                        <Input
                                            id="instagram_field"
                                            placeholder={companyEdit && companyEdit.instagram ? companyEdit.instagram : 'Instagram'}
                                            type="text"
                                            name="instagram"
                                            value={new_instagram}
                                            onChange={e => setNewInstagram(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="linkedin_field"
                                        >
                                            Linkedin
                                        </label>
                                        <Input
                                            id="linkedin_field"
                                            placeholder={companyEdit && companyEdit.linkedin ? companyEdit.linkedin : 'Linkedin'}
                                            name="linkedin"
                                            type="text"
                                            value={new_linkedin}
                                            onChange={e => setNewLinkedin(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="other_social_medias"
                                        >
                                            Other social medias
                                        </label>
                                        <Col md="10">
                                            <Input
                                                placeholder={companyEdit && companyEdit.other_social_medias ? companyEdit.other_social_medias : 'Other social medias'}
                                                id="other_social_medias"
                                                name="social_medias"
                                                type="text"
                                                value={new_otherSocialMedias}
                                                onChange={e => setNewOtherSocialMedias(e.target.value)}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>

                            </Row>
                            <hr />
                            <h4>Observation</h4>
                            <Row>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="reviews_field"
                                        >
                                            Reviews
                                        </label>
                                        <Input
                                            id="reviews_field"
                                            placeholder={companyEdit && companyEdit.reviews ? companyEdit.reviews : 'Reviews'}
                                            type="text"
                                            name="reviews"
                                            value={new_reviews}
                                            onChange={e => setNewReviews(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3" sm="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="notes_field"
                                        >
                                            Notes
                                        </label>
                                        <Input
                                            id="notes_field"
                                            placeholder={companyEdit && companyEdit.notes ? companyEdit.notes : "Notes"}
                                            type="text"
                                            name="notes"
                                            value={new_notes}
                                            onChange={e => setNewNotes(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3" sm="6">

                                </Col>
                                <Col md="3" sm="6">

                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <Button color="primary" type="submit">
                                        Insert
                                    </Button>
                                </Col>
                            </Row>
                        </form>

                    </> : ''}
                </div>
                <div className="modal-footer">
                    <Button onClick={saveChanges} color="primary" type="button">
                        Save changes
                    </Button>
                    <Button
                        className="ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setModal(false)}
                    >
                        Close
                    </Button>
                </div>
            </Modal>

            <SimpleHeader name="Companies" parentName="" />
            <Container className="mt--6" fluid>
                <Row>
                    <Col>
                        <Card className="mb-4">
                            <CardHeader>
                                <h3 className="mb-0">Insert companies</h3>
                                <Button style={{ marginTop: 20 }} onClick={() => setIsOpen(!isOpen)}>{isOpen ? 'Close' : 'Open'}</Button>
                            </CardHeader>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Collapse isOpen={isOpen}>
                            <Card className="mb-4">
                                <CardHeader>
                                    <h3 className="mb-0">Companies input</h3>
                                </CardHeader>

                                <CardBody>
                                    <h4>Business Information</h4>
                                    <form onSubmit={insertCompany}>

                                        <Row>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="name_company_field"
                                                    >
                                                        Alias
                                                    </label>
                                                    <Input
                                                        id="name_company_field"
                                                        placeholder="Alias"
                                                        type="text"
                                                        name="company_alias"
                                                        value={alias}
                                                        required
                                                        onChange={e => setAlias(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="name_company_field"
                                                    >
                                                        Entity Name
                                                    </label>
                                                    <Input
                                                        id="name_company_field"
                                                        placeholder="Entity Name"
                                                        type="text"
                                                        name="company_name"
                                                        value={entity_name}
                                                        required
                                                        onChange={e => setEntityName(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="industry_select"
                                                    >
                                                        Industry
                                                    </label>
                                                    <Select2
                                                        id="industry_select"
                                                        className="form-control"
                                                        defaultValue="Aerospace industry"
                                                        options={{
                                                            placeholder: "Select a industry",
                                                        }}
                                                        value={industry}
                                                        onChange={e => setIndustry(e.target.value)}
                                                        required
                                                        data={[
                                                            { id: "Aerospace industry", text: "Aerospace industry" },
                                                            { id: "Agriculture", text: "Agriculture" },
                                                            { id: "Fishing industry", text: "Fishing industry" },
                                                            { id: "Timber industry", text: "Timber industry" },
                                                            { id: "Tobacco industry", text: "Tobacco industry" },
                                                            { id: "Chemical industry", text: "Chemical industry" },
                                                            { id: "Pharmaceutical industry", text: "Pharmaceutical industry" },
                                                            { id: "Computer industry", text: "Computer industry" },
                                                            { id: "Software industry", text: "Software industry" },
                                                            { id: "Construction industry", text: "Construction industry" },

                                                            { id: "Defense industry", text: "Defense industry" },

                                                            { id: "Energy industry", text: "Energy industry" },

                                                            { id: "Electrical power industry", text: "Electrical power industry" },

                                                            { id: "Petroleum industry", text: "Petroleum industry" },

                                                            { id: "Entertainment industry", text: "Entertainment industry" },
                                                            { id: "Food industry", text: "Food industry" },
                                                            { id: "Health care industry", text: "Health care industry" },
                                                            { id: "Hospitality industry", text: "Hospitality industry" },
                                                            { id: "Information industry", text: "Information industry" },
                                                            { id: "Insurance industry", text: "Insurance industry" },
                                                            { id: "Manufacturing", text: "Manufacturing" },
                                                            { id: "Arms industry", text: "Arms industry" },
                                                            { id: "Automotive industry", text: "Automotive industry" },
                                                            { id: "Pulp and paper industry", text: "Pulp and paper industry" },
                                                            { id: "Steel industry", text: "Steel industry" },
                                                            { id: "Toy industry", text: "Toy industry" },
                                                            { id: "Shipbuilding industry", text: "Shipbuilding industry" },
                                                            { id: "Mass media", text: "Mass media" },
                                                            { id: "Broadcasting", text: "Broadcasting" },
                                                            { id: "Film industry", text: "Film industry" },
                                                            { id: "Internet", text: "Internet" },
                                                            { id: "Music industry", text: "Music industry" },
                                                            { id: "News media", text: "News media" },
                                                            { id: "Publishing", text: "Publishing" },
                                                            { id: "Telecommunications industry", text: "Telecommunications industry" },
                                                            { id: "Water industry", text: "Water industry" }
                                                        ]}
                                                    />

                                                </FormGroup>

                                            </Col>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="decision_maker_field"
                                                    >
                                                        Decision Maker
                                                    </label>
                                                    <Input
                                                        id="decision_maker_field"
                                                        placeholder="Decision Maker"
                                                        type="text"
                                                        name="decision_maker"
                                                        value={decisionMaker}
                                                        onChange={e => setDecisionMaker(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>

                                        </Row>


                                        <Row>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="channel_select"
                                                    >
                                                        Channel
                                                    </label>
                                                    <Select2
                                                        id="channel_select"
                                                        className="form-control"
                                                        defaultValue="Facebook"
                                                        options={{
                                                            placeholder: "Select a channel",
                                                        }}
                                                        value={channel}
                                                        required
                                                        onChange={e => setChannel(e.target.value)}
                                                        data={[
                                                            { id: "Facebook", text: "Facebook" },
                                                            { id: "Instagram", text: "Instagram" },
                                                            { id: "Google Ads", text: "Google Ads" },
                                                            { id: "Email Campaign", text: "Email Campaign" },
                                                            { id: "Website", text: "Website" },
                                                            { id: "Referral", text: "Referral" },
                                                            { id: "Direct Contact", text: "Direct Contact" }
                                                        ]}
                                                    />

                                                </FormGroup>
                                            </Col>
                                            <Col md="3" sm="6">

                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="site_field"
                                                    >
                                                        Site
                                                    </label>
                                                    <Input
                                                        id="site_field"
                                                        placeholder="Site"
                                                        name="site"
                                                        type="url"
                                                        value={site}
                                                        onChange={e => setSite(e.target.value)}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="ein_number_field"
                                                    >
                                                        EIN Number
                                                    </label>
                                                    <InputMask mask="99-9999999" value={einNumber} onChange={e => setEinNumber(e.target.value)}>
                                                        {inputProps => <Input
                                                            {...inputProps}
                                                            id="ein_number_field"
                                                            placeholder="EIN Number"
                                                            type="text"
                                                            name="ein_number"
                                                            required
                                                        />}
                                                    </InputMask>
                                                </FormGroup>


                                            </Col>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                    >
                                                        Site is responsive?
                                                    </label><br />
                                                    <label className="custom-toggle mr-1">
                                                        <input type="checkbox" checked={isResponsive} onClick={() => setIsResponsive(!isResponsive)} name="is_responsive" />
                                                        <span
                                                            className="custom-toggle-slider rounded-circle"
                                                            data-label-off="No"
                                                            data-label-on="Yes"
                                                        />
                                                    </label>
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <hr />
                                        <h4>Contact information</h4>
                                        <Row>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="phone_field"
                                                    >
                                                        Phone
                                                    </label>
                                                    <Input
                                                        id="phone_field"
                                                        placeholder="Phone"
                                                        type="tel"
                                                        mask="999 999 9999"
                                                        maskChar=" "
                                                        tag={InputMask}
                                                        name="phone"
                                                        value={phone} onChange={e => setPhone(e.target.value)}
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="second_phone_field"
                                                    >
                                                        Second Phone(optional)
                                                    </label>
                                                    <Col md="10">
                                                        <Input
                                                            id="second_phone_field"
                                                            placeholder="Second Phone"
                                                            type="tel"
                                                            mask="999 999 9999"
                                                            maskChar=" "
                                                            tag={InputMask}
                                                            name="second_phone"
                                                            value={phone2} onChange={e => setPhone2(e.target.value)}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>

                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="email_field"
                                                    >
                                                        Email
                                                    </label>
                                                    <Input
                                                        id="email_field"
                                                        placeholder="Email"
                                                        type="text"
                                                        name="email"
                                                        value={email}
                                                        required
                                                        onChange={e => setEmail(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3" sm="6"> </Col>
                                        </Row>
                                        <hr />
                                        <h4>Address information</h4>
                                        <Row>
                                            <Col md="3" sm="6">

                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="country_select"
                                                    >
                                                        Country
                                                    </label>
                                                    <Input required id="country_select" className="form-control" type="select" onChange={e => setCountry(e.target.value)}>
                                                        <option value="US">United States</option>
                                                    </Input>
                                                </FormGroup>

                                            </Col>
                                            <Col md="3" sm="6">

                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="state_select"
                                                    >
                                                        State
                                                    </label>
                                                    <Select2
                                                        id="state_select"
                                                        className="form-control"
                                                        defaultValue="AL"
                                                        required
                                                        options={{
                                                            placeholder: "Select a state",
                                                        }}
                                                        value={state}
                                                        onChange={e => setState(e.target.value)}
                                                        data={[
                                                            { id: "AL", text: "Alabama" },
                                                            { id: "AK", text: "Alaska" },
                                                            { id: "AZ", text: "Arizona" },
                                                            { id: "AR", text: "Arkansas" },
                                                            { id: "CA", text: "California" },
                                                            { id: "CO", text: "Colorado" },
                                                            { id: "CT", text: "Connecticut" },
                                                            { id: "DE", text: "Delaware" },
                                                            { id: "DC", text: "District of Columbia" },
                                                            { id: "FL", text: "Florida" },
                                                            { id: "GA", text: "Georgia" },
                                                            { id: "HI", text: "Hawaii" },
                                                            { id: "ID", text: "Idaho" },
                                                            { id: "IL", text: "Illinois" },
                                                            { id: "IN", text: "Indiana" },
                                                            { id: "IA", text: "Iowa" },
                                                            { id: "KS", text: "Kansas" },
                                                            { id: "KY", text: "Kentucky" },
                                                            { id: "LA", text: "Louisiana" },
                                                            { id: "ME", text: "Maine" },
                                                            { id: "MD", text: "Maryland" },
                                                            { id: "MA", text: "Massachusetts" },
                                                            { id: "MI", text: "Michigan" },
                                                            { id: "MN", text: "Minnesota" },
                                                            { id: "MS", text: "Mississippi" },
                                                            { id: "MO", text: "Missouri" },
                                                            { id: "MT", text: "Montana" },
                                                            { id: "NE", text: "Nebraska" },
                                                            { id: "NV", text: "Nevada" },
                                                            { id: "NH", text: "New Hampshire" },
                                                            { id: "NJ", text: "New Jersey" },
                                                            { id: "NM", text: "New Mexico" },
                                                            { id: "NY", text: "New York" },
                                                            { id: "NC", text: "North Carolina" },
                                                            { id: "ND", text: "North Dakota" },
                                                            { id: "OH", text: "Ohio" },
                                                            { id: "OK", text: "Oklahoma" },
                                                            { id: "PA", text: "Pennsylvania" },
                                                            { id: "RI", text: "Rhode Island" },
                                                            { id: "SC", text: "South Carolina" },
                                                            { id: "SD", text: "South Dakota" },
                                                            { id: "TN", text: "Tennessee" },
                                                            { id: "TX", text: "Texas" },
                                                            { id: "UT", text: "Utah" },
                                                            { id: "VT", text: "Vermont" },
                                                            { id: "VA", text: "Virginia" },
                                                            { id: "WA", text: "Washington" },
                                                            { id: "WV", text: "West Virginia" },
                                                            { id: "WI", text: "Wisconsin" },
                                                            { id: "WY", text: "Wyoming" }
                                                        ]}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col md="3" sm="6">

                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="city_field"
                                                    >
                                                        City
                                                    </label>
                                                    <Input
                                                        id="city_field"
                                                        placeholder="City"
                                                        name="city"
                                                        type="text"
                                                        value={city}
                                                        required
                                                        onChange={e => setCity(e.target.value)}
                                                    />
                                                </FormGroup>

                                            </Col>
                                            <Col md="3" sm="6">

                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="zip_code_field"
                                                    >
                                                        Zip Code
                                                    </label>
                                                    <Input
                                                        id="zip_code_field"
                                                        placeholder="Zip Code"
                                                        type="text"
                                                        name="zip_code"
                                                        value={zipCode}
                                                        onChange={e => setZipCode(e.target.value)}
                                                        maxLength={5}
                                                        minLength={5}
                                                        required
                                                    />
                                                </FormGroup>

                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col md="3" sm="6">

                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="address_field"
                                                    >
                                                        Business Address
                                                    </label>
                                                    <Col md="10">
                                                        <Input
                                                            placeholder="Business Address"
                                                            id="address_field"
                                                            type="text"
                                                            name="address"
                                                            value={business_address}
                                                            required
                                                            onChange={e => setBusinessAddress(e.target.value)}
                                                        />
                                                    </Col>
                                                </FormGroup>

                                            </Col>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="address_field"
                                                    >
                                                        Mailing Address
                                                    </label>
                                                    <Col md="10">
                                                        <Input
                                                            placeholder="Mailing Address"
                                                            id="address_field"
                                                            type="text"
                                                            name="address"
                                                            disabled={isSame}
                                                            value={mailing_address}
                                                            onChange={e => setMailingAddress(e.target.value)}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                    >
                                                        Addresses is same?
                                                    </label><br />
                                                    <label className="custom-toggle mr-1">
                                                        <input type="checkbox" checked={isSame} onChange={() => setIsSame(!isSame)} name="is_same" />
                                                        <span
                                                            className="custom-toggle-slider rounded-circle"
                                                            data-label-off="No"
                                                            data-label-on="Yes"
                                                        />
                                                    </label>
                                                </FormGroup>
                                            </Col>
                                            <Col md="3" sm="6">

                                            </Col>

                                        </Row>
                                        <hr />
                                        <h4>Social media information</h4>
                                        <Row>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="facebook_field"
                                                    >
                                                        Facebook
                                                    </label>
                                                    <Input
                                                        id="facebook_field"
                                                        placeholder="Facebook"
                                                        name="facebook"
                                                        type="text"
                                                        value={facebook}
                                                        onChange={e => setFacebook(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="instagram_field"
                                                    >
                                                        Instagram
                                                    </label>
                                                    <Input
                                                        id="instagram_field"
                                                        placeholder="Instagram"
                                                        type="text"
                                                        name="instagram"
                                                        value={instagram}
                                                        onChange={e => setInstagram(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="linkedin_field"
                                                    >
                                                        Linkedin
                                                    </label>
                                                    <Input
                                                        id="linkedin_field"
                                                        placeholder="Linkedin"
                                                        name="linkedin"
                                                        type="text"
                                                        value={linkedin}
                                                        onChange={e => setLinkedin(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="other_social_medias"
                                                    >
                                                        Other social medias
                                                    </label>
                                                    <Col md="10">
                                                        <Input
                                                            placeholder="Other social medias"
                                                            id="other_social_medias"
                                                            name="social_medias"
                                                            type="text"
                                                            value={otherSocialMedias}
                                                            onChange={e => setOtherSocialMedias(e.target.value)}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <hr />
                                        <h4>Observation</h4>
                                        <Row>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="reviews_field"
                                                    >
                                                        Reviews
                                                    </label>
                                                    <Input
                                                        id="reviews_field"
                                                        placeholder="Reviews"
                                                        type="text"
                                                        name="reviews"
                                                        value={reviews}
                                                        onChange={e => setReviews(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3" sm="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="notes_field"
                                                    >
                                                        Notes
                                                    </label>
                                                    <Input
                                                        id="notes_field"
                                                        placeholder="Notes"
                                                        type="text"
                                                        name="notes"
                                                        value={notes}
                                                        onChange={e => setNotes(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3" sm="6">

                                            </Col>
                                            <Col md="3" sm="6">

                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button color="primary" type="submit">
                                                    Insert
                                                </Button>
                                            </Col>
                                        </Row>
                                    
                                    </form>
                                </CardBody>

                            </Card>
                        </Collapse>
                    </Col>
                </Row>

                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader className="border-0">
                                <h3 className="mb-0">Companies</h3>
                            </CardHeader>

                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th className="sort" data-sort="name" scope="col">Alias</th>
                                        <th className="sort" data-sort="name" scope="col">Entity Name</th>
                                        <th className="sort" data-sort="budget" scope="col">Channel</th>
                                        <th className="sort" data-sort="status" scope="col">Industry</th>
                                        <th className="sort" data-sort="completion" scope="col">Decision Maker</th>
                                        <th className="sort" data-sort="completion" scope="col">Phone</th>
                                        <th className="sort" data-sort="completion" scope="col">Second Phone</th>
                                        <th className="sort" data-sort="completion" scope="col">Email</th>
                                        <th className="sort" data-sort="completion" scope="col">Site</th>
                                        <th className="sort" data-sort="completion" scope="col">Is responsive?</th>
                                        <th className="sort" data-sort="completion" scope="col">Full Address</th>
                                        <th className="sort" data-sort="completion" scope="col">Zip Code</th>
                                        <th className="sort" data-sort="completion" scope="col">Facebook</th>
                                        <th className="sort" data-sort="completion" scope="col">Instagram</th>
                                        <th className="sort" data-sort="completion" scope="col">Linkedin</th>
                                        <th className="sort" data-sort="completion" scope="col">Other social medias</th>
                                        <th className="sort" data-sort="completion" scope="col">Reviews</th>
                                        <th className="sort" data-sort="completion" scope="col">Notes</th>
                                        <th className="sort" data-sort="completion" scope="col">EIN Number</th>
                                        <th scope="col" />
                                    </tr>
                                </thead>
                                <tbody className="list">
                                    {list ? list.companies.map((item) => (
                                        <tr>
                                            <th scope="row">{item.alias}</th>                                            
                                            <td scope="row">{item.entity_name}</td>
                                            <td>{item.channel}</td>
                                            <td>{item.industry}</td>
                                            <td>{item.decision_maker}</td>
                                            <td>{item.phone}</td>                                            
                                            <td>{item.phone2}</td>
                                            <td>{item.email}</td>
                                            <td>{item.site}</td>
                                            <td>{item.is_responsive ? 'Yes' : 'No'}</td>
                                            <td>{`${item.business_address}, ${item.city}, ${item.state}, ${item.country}`}</td>
                                            <td>{item.zip_code}</td>
                                            <td>{item.facebook}</td>
                                            <td>{item.instagram}</td>
                                            <td>{item.linkedin}</td>
                                            <td>{item.other_social_medias}</td>
                                            <td>{item.reviews}</td>
                                            <td title="Click to view note" style={{ cursor: 'pointer' }} onClick={() => showNotes(item.notes)}>
                                                {item.notes ? <i style={{ fontSize: 25 }} className="ni ni-check-bold" /> : <i style={{ fontSize: 25 }} className="ni ni-fat-remove" />}
                                            </td>
                                            <td>{item.ein_number}</td>
                                            <td className="text-right">
                                                <UncontrolledDropdown>
                                                    <DropdownToggle
                                                        className="btn-icon-only text-light"
                                                        color=""
                                                        role="button"
                                                        size="sm"
                                                    >
                                                        <i className="fas fa-ellipsis-v" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-arrow" right>
                                                        <DropdownItem
                                                            onClick={(e) => {
                                                                setCompanyId(item.id);
                                                                setModal(true);
                                                            }}
                                                        >
                                                            Edit company
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            href="#pablo"
                                                            onClick={() => deleteCompany(item.id)}
                                                        >
                                                            Delete Company
                                                        </DropdownItem>

                                                        <DropdownItem
                                                            onClick={() => addPropertyId(item.id)}
                                                        >
                                                            Add Analytics ID
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            onClick={(e) => {
                                                                getCompany(item.id);
                                                                setModalView(true);
                                                            }}
                                                        >
                                                            View details
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </td>
                                        </tr>
                                    )) : ''}
                                </tbody>
                            </Table>

                            <CardFooter className="py-4">
                                <nav aria-label="...">

                                    <Pagination
                                        className="pagination justify-content-end mb-0"
                                        listClassName="justify-content-end mb-0"
                                    >
                                        <PaginationItem>
                                            <PaginationLink
                                                onClick={(e) => {
                                                    if (actualPage > 1) {
                                                        setActualPage(actualPage - 1);
                                                    }
                                                }}
                                                tabIndex="-1"
                                            >
                                                <i className="fas fa-angle-left" />
                                                <span className="sr-only">Previous</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                        {
                                            Array(list ? Math.round(list.allCompanies.length / 10) === 0 ? 1 : Number((list.allCompanies.length / 10).toFixed(1).split('.')[1]) > 5 ? Math.round(list.allCompanies.length / 10) : Math.round(list.allCompanies.length / 10) + 1 : 1).fill('').map((_, index) => {
                                                return (
                                                    <PaginationItem className={actualPage === (index + 1) ? 'active' : ''} key={index}>
                                                        <PaginationLink
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setActualPage(index + 1);
                                                            }}
                                                        >
                                                            {index + 1}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                )
                                            })
                                        }

                                        <PaginationItem>
                                            <PaginationLink
                                                onClick={(e) => {
                                                    if (actualPage < Math.round(list.allCompanies.length / 5)) {
                                                        setActualPage(actualPage + 1);
                                                    }
                                                }}
                                            >
                                                <i className="fas fa-angle-right" />
                                                <span className="sr-only">Next</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                    </Pagination>

                                </nav>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>

            </Container>
        </>
    );
}

export default Elements;
