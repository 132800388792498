import React from "react";
import ReactDOM from "react-dom";
// react library for routing
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/vendor/nucleo/css/nucleo.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";
// Custom styles
import "assets/css/custom.css";

import AdminLayout from "layouts/Admin.js";
import RTLLayout from "layouts/RTL.js";
import AuthLayout from "layouts/Auth.js";
import IndexView from "views/Index.js";
import PrivateRoute from "components/Middlewares/PrivateRoute.js";
import isAuthenticate from "components/Middlewares/isAuthenticate.js";
import Login from "views/pages/login/Login.js";
import ForgotPassword from "views/pages/login/ForgotPassword.js";
import RequestAccess from "views/pages/login/RequestAccess.js";

const isAuth = isAuthenticate();

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/admin" render={(props) => isAuth ? <AdminLayout {...props} /> : <Redirect to={{pathname: '/auth/login', state: {from: props.location}}} />} />
      
      {/* <Route path="/admin" render={(props) => <AdminLayout {...props} />} /> */}
      <Route path="/rtl" render={(props) => isAuth ? <RTLLayout {...props} /> : <Redirect to={{pathname: '/auth/login', state: {from: props.location}}} />} />
      
      <Route path="/auth" render={(props) => <Login {...props} />} />
      <Route path="/forgot-password" render={(props) => <ForgotPassword {...props} />} />
      <Route path="/create-profile" render={(props) => <RequestAccess {...props} />} />

      <Route path="/" render={(props) => isAuth ? <AdminLayout {...props} /> : <Redirect to={{pathname: '/auth/login', state: {from: props.location}}} />} />
      {/* <Route path="/" render={(props) => <IndexView {...props} />} /> */}
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
