import React, { useState, useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    CardBody,
    CardTitle,
    Container,
    Row,
    Col,
} from "reactstrap";
import api from '../../util/api';

function CardsHeader({ name, parentName }) {
    const token = localStorage.getItem('token');
    const [contractsSent, setContractsSent] = useState(0);
    const [contractsActive, setContractsActive] = useState(0);
    const [totalLeads, setTotalLeads] = useState(0);
    const [totalRevenue, setTotalRevenue] = useState(0);

    useEffect(() => {
        api.get('/contracts/count', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            if (res.data.success) {
                const { sent, active, leads } = res.data.contracts;
                setTotalLeads(leads);
                setContractsActive(active);
                setContractsSent(sent);
            }
        });
        api.get('/contracts/count/total-revenue', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            if (res.data.success) {
                setTotalRevenue(res.data.revenue);
            }
        });
    }, [token]);


    return (
        <>
            <div className="header bg-info pb-6">
                <Container fluid>
                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col lg="6" xs="7">
                                <h6 className="h2 text-white d-inline-block mb-0">{name}</h6>{" "}
                                <Breadcrumb
                                    className="d-none d-md-inline-block ml-md-4"
                                    listClassName="breadcrumb-links breadcrumb-dark"
                                >
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                            <i className="fas fa-home" />
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                            {parentName}
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem aria-current="page" className="active">
                                        {name}
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                            <Col className="text-right" lg="6" xs="5">
                                <Button
                                    className="btn-neutral"
                                    color="default"
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                    size="sm"
                                >
                                    New
                                </Button>
                                <Button
                                    className="btn-neutral"
                                    color="default"
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                    size="sm"
                                >
                                    Filters
                                </Button>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="6" xl="3">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    Total <br />Leads
                                                </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">
                                                    {totalLeads}
                                                </span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                                    <i className="ni ni-active-40" />
                                                </div>
                                            </Col>
                                        </Row>
                                        {/* <p className="mt-3 mb-0 text-sm">
                      <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 3.48%
                      </span>{" "}
                      <span className="text-nowrap">Since last month</span>
                    </p> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="6" xl="3">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    Contracts <br />sent
                                                </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">{contractsSent}</span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                                                    <i className="ni ni-chart-pie-35" />
                                                </div>
                                            </Col>
                                        </Row>
                                        {/* <p className="mt-3 mb-0 text-sm">
                      <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 3.48%
                      </span>{" "}
                      <span className="text-nowrap">Since last month</span>
                    </p> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="6" xl="3">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    Contracts <br />Active
                                                </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">{contractsActive}</span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                                    <i className="ni ni-money-coins" />
                                                </div>
                                            </Col>
                                        </Row>
                                        {/* <p className="mt-3 mb-0 text-sm">
                      <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 3.48%
                      </span>{" "}
                      <span className="text-nowrap">Since last month</span>
                    </p> */}
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="6" xl="3">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle
                                                    tag="h5"
                                                    className="text-uppercase text-muted mb-0"
                                                >
                                                    Total <br />Revenue
                                                </CardTitle>
                                                <span className="h2 font-weight-bold mb-0">{totalRevenue.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                                                    <i className="ni ni-chart-bar-32" />
                                                </div>
                                            </Col>
                                        </Row>
                                        {/* <p className="mt-3 mb-0 text-sm">
                      <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> 3.48%
                      </span>{" "}
                      <span className="text-nowrap">Since last month</span>
                    </p> */}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
}

CardsHeader.propTypes = {
    name: PropTypes.string,
    parentName: PropTypes.string,
};

export default CardsHeader;
