import React, { useEffect, useState } from "react";
import classnames from "classnames";
import Chart from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Container,
  Row,
  Col,
} from "reactstrap";

import CardsHeader from "components/Headers/CardsHeader.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import api from "../../../util/api";

import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

const currentMonth = Number(new Date().getMonth() + 1);

function Dashboard() {
  const [activeNav, setActiveNav] = React.useState(1);
  const [product, setProduct] = useState([]);
  const [chartExample1Data, setChartExample1Data] = React.useState("data1");
  const [contractsPerMonth, setContractsPerMonth] = useState([0, 0, 0, 0, 0, 0]);
  const [sales, setSales] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [fee, setFee] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [net, setNet] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

  const [allExpenses, setAllExpenses] = useState(0);

  console.log(sales, fee, net);

  const token = localStorage.getItem('token');

  console.log(sales);

  useEffect(() => {
    api.get('/contracts/count/month', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(res => {
      if (res.data.success) {
        setContractsPerMonth(res.data.contract);
      }
    });
    api.get('/expenses?offset=0&order=category-DESC', {
      headers: {
          'Authorization': `Bearer ${token}`
      }
  }).then(res => {
      if (res.data.success) {
          setAllExpenses(res.data.expensesByMonth.reduce((a,b) => Number(a) + Number(b), 0));
      }
  })
    api.get('/sales', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(res => {
      if (res.data.success) {
        setSales(res.data.sales);
        setFee(res.data.fee);
        setNet(res.data.net);
      }
    })
    api.get('/sales/fetch-products-price', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(res => {
      if(res.data.success){
        setProduct(res.data.products)
      }
    })
  }, [token]);


  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data(chartExample1Data === "data1" ? "data2" : "data1");
  };
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  // Define Goals
  var ourBaseInExpenses = 30000;
  var ourBaseInGrowth = (ourBaseInExpenses * 0.3) + ourBaseInExpenses;

  // Calculate Growth 
  var totalSales = sales.reduce((a,b) => a + b, 0);
  var percentGrowth = ((totalSales / ourBaseInGrowth) * 100).toFixed(3);

  //Calculate Xpenses
  var totalExpenses = allExpenses;
  var percentExpenses = ((totalExpenses / ourBaseInExpenses) * 100).toFixed(3);

  var calculo = 100 * (totalSales - totalExpenses) / totalSales;
  var differenceInPercent = (calculo).toFixed(2) + '%';
  console.log(calculo < 0)
  console.log(totalSales, totalExpenses)

  return (
    <>
      <CardsHeader name="MSW Dashboard" parentName="Dashboards" />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="8">
            <Card className="bg-default">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-light text-uppercase ls-1 mb-1">
                      Overview
                    </h6>
                    <h5 className="h3 text-white mb-0">Sales Growth</h5>
                  </div>
                  <div className="col">
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Line
                    data={{
                      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                      datasets: [
                        {
                          label: "Sales",
                          data: sales,
                          fill: false,
                          borderColor: "rgba(75,192,192,1)",
                          backgroundColor: "rgba(75,192,192,1)",
                          pointColor: "#da3e2f",
                          strokeColor: "#da3e2f",
                          datalabels: {
                            anchor: 'end',
                            align: 'end',
                            offset: 5
                          },
                        },
                        {
                          label: "Fee",
                          data: fee,
                          fill: false,
                          borderColor: "#742774",
                          backgroundColor: "#742774",
                          pointColor: "#da3e2f",
                          strokeColor: "#da3e2f",
                          datalabels: {
                            anchor: 'end',
                            align: 'end',
                            offset: 5
                          },
                        },
                        {
                          label: "Net",
                          data: net,
                          fill: false,
                          borderColor: "#ad2d10",
                          backgroundColor: "#ad2d10",
                          pointColor: "#da3e2f",
                          strokeColor: "#da3e2f",
                          datalabels: {
                            anchor: 'start',
                            align: 'bottom',
                            offset: 5
                          },
                        },

                      ],
                    }}
                    plugins={[ChartDataLabels]}
                    id="chart-sales-dark"
                    className="chart-canvas"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card>
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1"> Performance </h6>
                    <h5 className="h3 mb-0">Total contracts per month</h5>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Bar
                    data={{
                      labels: currentMonth > 6 ? ["Jul", "Aug", "Sep", "Oct", "Nov", "Dec"] : ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
                      datasets: [
                        {
                          label: "Contracts",
                          data: contractsPerMonth,
                          maxBarThickness: 10
                        }
                      ]
                    }}
                    options={chartExample2.options}
                    className="chart-canvas"
                    id="chart-bars"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          
        <Col xl="8">
            <Card>
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      Products
                    </h6>
                    <h5 className="h3 mb-0">Products purchased with greater value</h5>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                <Bar
                    data={{
                      labels: product.map((item) => item.product.name),
                      datasets: [
                        {
                          label: "Products",
                          data: product.map((item) => item.amount),
                          maxBarThickness: 10
                        }
                      ]
                    }}
                    options={chartExample2.options}
                    className="chart-canvas"
                    id="chart-bars"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col xl="4">
            <Row>
              <div className="col">
                Balance Calculation and Goals
              </div>
            </Row>
            <div className="card-deck">
              <Card className="bg-gradient-default">
                <CardBody>
                  <div className="mb-2">
                    <div className="text-light mt-2 text-sm">
                      Balance Sheet
                    </div>
                    <div>
                      <span className={calculo < 0 ? "text-danger font-weight-600" : "text-success font-weight-600"}>
                      {differenceInPercent} ( Diference in Earnings)
                      </span>{" "}
                      <span className="text-light"> Calculate Total Expenses -  Earnings ({(totalSales - totalExpenses).toLocaleString('en-US', { style: 'currency', currency: 'USD' })})</span>
                    </div>
                  </div>
                  <Row>
                  <Col md={6}>
                  <Button onClick={() => window.location.href = '/admin/p&l'} block className="btn-neutral" color="default" size="sm">View PnL</Button>
                  </Col>
                  <Col md={6}>
                    <Button onClick={() => window.location.href = '/admin/planalytics'} block className="btn-neutral" color="default" size="sm">View PnL Analytics</Button>
                  </Col>
                  </Row>
                </CardBody>
                <CardBody>
                  <Row>
                    <div className="col">
                      <small className="text-light">Our goal: {(ourBaseInGrowth).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</small><br />
                      <small className="text-light">Sales Growth: {percentGrowth}%</small>
                      <Progress
                        className="progress-xs my-2" max={ourBaseInGrowth} value={totalSales} color="success"
                      />
                    </div>
                    <div className="col">
                      <small className="text-light">Our goal: {(ourBaseInExpenses).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</small><br />
                      <small className="text-light">All Expenses: {percentExpenses}%</small>
                      <Progress
                        className="progress-xs my-2" max={ourBaseInExpenses} value={totalExpenses} color="warning"
                      />
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </div>
            <Row>
              <div className="col">
                Coming soon more Insights
              </div>
            </Row>
            <div className="card-deck">
              <Card className="bg-gradient-default">
                <CardBody>
                  <div className="mb-2">
                    <div className="text-light mt-2 text-sm">
                    </div>
                    <div>
                      <span className="text-success font-weight-600">
                      </span>{" "}
                      <span className="text-light"> </span>
                    </div>
                  </div>
                </CardBody>
                <CardBody>
                  <Row>
                    <div className="col">
                      <small className="text-light"></small><br />
                      <small className="text-light"></small>
                      <Progress
                        className="progress-xs my-2" max="100" value="85" color="success"
                      />
                    </div>
                    <div className="col">
                      <small className="text-light"></small><br />
                      <small className="text-light"></small>
                      <Progress
                        className="progress-xs my-2" max="100" value="25" color="warning"
                      />
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Col>
         
        </Row>

      </Container>
    </>
  );
}

export default Dashboard;
